// @flow

import _ from 'underscore';
import type { Option } from '../types/Option';
import Attachments from './Attachments';
import BuildingUsages from './BuildingUsages';
import Countries from './Countries';
import FormDataTransform from './FormDataTransform';
import Keywords from './Keywords';
import Languages from './Languages';
import Materials from './Materials';
import RecordAssociations from './RecordAssociations';
import RelatedPublications from './RelatedPublications';
import String from '../utils/String';

import type { Publication as PublicationType } from '@archnet/shared';

type PublicationUpload = {
  ...PublicationType,
  files: Array<{ content: File }>
};

/**
 * Class for handling transforming publication records.
 */
class Publication extends FormDataTransform {
  /**
   * Returns the publication parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'publication';
  }

  /**
   * Returns the publications payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'publication_type_id',
      'hcp_item',
      'award_winner',
      'flag_for_review',
      'published',
      'year',
      'source_id',
      'source',
      'copyright',
      'contributor',
      'dimensions',
      'citation',
      'description',
      'abstract',
      'locked'
    ];
  }

  /**
   * Returns the passed publication as a dropdown option.
   *
   * @param publication
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(publication: PublicationType): Option {
    return {
      key: publication.id,
      value: publication.id,
      text: publication.name
    };
  }

  /**
   * Returns the publication object to be sent to the server on POST/PUT requests.
   *
   * @param publication
   *
   * @returns {FormData}
   */
  toPayload(publication: PublicationType): FormData {
    const formData = super.toPayload(publication);

    Attachments.appendFormData(formData, this.getParameterName(), publication, 'content');
    BuildingUsages.appendFormData(formData, this.getParameterName(), publication, 'building_usages_publications');
    Countries.appendFormData(formData, this.getParameterName(), publication, 'countries_publications');
    Keywords.appendFormData(formData, this.getParameterName(), publication, 'keywords_publications');
    Languages.appendFormData(formData, this.getParameterName(), publication, 'languages_publications');
    Materials.appendFormData(formData, this.getParameterName(), publication, 'materials_publications');
    RecordAssociations.appendFormData(formData, this.getParameterName(), publication, 'authority_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), publication, 'collection_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), publication, 'site_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), publication, 'media_record_associations');

    RecordAssociations.appendFormData(
      formData,
      this.getParameterName(),
      publication,
      'parent_publication_record_associations'
    );

    RecordAssociations.appendFormData(
      formData,
      this.getParameterName(),
      publication,
      'child_publication_record_associations'
    );

    RelatedPublications.appendFormData(formData, this.getParameterName(), publication);

    RecordAssociations.appendFormData(
      formData,
      this.getParameterName(),
      publication,
      'donation_record_associations'
    );

    return formData;
  }

  /**
   * Returns the form data to be sent to the server on /api/publications/upload.
   *
   * @param publication
   *
   * @returns {FormData}
   */
  toBulkPayload(publication: PublicationUpload): FormData {
    const formData = new FormData();

    _.each(publication.files, (file, index) => {
      _.each(this.getPayloadKeys(), (key) => {
        formData.append(`publications[${index}][${key}]`, String.toString(publication[key]));
      });

      Attachments.appendFormData(formData, `publications[${index}]`, file, 'content');
      Attachments.appendFormData(formData, `publications[${index}]`, file, 'name');
      BuildingUsages.appendFormData(formData, `[publications][${index}]`, publication, 'building_usages_publications');
      Countries.appendFormData(formData, `[publications][${index}]`, publication, 'countries_publications');
      Keywords.appendFormData(formData, `[publications][${index}]`, publication, 'keywords_publications');
      Languages.appendFormData(formData, `[publications][${index}]`, publication, 'languages_publications');
      Materials.appendFormData(formData, `[publications][${index}]`, publication, 'materials_publications');

      RecordAssociations.appendFormData(
        formData,
        `[publications][${index}]`,
        publication,
        'authority_record_associations'
      );

      RecordAssociations.appendFormData(
        formData,
        `[publications][${index}]`,
        publication,
        'collection_record_associations'
      );

      RecordAssociations.appendFormData(
        formData,
        `[publications][${index}]`,
        publication,
        'donation_record_associations'
      );

      RecordAssociations.appendFormData(
        formData,
        `[publications][${index}]`,
        publication,
        'site_record_associations'
      );
    });

    return formData;
  }
}

export default (new Publication(): Publication);

export type {
  PublicationUpload
};
