// @flow

import type { StylePeriod, Translateable } from '@archnet/shared';
import { BooleanIcon, EmbeddedList } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import EmptyTableRow from './EmptyTableRow';
import StylePeriodModal from './StylePeriodModal';

type StylePeriodable = {
  primary: boolean,
  style_period_id: number,
  style_period: StylePeriod
};

type Props = Translateable & {
  className?: string,
  items: Array<StylePeriodable>,
  onDelete: (item: StylePeriodable) => void,
  onDrag: (items: Array<StylePeriodable>) => void,
  onSave: (item: StylePeriodable) => void
};

const StylePeriodsList: AbstractComponent<any> = withTranslation()((props: Props) => (
  <EmbeddedList
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className={`style-periods-list ${props.className ? props.className : ''}`}
    columns={[{
      className: 'drag-drop-icon',
      name: 'move',
      label: '',
      render: () => <Icon name='bars' />,
      sortable: false
    }, {
      name: 'primary',
      label: props.t('StylePeriodsList.columns.primary'),
      render: (item) => <BooleanIcon value={item.primary} />
    }, {
      name: 'name',
      label: props.t('StylePeriodsList.columns.name'),
      resolve: (item) => item.style_period && item.style_period.name
    }, {
      name: 'start_year',
      label: props.t('StylePeriodsList.columns.startYear'),
      resolve: (item) => item.style_period && item.style_period.start_year
    }, {
      name: 'end_year',
      label: props.t('StylePeriodsList.columns.endYear'),
      resolve: (item) => item.style_period && item.style_period.end_year
    }]}
    items={props.items}
    modal={{
      component: StylePeriodModal,
    }}
    onDelete={props.onDelete.bind(this)}
    onDrag={(dragIndex, hoverIndex) => {
      const items = [...props.items];
      const item = items[dragIndex];

      items.splice(dragIndex, 1);
      items.splice(hoverIndex, 0, item);

      props.onDrag(items);
    }}
    onSave={props.onSave.bind(this)}
    renderEmptyRow={() => <EmptyTableRow />}
  />
));

export default StylePeriodsList;
