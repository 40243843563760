// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming collection description records.
 */
class CollectionDescriptions extends NestedAttributes {
  PARAM_NAME: string = 'collection_descriptions';

  /**
   * Overrides the appendFormData function to default to the collection name.
   *
   * @param formData
   * @param prefix
   * @param record
   * @param collection
   */
  appendFormData(formData: FormData, prefix: string, record: any, collection: string = this.PARAM_NAME) {
    super.appendFormData(formData, prefix, record, collection);
  }

  /**
   * Returns the collection descriptions payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'collection_type_id',
      '_destroy'
    ];
  }
}

export default (new CollectionDescriptions(): CollectionDescriptions);
