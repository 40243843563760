// @flow

import uuid from 'react-uuid';
import _ from 'underscore';

type Item = {
  id: number
};

type Props = {
  item: any,
  onMultiAddChildAssociations: (relationship: string, items: any) => void
};

/**
 * Adds the passed children to the collection at the passed relationship.
 *
 * @param props
 * @param relationship
 * @param type
 * @param children
 */
const onMultiAddChildren = (props: Props, relationship: string, type: string, children: Array<any>) => {
  const existing = props.item[relationship];

  const find = (item: Item) => _.findWhere(existing, {
    child_id: item.id,
    child_type: type
  });

  const create = (item: Item) => ({
    uid: uuid(),
    child_id: item.id,
    child_type: type,
    child: item
  });

  props.onMultiAddChildAssociations(relationship, _.map(children, (item) => find(item) || create(item)));
};

/**
 * Adds the passed parents to the collection at the passed relationship.
 *
 * @param props
 * @param relationship
 * @param type
 * @param parents
 */
const onMultiAddParents = (props: Props, relationship: string, type: string, parents: Array<any>) => {
  const find = (item: Item) => _.findWhere(props.item[relationship], {
    parent_id: item.id,
    parent_type: type
  });

  const create = (item: Item) => ({
    uid: uuid(),
    parent_id: item.id,
    parent_type: type,
    parent: item
  });

  props.onMultiAddChildAssociations(relationship, _.map(parents, (item) => find(item) || create(item)));
};

/**
 * Adds the passed related records to the collection at the passed relationship.
 *
 * @param props
 * @param relationship
 * @param attribute
 * @param related
 */
const onMultiAddRelated = (props: Props, relationship: string, attribute: string, related: Array<any>) => {
  const find = (item: Item) => _.findWhere(props.item[relationship], {
    [attribute]: item.id
  });

  const create = (item: Item) => ({
    uid: uuid(),
    [attribute]: item.id,
    child: item
  });

  props.onMultiAddChildAssociations(relationship, _.map(related, (item) => find(item) || create(item)));
};

export {
  onMultiAddChildren,
  onMultiAddParents,
  onMultiAddRelated
};
