// @flow

import type { Donor, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';

type Props = Translateable & {
  ...EditContainerProps,
  item: Donor
};

const DonorModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='donor-modal'
    noValidate
    open
  >
    <Modal.Header
      content={props.item.id
        ? props.t('DonorModal.title.edit')
        : props.t('DonorModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('name')}
        label={props.t('DonorModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('email')}
        label={props.t('DonorModal.labels.email')}
        onChange={props.onTextInputChange.bind(this, 'email')}
        required={props.isRequired('email')}
        value={props.item.email || ''}
      />
      <Form.Input
        error={props.isError('phone')}
        label={props.t('DonorModal.labels.phone')}
        onChange={props.onTextInputChange.bind(this, 'phone')}
        required={props.isRequired('phone')}
        value={props.item.phone || ''}
      />
      <Form.TextArea
        error={props.isError('general_info')}
        label={props.t('DonorModal.labels.generalInfo')}
        onChange={props.onTextInputChange.bind(this, 'general_info')}
        required={props.isRequired('general_info')}
        value={props.item.general_info || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default DonorModal;
