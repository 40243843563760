// @flow

import type { Translateable } from '@archnet/shared';
import React, { type AbstractComponent, useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import KeywordsService from '../services/Keywords';
import AssociatedRecordCountCell from '../components/AssociatedRecordCountCell';
import { CurrentFacetLabels, Toaster } from '@performant-software/semantic-components';
import { Card, Button, Message } from 'semantic-ui-react';
import _ from 'underscore';
import MergeKeywordsModal from '../components/MergeKeywordsModal';
import './Keywords.css';
import type { MergeRequest } from '../components/MergeKeywordsModal';

const Keywords: AbstractComponent<any> = withTranslation()((props: Translateable) => {
  const [selected, setSelected] = useState<Array<any>>([]);
  const [mergeOpen, setMergeOpen] = useState(false);
  const [key, setKey] = useState(1);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [processing, setProcessing] = useState(false);

  const isSelected = useCallback((item) => item && _.contains(selected, item), [selected]);

  const handleSelect = useCallback((item) => {
    if (isSelected(item)) {
      setSelected((prevItems) => _.filter(prevItems, (i) => item !== i));
    } else {
      setSelected((prevItems) => ([...prevItems, item]));
    }
  }, [selected]);

  const handleSelectAll = (items: Array<any>) => {
    setSelected(items);
  };

  const handleMerge = () => {
    setMergeOpen(true);
  };

  const handleMergeRequest = (merge: MergeRequest) => {
    setProcessing(true);
    KeywordsService.mergeRequest(merge).then((resp) => {
      setMergeOpen(false);
      setProcessing(false);
      setKey(key + 1);
      setSelected([]);
      if (!resp.status === 204) {
        setError(true);
        setErrorMessage('');
      }
    }).catch((err) => {
      setMergeOpen(false);
      setProcessing(false);
      setError(true);
      setErrorMessage(err);
    });
  };

  return (
    <>
      {selected.length > 0 && (
        <Card fluid className='merge-card'>
          <h3>{props.t('Keywords.titles.selected')}</h3>
          <CurrentFacetLabels items={selected.map((s: any) => ({ label: s.name }))} />
          <Button
            icon='fork'
            className='merge-button'
            label={props.t('Keywords.buttons.merge')}
            primary
            disabled={selected.length < 2}
            onClick={handleMerge}
          />
        </Card>
      )}
      <AdminListTable
        key={key}
        className='keywords'
        collectionName='keywords'
        searchable
        selectable
        onRowSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onSelectClear={() => setSelected([])}
        isRowSelected={isSelected}
        columns={[{
          name: 'name',
          label: props.t('Keywords.columns.name'),
          sortable: true
        }, {
          name: 'sites_keywords_count',
          label: props.t('Keywords.columns.associatedSites'),
          sortable: true,
          render: (item) => (
            <AssociatedRecordCountCell
              item={item}
              name='keywords_sites_count'
              recordType='site'
              recordTypeLabel={props.t('AssociatedRecordsButton.labels.sites')}
              associationName='keywords_sites'
              filterType='keyword'
              filterTypeLabel={props.t('AssociatedRecordsButton.labels.keyword')}
            />
          )
        }, {
          name: 'media_contents_keywords_count',
          label: props.t('Keywords.columns.associatedMediaRecords'),
          sortable: true,
          render: (item) => (
            <AssociatedRecordCountCell
              item={item}
              name='keywords_media_contents_count'
              recordType='media_content'
              recordTypeLabel={props.t('AssociatedRecordsButton.labels.mediaContent')}
              associationName='keywords_media_contents'
              filterType='keyword'
              filterTypeLabel={props.t('AssociatedRecordsButton.labels.keyword')}
            />
          )
        }, {
          name: 'publications_keywords_count',
          label: props.t('Keywords.columns.associatedPublications'),
          sortable: true,
          render: (item) => (
            <AssociatedRecordCountCell
              item={item}
              name='keywords_publications_count'
              recordType='publication'
              recordTypeLabel={props.t('AssociatedRecordsButton.labels.publications')}
              associationName='keywords_publications'
              filterType='keyword'
              filterTypeLabel={props.t('AssociatedRecordsButton.labels.keyword')}
            />
          )
        }]}
        onDelete={(Keyword) => KeywordsService.delete(Keyword)}
        onLoad={(params) => KeywordsService.fetchAll(params)}
        route='keywords'
      />
      <MergeKeywordsModal
        onClose={() => setMergeOpen(false)}
        keywords={selected}
        open={mergeOpen}
        onMerge={(merge: MergeRequest) => handleMergeRequest(merge)}
        processing={processing}
      />
      {error && (
        <Toaster
          onDismiss={() => setError(false)}
          timeout={0}
          type={Toaster.MessageTypes.negative}
        >
          <Message.Header
            content={props.t('Keywords.error.mergeFailed')}
          />
          <Message.List
            items={[errorMessage]}
          />
        </Toaster>
      )}
    </>
  );
});

export default Keywords;
