// @flow

import type { Donation, RecordAssociation, Translateable } from '@archnet/shared';
import React, { useState, type AbstractComponent, type Element } from 'react';
import { ListFilters, Selectize, ViewPDFButton } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header, Item } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedRecordsList from './AssociatedRecordsList';
import DonationFilters from '../filters/DonationFilters';
import DonationModal from './DonationModal';
import Donations from '../services/Donations';
import PrimaryImageView from './PrimaryImageView';
import PublishedLabel from './PublishedLabel';
import SelectizeHeader from './SelectizeHeader';

type Props = Translateable & {
  items: Array<RecordAssociation>,
  onDataLoaded?: (items: Array<RecordAssociation>) => void,
  onDelete: (item: RecordAssociation) => void,
  onLoad?: (params: any) => Promise<any>,
  onSave: (item: RecordAssociation) => void,
  onUpdate?: (items: Array<RecordAssociation>) => void,
  renderDescription: (item: RecordAssociation) => Element<any>,
  renderExtra: (item: RecordAssociation) => Element<any>,
  renderHeader: (item: RecordAssociation) => Element<any>,
  renderImage: (item: RecordAssociation) => Element<any>,
  renderMeta: (item: RecordAssociation) => Element<any>,
  resolveDonation: (item: RecordAssociation) => Donation
};

const AssociatedDonations = (props: Props) => {
  const [modal, setModal] = useState(false);

  /**
   * Renders the passed donation for the Selectize component.
   *
   * @param donation
   *
   * @returns {*}
   */
  const renderDonation = (donation: Donation) => (
    <Item.Group>
      <Item>
        <PrimaryImageView
          dimmable={false}
          item={donation}
          size='small'
        />
        <Item.Content>
          <Item.Header
            content={donation.name}
          />
          <Item.Meta
            content={donation.record_id}
          />
          <Item.Extra>
            <PublishedLabel
              icon
              published={donation.published}
            />
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
  );

  /**
   * Renders the extra information for the passed item.
   *
   * @param item
   *
   * @returns {JSX.Element|*}
   */
  const renderExtra = (item: RecordAssociation) => {
    if (props.renderExtra) {
      return props.renderExtra(item);
    }

    const donation = resolveDonation(item);

    return (
      <PublishedLabel
        icon
        published={donation.published}
      />
    );
  };

  /**
   * Renders the header for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderHeader = (item: RecordAssociation) => {
    if (props.renderHeader) {
      return props.renderHeader(item);
    }

    const donation = resolveDonation(item);

    return (
      <Link
        to={`/admin/donations/${donation.id}`}
      >
        <Header
          as='h3'
          content={donation.name}
        />
      </Link>
    );
  };

  /**
   * Renders the image for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderImage = (item: RecordAssociation) => {
    if (props.renderImage) {
      return props.renderImage(item);
    }

    const donation = resolveDonation(item);

    return (
      <PrimaryImageView
        item={donation}
      >
        { donation?.primary_image?.child?.content_url && (
          <ViewPDFButton
            primary
            url={`/admin/display?source=${donation?.primary_image?.child?.content_url}`}
          />
        )}
      </PrimaryImageView>
    );
  };

  /**
   * Renders the metadata for the passed item.
   *
   * @param item
   *
   * @returns {string|*}
   */
  const renderMeta = (item: RecordAssociation) => {
    if (props.renderMeta) {
      return props.renderMeta(item);
    }

    const donation = resolveDonation(item);
    return donation.record_id;
  };

  /**
   * Returns the related donation for the passed donation record association.
   *
   * @param item
   *
   * @returns {*|{}}
   */
  const resolveDonation = (item: RecordAssociation) => (props.resolveDonation && props.resolveDonation(item)) || {};

  return (
    <>
      <AssociatedRecordsList
        actions={[{
          basic: true,
          color: 'red',
          icon: 'trash',
          label: props.t('Common.buttons.delete'),
          onClick: props.onDelete.bind(this)
        }]}
        buttons={[{
          color: 'green',
          content: props.t('AssociatedDonations.buttons.add'),
          icon: 'plus',
          onClick: () => setModal(true)
        }]}
        items={props.items}
        onDataLoaded={props.onDataLoaded}
        onLoad={props.onLoad}
        onUpdate={props.onUpdate}
        renderExtra={renderExtra.bind(this)}
        renderDescription={props.renderDescription.bind(this)}
        renderHeader={renderHeader.bind(this)}
        renderImage={renderImage.bind(this)}
        renderMeta={renderMeta.bind(this)}
      />
      {modal && (
        <Selectize
          collectionName='donations'
          filters={{
            component: ListFilters,
            props: {
              filters: DonationFilters
            }
          }}
          modal={{
            component: DonationModal,
            onSave: (donation) => Donations.save(donation).then(({ data }) => data.donation)
          }}
          onClose={() => setModal(false)}
          onLoad={(params) => Donations.search({ ...params, sort_by: 'name', per_page: 5 })}
          onSave={(donations) => {
            props.onSave(donations);
            setModal(false);
          }}
          renderHeader={(headerProps) => (
            <SelectizeHeader
              {...headerProps}
              type='Donation'
            />
          )}
          renderItem={renderDonation.bind(this)}
          selectedItems={_.map(props.items, resolveDonation)}
          title={props.t('AssociatedDonations.selectize.title')}
          width='60%'
        />
      )}
    </>
  );
};

AssociatedDonations.defaultProps = {
  renderDescription: () => null
};

export default (withTranslation()(AssociatedDonations): AbstractComponent < any >);
