// @flow

import type { Translateable } from '@archnet/shared';
import { FileUpload, ItemCollection, LazyImage } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { useCallback, type AbstractComponent, type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Form, Grid, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import { MediaUtils } from '@archnet/shared';
import './UploadModal.css';

type Props = EditContainerProps & Translateable & {
  modal: {
    component: ComponentType<any>,
    props: any
  },
  onAddFile: (file: File) => void,
  title: string
};

const UploadModal: AbstractComponent<any> = withTranslation()((props: Props) => {
  /**
   * Adds the passed collection of files to the container.
   *
   * @type {(function(*=): void)|*}
   */
  const onFilesAdded = useCallback((files: Array<File>) => {
    _.each(files, (file) => {
      props.onSaveChildAssociation('files', {
        ...props.onAddFile(file),
        uid: uuid()
      });
    });
  }, [props.onAddFile, props.onSaveChildAssociation]);

  /**
   * Removes the passed file from the container.
   *
   * @type {(function(*=): void)|*}
   */
  const onFileRemoved = useCallback((file: File) => {
    props.onDeleteChildAssociation('files', file);
  }, [props.onDeleteChildAssociation]);

  const UploadForm = props.modal.component;

  return (
    <Modal
      as={Form}
      centered={false}
      className='upload-modal'
      open
    >
      <Modal.Header
        content={props.title}
      />
      <Modal.Content>
        <Grid
          columns={2}
          divided
          relaxed='very'
        >
          <Grid.Column>
            <UploadForm
              {...props}
              {...props.modal.props}
            />
          </Grid.Column>
          <Grid.Column>
            <FileUpload
              onFilesAdded={onFilesAdded}
            />
            { !_.isEmpty(props.item.files) && (
              <ItemCollection
                actions={[{
                  basic: true,
                  color: 'red',
                  icon: 'trash',
                  label: props.t('Common.buttons.delete'),
                  onClick: onFileRemoved.bind(this)
                }]}
                items={props.item.files}
                onDrag={(dragIndex, hoverIndex) => {
                  const files = [...props.item.files];
                  const file = files[dragIndex];

                  files.splice(dragIndex, 1);
                  files.splice(hoverIndex, 0, file);

                  props.onSetState({ files });
                }}
                renderEmptyList={() => null}
                renderHeader={(item) => item.name}
                renderMeta={() => ''}
                renderImage={(item) => (
                  <LazyImage
                    dimmable={false}
                    src={MediaUtils.getImageUrl(item)}
                  />
                )}
              />
            )}
          </Grid.Column>
        </Grid>
      </Modal.Content>
      { props.children }
    </Modal>
  );
});

export default UploadModal;
