// @flow

import React, { type Node } from 'react';
import './Logo.module.css';

type Props = {
  height?: string,
  width?: string
};

const Logo = (props: Props): Node => (
  <svg
    version='1.1'
    id='Layer_1'
    x='0px'
    y='0px'
    width={props.width}
    height={props.height}
    viewBox='0 0 200 60'
    enableBackground='new 0 0 200 60'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#2887c7'
      d='M105.824,44.279V32.641h-9.945v11.639h-5.711V16.36h5.711v10.88 h9.945V16.36h5.713v27.919H105.824z M76.467,39c2.131,0,4.127-1.16,5.992-3.48l4.033,3.92c-1.65,1.947-3.089,3.268-4.313,3.961 c-1.465,0.826-3.354,1.24-5.672,1.24c-3.914,0-6.977-1.441-9.187-4.32c-1.971-2.613-2.956-5.947-2.956-10s0.985-7.386,2.956-10 c2.156-2.88,5.205-4.32,9.146-4.32c2.157,0,3.98,0.38,5.473,1.14c1.49,0.76,2.941,1.994,4.354,3.7l-4.034,3.96 c-1.864-2.106-3.794-3.16-5.792-3.16c-2.184,0-3.821,0.947-4.912,2.84c-0.906,1.547-1.358,3.494-1.358,5.84s0.452,4.293,1.358,5.84 C72.646,38.055,74.283,39,76.467,39z M56.855,44.279l-5.592-9.359c-0.453-0.773-0.853-1.279-1.199-1.52s-0.918-0.359-1.717-0.359 h-1.398v11.238h-5.712V16.36h10.226c2.875,0,5.166,0.68,6.87,2.04c1.863,1.494,2.796,3.64,2.796,6.44 c0,1.734-0.466,3.274-1.398,4.62c-0.933,1.347-2.21,2.327-3.834,2.94l7.589,11.879H56.855z M51.503,21.88h-4.634V28h4.634 c2.609,0,3.914-1.04,3.914-3.12C55.417,22.88,54.112,21.88,51.503,21.88z M29.734,37.279h-9.067l-2.516,7H12L22.705,16.36h4.953 l10.784,27.919h-6.191L29.734,37.279z M25.181,24.8l-2.676,7.479h5.392L25.181,24.8z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#FFFFFF'
      d='M179.351,21.72V44h-5.713V21.72h-7.828v-5.64h21.369v5.64H179.351z M143.083,16.08h19.132v5.64h-13.421v5.24h11.703v5.4h-11.703v6h13.421V44h-19.132V16.08z M122.712,28.08V44H117V16.08h4.634 L131.778,32V16.08h5.712V44h-4.593L122.712,28.08z'
    />
  </svg>
);

Logo.defaultProps = {
  height: '60px',
  width: '200px'
};

export default Logo;
