// @flow

const formatDate = (dateString: ?string): string | null => {
  const date = withoutTime(dateString);
  return date && date.toLocaleDateString();
};

const withoutTime = (dateString: ?string): Date | null => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  return date;
};

export default {
  formatDate,
  withoutTime
};
