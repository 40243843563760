// @flow

import type { Option } from '../types/Option';
import AdminNotes from './AdminNotes';
import Attachments from './Attachments';
import FormDataTransform from './FormDataTransform';

import type { Donor as DonorType } from '@archnet/shared';
import RecordAssociations from './RecordAssociations';

/**
 * Class for handling transforming donor records.
 */
class Donor extends FormDataTransform {
  /**
   * Returns the donor parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'donor';
  }

  /**
   * Returns the donor payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'email',
      'phone',
      'general_info',
      'hcp_item',
      'award_winner',
      'flag_for_review',
      'locked'
    ];
  }

  /**
   * Returns the passed donor as a dropdown option.
   *
   * @param donor
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(donor: DonorType): Option {
    return {
      key: donor.id,
      value: donor.id,
      text: donor.name
    };
  }

  /**
   * Returns the donor object to be sent to the server on POST/PUT requests.
   *
   * @param donor
   *
   * @returns {*}
   */
  toPayload(donor: DonorType): any {
    const formData = super.toPayload(donor);

    AdminNotes.appendFormData(formData, this.getParameterName(), donor);
    RecordAssociations.appendFormData(formData, this.getParameterName(), donor, 'donation_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), donor, 'media_record_associations');

    return formData;
  }
}

export default (new Donor(): Donor);
