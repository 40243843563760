// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming nested countries records.
 */
class Countries extends NestedAttributes {
  /**
   * Returns the countries payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'country_id',
      '_destroy'
    ];
  }
}

export default (new Countries(): Countries);
