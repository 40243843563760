// @flow

import axios from 'axios';
import _ from 'underscore';
import history from './History';
import Session from '../services/Session';

const STATUS_UNAUTHORIZED = 401;

// Sets the authentication token as a request header
axios.interceptors.request.use((config) => {
  const user = Session.storage.getItem('user');

  if (user) {
    _.extend(config.headers, JSON.parse(user));
  }

  return config;
}, (error) => Promise.reject(error));

axios.interceptors.response.use((response) => response, (error) => {
  const { status } = error.response;
  if (status === STATUS_UNAUTHORIZED) {
    history.replace('/', {
      expired: true,
      url: history.location.pathname
    });
  }

  return Promise.reject(error);
});
