// @flow

import _ from 'underscore';

import type { StylePeriod as StylePeriodType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming style_period records.
 */
class StylePeriod {
  PAYLOAD_KEYS: Array<string> = [
    'id',
    'name',
    'description',
    'start_year',
    'end_year',
    '_destroy'
  ];

  /**
   * Returns the passed StylePeriod as a dropdown option.
   *
   * @param stylePeriod
   *
   * @returns {{key: number, value: number, text: string}}
   */
  toDropdown(stylePeriod: StylePeriodType): Option {
    return {
      key: stylePeriod.id,
      value: stylePeriod.id,
      text: stylePeriod.name
    };
  }

  /**
   * Returns the style_period for the passed site to be sent to the server on POST/PUT requests.
   *
   * @param stylePeriod
   *
   * @returns {{style_period: (*)}}
   */
  toPayload(stylePeriod: StylePeriod): any {
    return {
      style_period: _.pick(stylePeriod, this.PAYLOAD_KEYS)
    };
  }
}

export default (new StylePeriod(): StylePeriod);
