// @flow

import type { Country, Translateable } from '@archnet/shared';
import { Selectize } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Flag } from 'semantic-ui-react';
import _ from 'underscore';
import Countries from '../services/Countries';
import SimpleTagsList from './SimpleTagsList';

type Countryable = {
  id: number,
  country_id: number,
  country: Country
};

type Props = Translateable & {
  items: Array<Countryable>,
  onDelete: (item: Countryable) => void,
  onSave: (items: Array<Countryable>) => void
};

const CountriesList: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleTagsList
    items={_.filter(props.items, (item) => !item._destroy)}
    onDelete={props.onDelete.bind(this)}
    renderIcon={(item) => <Flag name={item.country.un_code && item.country.un_code.toLowerCase()} />}
    renderItem={(item) => item.country.name}
    renderModal={({ onClose }) => (
      <Selectize
        collectionName='countries'
        onClose={onClose}
        onLoad={(params) => Countries.fetchAll({ ...params, sort_by: 'name' })}
        onSave={(countries) => {
          const findCountry = (item: Countryable) => _.findWhere(props.items, { country_id: item.id });
          const createCountry = (item: Countryable) => ({ uid: uuid(), country_id: item.id, country: item });

          // Create or find items
          const items = _.map(countries, (country) => findCountry(country) || createCountry(country));

          // Save selected countries
          props.onSave(items);

          // Close the modal
          onClose();
        }}
        renderItem={(country) => (
          <span>
            <Flag
              name={country.un_code && country.un_code.toLowerCase()}
            />
            { country.name }
          </span>
        )}
        selectedItems={_.map(props.items, (item) => item.country)}
        title={props.t('CountriesList.title')}
      />
    )}
  />
));

export default CountriesList;
