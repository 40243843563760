// @flow

import axios from 'axios';

/**
 * Services class responsible for all authentication based API calls.
 */
class Authentication {
  /**
   * Calls the /api/auth/sign_in end point with the passed email and password.
   *
   * @param login
   * @param password
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  login(login: ?string, password: ?string): Promise<any> {
    return axios.post('/auth/sign_in', { login, password });
  }

  /**
   * Calls the api/auth/sign_out end point.
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  logout(): Promise<any> {
    return axios.delete('/auth/sign_out');
  }
}

export default (new Authentication(): Authentication);
