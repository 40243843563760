// @flow

import BaseService from './BaseService';
import HomePage from '../transforms/HomePage';

/**
 * Service class for all home_page based API calls.
 */
class HomePages extends BaseService {
  /**
   * Returns the home pages base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/home_pages';
  }

  /**
   * Returns the home pages transform.
   *
   * @returns {HomePage}
   */
  getTransform(): typeof HomePage {
    return HomePage;
  }
}

export default (new HomePages(): HomePages);
