// @flow

import BatchUpdateable from './BatchUpdateable';
import Donation from '../transforms/Donation';

/**
 * Service class for all donation based API calls.
 */
class Donations extends BatchUpdateable {
  /**
   * Returns the donations base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/donations';
  }

  /**
   * Returns the donations transform.
   *
   * @returns {Donation}
   */
  getTransform(): typeof Donation {
    return Donation;
  }
}

export default (new Donations(): Donations);
