// @flow

import _ from 'underscore';

import type { Material as MaterialType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming Material records.
 */
class Material {
  PAYLOAD_KEYS: Array<string> = [
    'id',
    'name',
    '_destroy'
  ];

  /**
   * Returns the passed Material as a dropdown option.
   *
   * @param material
   *
   * @returns {{key: number, value: number, text: string}}
   */
  toDropdown(material: MaterialType): Option {
    return {
      key: material.id,
      value: material.id,
      text: material.name
    };
  }

  /**
   * Returns the material to be sent to the server on POST/PUT requests.
   *
   * @param material
   *
   * @returns {{material: (*)}}
   */
  toPayload(material: MaterialType): any {
    return {
      material: _.pick(material, this.PAYLOAD_KEYS)
    };
  }
}

export default (new Material(): Material);
