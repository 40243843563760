// @flow

import  { MediaUtils, type MediaContent as MediaContentType, type Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Label } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedAuthorities from '../components/AssociatedAuthorities';
import AssociatedCollections from '../components/AssociatedCollections';
import AssociatedDonations from '../components/AssociatedDonations';
import AssociatedPublications from '../components/AssociatedPublications';
import AssociatedSites from '../components/AssociatedSites';
import DonorLabel from '../components/DonorLabel';
import ExternalSources from '../resources/media_contents/ExternalSources.json';
import Geography from '../components/Geography';
import KeywordsList from '../components/KeywordsList';
import LanguagesList from '../components/LanguagesList';
import MaterialsList from '../components/MaterialsList';
import MediaContentActions from '../components/MediaContentActions';
import MediaContents from '../services/MediaContents';
import MediaType from '../transforms/MediaType';
import MediaTypeModal from '../components/MediaTypeModal';
import MediaTypes from '../services/MediaTypes';
import { onMultiAddChildren, onMultiAddParents } from '../utils/RecordAssociation';
import RecordAssociations from '../services/RecordAssociations';
import RecordHeader from '../components/RecordHeader';
import RichTextArea from '../components/RichTextArea';
import SimpleEditPage from './SimpleEditPage';
import StylePeriodsList from '../components/StylePeriodsList';
import AssociatedMedia from '../components/AssociatedMedia';
import uuid from 'react-uuid';

type Props = Translateable & {
  ...EditContainerProps,
  item: MediaContentType
};

const Tabs = {
  details: 'details',
  geography: 'geography',
  sites: 'sites',
  collections: 'collections',
  publications: 'publications',
  authorities: 'authorities',
  donations: 'donations'
};

const MediaContent: AbstractComponent<any> = withTranslation()((props: Props) => {
  /**
   * Calls the `/api/media_types` API endpoint.
   *
   * @type {function(*): Promise<*>}
   */
  const onSearchMediaTypes = useCallback((search) => (
    MediaTypes.fetchAll({ per_page: 0, search, sort_by: 'name' })
  ), []);

  return (
    <SimpleEditPage
      {...props}
      className='media-content'
    >
      <SimpleEditPage.Header>
        <RecordHeader
          {...props}
          header={props.item.name}
          meta={props.item.record_id}
          renderImage={() => (
            <MediaContentActions
              item={props.item}
              onChange={props.onSetState}
            />
          )}
        />
      </SimpleEditPage.Header>
      <SimpleEditPage.Tab
        key={Tabs.details}
        name={props.t('Common.tabs.details')}
      >
        <Form.Input
          error={props.isError('name')}
          label={props.t('MediaContent.labels.name')}
          required={props.isRequired('name')}
          onChange={props.onTextInputChange.bind(this, 'name')}
          value={props.item.name || ''}
        />
        <Form.Input
          error={props.isError('media_type_id')}
          label={props.t('MediaContent.labels.type')}
          required={props.isRequired('media_type_id')}
        >
          <AssociatedDropdown
            collectionName='media_types'
            modal={{
              component: MediaTypeModal,
              props: {
                required: ['name', 'kind']
              },
              onSave: (mediaType) => MediaTypes.save(mediaType).then(({ data }) => data.media_type)
            }}
            onSearch={onSearchMediaTypes}
            onSelection={props.onAssociationInputChange.bind(this, 'media_type_id', 'media_type')}
            renderOption={(mediaType) => MediaType.toDropdown(mediaType)}
            searchQuery={props.item.media_type && props.item.media_type.name}
            value={props.item.media_type_id}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('year')}
          label={props.t('MediaContent.labels.year')}
          onChange={props.onTextInputChange.bind(this, 'year')}
          required={props.isRequired('year')}
          value={props.item.year || ''}
        />
        <Form.Input
          error={props.isError('year_description')}
          label={props.t('MediaContent.labels.yearDescription')}
          onChange={props.onTextInputChange.bind(this, 'year_description')}
          required={props.isRequired('year_description')}
          value={props.item.year_description || ''}
        />
        <Form.Input
          error={props.isError('caption')}
          label={props.t('MediaContent.labels.caption')}
          required={props.isRequired('caption')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('caption', null, { value })}
            value={props.item.caption || ''}
          />
        </Form.Input>
        <Form.Dropdown
          clearable
          error={props.isError('external_source')}
          label={props.t('MediaContent.labels.externalSource')}
          onChange={props.onTextInputChange.bind(this, 'external_source')}
          options={ExternalSources}
          required={props.isRequired('external_source')}
          selectOnBlur={false}
          selection
          value={props.item.external_source || ''}
        />
        <Form.Input
          error={props.isError('external_id')}
          label={props.t('MediaContent.labels.externalId')}
          onChange={props.onTextInputChange.bind(this, 'external_id')}
          required={props.isRequired('external_id')}
          value={props.item.external_id || ''}
        />
        <Form.Input
          error={props.isError('source_id')}
          label={props.t('MediaContent.labels.sourceId')}
          onChange={props.onTextInputChange.bind(this, 'source_id')}
          required={props.isRequired('source_id')}
          value={props.item.source_id || ''}
        />
        <Form.Input
          error={props.isError('source')}
          label={props.t('MediaContent.labels.source')}
          required={props.isRequired('source')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('source', null, { value })}
            value={props.item.source || ''}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('copyright')}
          label={props.t('MediaContent.labels.copyright')}
          required={props.isRequired('copyright')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('copyright', null, { value })}
            value={props.item.copyright || ''}
          />
        </Form.Input>
        <Form.TextArea
          error={props.isError('contributor')}
          label={props.t('MediaContent.labels.contributor')}
          onChange={props.onTextInputChange.bind(this, 'contributor')}
          required={props.isRequired('contributor')}
          value={props.item.contributor || ''}
        />
        <Form.Input
          error={props.isError('dimensions')}
          label={props.t('MediaContent.labels.dimensions')}
          onChange={props.onTextInputChange.bind(this, 'dimensions')}
          required={props.isRequired('dimensions')}
          value={props.item.dimensions || ''}
        />
        <Form.Input
          error={props.isError('description')}
          label={props.t('MediaContent.labels.description')}
          required={props.isRequired('description')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('description', null, { value })}
            value={props.item.description || ''}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('abstract')}
          label={props.t('MediaContent.labels.abstract')}
          required={props.isRequired('abstract')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('abstract', null, { value })}
            value={props.item.abstract || ''}
          />
        </Form.Input>
        <Form.Input
          label={props.t('MediaContent.labels.languages')}
        >
          <LanguagesList
            items={props.item.languages_media_contents}
            onDelete={props.onDeleteChildAssociation.bind(this, 'languages_media_contents')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'languages_media_contents')}
          />
        </Form.Input>
        <Form.Input
          label={props.t('MediaContent.labels.keywords')}
        >
          <KeywordsList
            items={props.item.keywords_media_contents}
            onDelete={props.onDeleteChildAssociation.bind(this, 'keywords_media_contents')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'keywords_media_contents')}
          />
        </Form.Input>
        <Form.Input
          label={props.t('MediaContent.labels.materials')}
        >
          <MaterialsList
            items={props.item.materials_media_contents}
            onDelete={props.onDeleteChildAssociation.bind(this, 'materials_media_contents')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'materials_media_contents')}
          />
        </Form.Input>
        <Form.Input
          label={props.t('MediaContent.labels.stylePeriods')}
        >
          <StylePeriodsList
            className='style-periods'
            items={props.item.media_contents_style_periods}
            onDelete={props.onDeleteChildAssociation.bind(this, 'media_contents_style_periods')}
            onDrag={(items) => props.onSetState({ media_contents_style_periods: items })}
            onSave={props.onSaveChildAssociation.bind(this, 'media_contents_style_periods')}
          />
        </Form.Input>
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.geography}
        name={props.t('Common.tabs.geography')}
      >
        <Geography
          {...props}
        />
      </SimpleEditPage.Tab>
      { MediaUtils.isVideo(props.item) && !MediaUtils.isExternal(props.item) && (
        <SimpleEditPage.Tab
          key={Tabs.media}
          name={props.t('Common.tabs.media')}
          count={props.item.associated_child_media_count}
        >
          <AssociatedMedia
            items={props.item.media_record_associations}
            modal={{
              props: {
                tabs: ['authorities', 'collections', 'donations']
              }
            }}
            onDataLoaded={(items) => props.onUpdateState({
              media_record_associations: [
                ...props.item.media_record_associations || [],
                ...items
              ]
            })}
            onDelete={props.onDeleteChildAssociation.bind(this, 'media_record_associations')}
            onEdit={(item, media) => props.onSaveChildAssociation('media_record_associations', {
              ...item,
              child: media
            })}
            onLoad={(params) => RecordAssociations.fetchAll({
              ...params,
              parent_id: props.item.id,
              parent_type: 'MediaContent',
              child_type: 'MediaContent'
            })}
            onSave={(media) => {
              _.each(media, (m) => {
                props.onSaveChildAssociation('media_record_associations', {
                  uid: uuid(),
                  child_id: m.id,
                  child_type: 'MediaContent',
                  child: m
                });
              });
            }}
            onSaveMultiple={onMultiAddChildren.bind(this, props, 'media_record_associations', 'MediaContent')}
            onSelectPrimary={(item) => props.onSetState({
              media_record_associations: _.map(
                props.item.media_record_associations,
                (i) => ({ ...i, primary: i === item })
              )
            })}
            onUpdate={(items) => props.onSetState({ media_record_associations: items })}
            resolveMedia={(item) => item.child}
            tabs={['authorities', 'collections', 'donations']}
          />
        </SimpleEditPage.Tab>
      )}
      <SimpleEditPage.Tab
        key={Tabs.sites}
        name={props.t('Common.tabs.sites')}
        count={props.item.associated_parent_sites_count}
      >
        <AssociatedSites
          items={props.item.site_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            site_record_associations: [
              ...props.item.site_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'site_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'MediaContent',
            parent_type: 'Site'
          })}
          onSave={onMultiAddParents.bind(this, props, 'site_record_associations', 'Site')}
          onUpdate={(items) => props.onSetState({ site_record_associations: items })}
          resolveSite={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.collections}
        name={props.t('Common.tabs.collections')}
        count={props.item.associated_parent_collections_count}
      >
        <AssociatedCollections
          items={props.item.collection_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            collection_record_associations: [
              ...props.item.collection_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'collection_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'MediaContent',
            parent_type: 'Collection'
          })}
          onSave={onMultiAddParents.bind(this, props, 'collection_record_associations', 'Collection')}
          onUpdate={(items) => props.onSetState({ collection_record_associations: items })}
          resolveCollection={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.publications}
        name={props.t('Common.tabs.publications')}
        count={props.item.associated_parent_publications_count}
      >
        <AssociatedPublications
          items={props.item.publication_record_associations}
          modal={{
            props: {
              tabs: ['authorities', 'collections', 'donations', 'sites']
            }
          }}
          onDataLoaded={(items) => props.onUpdateState({
            publication_record_associations: [
              ...props.item.publication_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'publication_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'MediaContent',
            parent_type: 'Publication'
          })}
          onEdit={(item, publication) => props.onSaveChildAssociation('publication_record_associations', {
            ...item,
            parent: publication
          })}
          onSave={onMultiAddParents.bind(this, props, 'publication_record_associations', 'Publication')}
          onSelectPrimary={(item) => props.onSetState({
            publication_record_associations: _.map(
              props.item.publication_record_associations,
              (i) => ({ ...i, primary: i === item })
            )
          })}
          onUpdate={(items) => props.onSetState({ publication_record_associations: items })}
          resolvePublication={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.authorities}
        name={props.t('Common.tabs.authorities')}
        count={props.item.associated_parent_authorities_count}
      >
        <AssociatedAuthorities
          items={props.item.authority_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            authority_record_associations: [
              ...props.item.authority_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authority_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'MediaContent',
            parent_type: 'Authority'
          })}
          onRoleSelection={props.onSaveChildAssociation.bind(this, 'authority_record_associations')}
          onSave={onMultiAddParents.bind(this, props, 'authority_record_associations', 'Authority')}
          onUpdate={(items) => props.onSetState({ authority_record_associations: items })}
          resolveAuthority={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.donations}
        name={props.t('Common.tabs.donations')}
        count={props.item.associated_parent_donations_count}
      >
        <AssociatedDonations
          items={props.item.donation_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            donation_record_associations: [
              ...props.item.donation_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'donation_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'MediaContent',
            parent_type: 'Donation'
          })}
          onSave={onMultiAddParents.bind(this, props, 'donation_record_associations', 'Donation')}
          onUpdate={(items) => props.onSetState({ donation_record_associations: items })}
          renderDescription={(item) => (
            <Label.Group>
              {_.map(item.parent.donor_record_associations, (ra) => <DonorLabel donor={ra.parent} />)}
            </Label.Group>
          )}
          resolveDonation={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  );
});

export default {
  component: MediaContent,
  onInitialize: (id: number): Promise<any> => (
    MediaContents
      .fetchOne(id)
      .then(({ data }) => data.media_content)
  ),
  onSave: (mediaContent: MediaContentType): Promise<any> => (
    MediaContents
      .save(mediaContent)
      .then(({ data }) => data.media_content)
  )
};
