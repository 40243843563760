// @flow

import type { Translateable } from '@archnet/shared';
import { ListFilters } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, RouterHistory } from 'react-router-dom';
import { Header, Label } from 'semantic-ui-react';
import _ from 'underscore';
import { RichText } from '@archnet/shared';
import { ActionTypes, getActions } from '../actions/Actions';
import AdminItemList from '../components/AdminItemList';
import Authorities from '../actions/Authorities';
import AuthorityLabel from '../components/AuthorityLabel';
import Collections from '../actions/Collections';
import CopyrightLabel from '../components/CopyrightLabel';
import ExternalSources from '../resources/media_contents/ExternalSources.json';
import Keywords from '../actions/Keywords';
import Languages from '../actions/Languages';
import LazyMedia from '../components/LazyMedia';
import Materials from '../actions/Materials';
import MediaContentFilters from '../filters/MediaContentFilters';
import MediaContentsService from '../services/MediaContents';
import MediaType from '../transforms/MediaType';
import MediaTypeLabel from '../components/MediaTypeLabel';
import MediaTypes from '../services/MediaTypes';
import PublishedLabel from '../components/PublishedLabel';
import SiteLabel from '../components/SiteLabel';
import Sites from '../actions/Sites';
import StylePeriods from '../actions/StylePeriods';
import './MediaContents.css';

type Props = Translateable & {
  history: typeof RouterHistory
};

const MediaContents: AbstractComponent<any> = withTranslation()(withRouter((props: Props) => (
  <AdminItemList
    className='media-contents'
    collectionName='media_contents'
    editActions={[{
      attributeName: 'media_type_id',
      collectionName: 'media_types',
      key: 'media_type',
      onLoad: (params) => MediaTypes.fetchAll({ ...params, per_page: 0, sort_by: 'name' }),
      renderOption: (mediaType) => MediaType.toDropdown(mediaType),
      text: props.t('MediaContents.actions.mediaType'),
      type: ActionTypes.relationship,
      value: 'media_type'
    }, {
      attributeName: 'year',
      key: 'year',
      text: props.t('MediaContents.actions.year'),
      type: ActionTypes.string,
      value: 'year'
    }, {
      attributeName: 'year_description',
      key: 'year_description',
      text: props.t('MediaContents.actions.yearDescription'),
      type: ActionTypes.string,
      value: 'yearDescription'
    }, {
      attributeName: 'external_source',
      key: 'external_source',
      options: ExternalSources,
      text: props.t('MediaContents.actions.externalSource'),
      type: ActionTypes.select,
      value: 'external_source'
    }, {
      attributeName: 'source_id',
      key: 'source_id',
      text: props.t('MediaContents.actions.sourceId'),
      type: ActionTypes.string,
      value: 'source_id'
    }, {
      attributeName: 'source',
      key: 'source',
      text: props.t('MediaContents.actions.source'),
      type: ActionTypes.text,
      value: 'source'
    }, {
      attributeName: 'copyright',
      key: 'copyright',
      text: props.t('MediaContents.actions.copyright'),
      type: ActionTypes.text,
      value: 'copyright'
    }, {
      attributeName: 'contributor',
      key: 'contributor',
      text: props.t('MediaContents.actions.contributor'),
      type: ActionTypes.string,
      value: 'contributor'
    }, {
      attributeName: 'dimensions',
      key: 'dimensions',
      text: props.t('MediaContents.actions.dimensions'),
      type: ActionTypes.string,
      value: 'dimensions'
    }, {
      ...Languages,
      associationName: 'languages_media_contents',
    }, {
      ...Keywords,
      associationName: 'keywords_media_contents'
    }, {
      ...Materials,
      associationName: 'materials_media_contents'
    }, {
      ...StylePeriods,
      associationName: 'media_contents_style_periods'
    }, {
      ...Authorities,
      parentType: 'Authority'
    }, {
      ...Collections,
      parentType: 'Collection'
    }, {
      ...Sites,
      parentType: 'Site'
    }, ...getActions()]}
    filters={{
      component: ListFilters,
      props: {
        filters: MediaContentFilters
      }
    }}
    onBatchDelete={(ids) => MediaContentsService.batchDelete(ids)}
    onBatchUpdate={(ids, params) => MediaContentsService.batchUpdate(ids, params)}
    onDelete={(mediaContent) => MediaContentsService.delete(mediaContent)}
    onLoad={(params) => MediaContentsService.search(params)}
    renderDescription={(item) => <RichText content={item.caption} />}
    renderExtra={(item) => (
      <Label.Group>
        <PublishedLabel icon published={item.published} />
        <MediaTypeLabel mediaType={item.media_type} />
        { _.map(item.site_record_associations, (ra) => <SiteLabel site={ra.parent} />) }
        { _.map(item.authority_record_associations, (ra) => <AuthorityLabel authority={ra.parent} />) }
        <CopyrightLabel copyright={item.copyright} />
      </Label.Group>
    )}
    renderHeader={(item) => <Header content={item.name} />}
    renderImage={(item) => <LazyMedia media={item} />}
    renderMeta={(item) => item.record_id}
    route='media_contents'
    sort={[{
      key: 'name',
      value: 'name',
      text: props.t('Common.sort.name')
    }, {
      key: 'record_id',
      value: 'record_id',
      text: props.t('Common.sort.recordId')
    }, {
      key: 'created_at',
      value: 'created_at',
      text: props.t('Common.sort.createdAt')
    }, {
      key: 'updated_at',
      value: 'updated_at',
      text: props.t('Common.sort.updatedAt')
    }, {
      key: 'date_published',
      value: 'date_published',
      text: props.t('Common.sort.datePublished')
    }]}
  />
)));

export default MediaContents;
