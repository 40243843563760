// @flow

import React, { type ComponentType, type AbstractComponent } from 'react';
import {
  Location,
  Redirect,
  Route,
  withRouter
} from 'react-router-dom';
import Session from '../services/Session';

type Props = {
  component: ComponentType<{}>,
  location: typeof Location
};

const AuthenticatedRoute: AbstractComponent<any> = withRouter(({ component, ...rest }: Props) => (
  <Route
    {...rest}
    render={(props) => {
      if (!Session.isAuthenticated()) {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: {
                url: rest.location.pathname
              }
            }}
          />
        );
      }

      const AuthenticatedComponent = component;
      return <AuthenticatedComponent {...props} />;
    }}
  />
));

export default AuthenticatedRoute;
