// @flow

import type { Option } from '../types/Option';
import AdminNotes from './AdminNotes';
import BuildingUsages from './BuildingUsages';
import EventItems from './EventItems';
import FormDataTransform from './FormDataTransform';
import Keywords from './Keywords';
import Materials from './Materials';
import RecordAssociations from './RecordAssociations';
import RelatedSites from './RelatedSites';
import SiteNames from './SiteNames';
import StylePeriods from './StylePeriods';

import type { Site as SiteType } from '@archnet/shared';

/**
 * Class for handling transforming site records.
 */
class Site extends FormDataTransform {
  /**
   * Returns the site parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'site';
  }

  /**
   * Returns the site payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'published',
      'overall_condition',
      'structural_stability',
      'architectural_integrity',
      'damage_assessment',
      'cultural_risk',
      'political_risk',
      'economic_risk',
      'social_risk',
      'environmental_risk',
      'potential_for_reuse',
      'feasibility',
      'viability',
      'conservation_status',
      'occupancy',
      'proposed_intervention',
      'priority',
      'place_name',
      'latitude',
      'longitude',
      'address_street',
      'address_province',
      'address_country',
      'address_postal_code',
      'address_region_state',
      'country_id',
      'flag_for_review',
      'award_winner',
      'hcp_item',
      'caption',
      'abstract',
      'description',
      'dimensions',
      'locked'
    ];
  }

  /**
   * Returns the passed site as a dropdown option.
   *
   * @param site
   *
   * @returns {{description: string, text: string, value: number, key: number}}
   */
  toDropdown(site: SiteType): Option {
    return {
      key: site.id,
      value: site.id,
      text: site.name,
      description: site.record_id
    };
  }

  /**
   * Returns the site object to be sent to the server on POST/PUT requests.
   *
   * @param site
   *
   * @returns {FormData}
   */
  toPayload(site: SiteType): FormData {
    const formData = super.toPayload(site);

    AdminNotes.appendFormData(formData, this.getParameterName(), site);
    BuildingUsages.appendFormData(formData, this.getParameterName(), site, 'building_usages_sites');
    EventItems.appendFormData(formData, this.getParameterName(), site, 'event_items');
    Keywords.appendFormData(formData, this.getParameterName(), site, 'keywords_sites');
    Materials.appendFormData(formData, this.getParameterName(), site, 'materials_sites');
    RecordAssociations.appendFormData(formData, this.getParameterName(), site, 'authority_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), site, 'media_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), site, 'collection_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), site, 'publication_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), site, 'parent_site_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), site, 'child_site_record_associations');
    RelatedSites.appendFormData(formData, this.getParameterName(), site);
    SiteNames.appendFormData(formData, this.getParameterName(), site);
    StylePeriods.appendFormData(formData, this.getParameterName(), site, 'sites_style_periods');

    return formData;
  }
}

export default (new Site(): Site);
