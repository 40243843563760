// @flow

import type { Publication, Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Header,
  Modal
} from 'semantic-ui-react';
import PublicationTypes from '../services/PublicationTypes';
import PublicationType from '../transforms/PublicationType';
import PublicationTypeModal from './PublicationTypeModal';

type Props = Translateable & {
  ...EditContainerProps,
  item: Publication
}

const PublicationModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='publication-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header>
      <Header>
        <Header.Content>
          { props.t('PublicationModal.title.edit') }
        </Header.Content>
        <Button
          basic
          color={props.item.published ? 'green' : undefined}
          content={props.item.published ? 'Published' : 'Unpublished'}
          floated='right'
          icon='send'
          onClick={props.onCheckboxInputChange.bind(this, 'published')}
        />
      </Header>
    </Modal.Header>
    <Modal.Content>
      <Form.Input
        error={props.isError('name')}
        label={props.t('PublicationModal.labels.name')}
        required={props.isRequired('name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('publication_type_id')}
        label={props.t('PublicationModal.labels.type')}
        required={props.isRequired('publication_type_id')}
      >
        <AssociatedDropdown
          collectionName='publication_types'
          modal={{
            component: PublicationTypeModal,
            onSave: (pt) => PublicationTypes.save(pt).then(({ data }) => data.publication_type)
          }}
          onSearch={(search) => PublicationTypes.fetchAll({ per_page: 0, search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'publication_type_id', 'publication_type')}
          renderOption={(publicationType) => PublicationType.toDropdown(publicationType)}
          searchQuery={props.item.publication_type && props.item.publication_type.name}
          value={props.item.publication_type_id || ''}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('year')}
        label={props.t('PublicationModal.labels.year')}
        required={props.isRequired('year')}
        onChange={props.onTextInputChange.bind(this, 'year')}
        value={props.item.year || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default PublicationModal;
