// @flow

import _ from 'underscore';

import type { BuildingType as BuildingTypeType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming building_type records.
 */
class BuildingType {
  PAYLOAD_KEYS: Array<string> = [
    'id',
    'name',
    '_destroy'
  ];

  /**
   * Returns the passed BuildingType as a dropdown option.
   *
   * @param buildingType
   *
   * @returns {{key: number, value: number, text: string, description: string}}
   */
  toDropdown(buildingType: BuildingTypeType): Option {
    return {
      key: buildingType.id,
      value: buildingType.id,
      text: buildingType.name,
    };
  }

  /**
   * Returns the building_type to be sent to the server on POST/PUT requests.
   *
   * @param buildingType
   *
   * @returns {{building_type: (*)}}
   */
  toPayload(buildingType: BuildingTypeType): any {
    return {
      building_type: _.pick(buildingType, this.PAYLOAD_KEYS)
    };
  }
}

export default (new BuildingType(): BuildingType);
