// @flow

import type { MediaType, Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';

type Props = Translateable & {
  color?: string,
  mediaType: ?MediaType
};

const MediaTypeLabel = (props: Props) => {
  if (!props.mediaType) {
    return null;
  }

  return (
    <Label
      color={props.color}
    >
      { props.t('MediaTypeLabel.labels.mediaType') }
      <Label.Detail
        content={props.mediaType && props.mediaType.name}
      />
    </Label>
  );
};

MediaTypeLabel.defaultProps = {
  color: 'blue'
};

export default (withTranslation()(MediaTypeLabel): AbstractComponent<any>);
