// @flow

import type {
  Collection as CollectionType,
  Country as CountryType,
  MediaContent as MediaContentType,
  Publication as PublicationType,
  Site as SiteType
} from '@archnet/shared';
import { FilterTypes } from '@performant-software/semantic-components';
import { type Filter } from '../types/Filter';
import Filters from './Filters';
import i18n from '../i18n/I18n';
import Collection from '../transforms/Collection';
import Collections from '../services/Collections';
import Countries from '../services/Countries';
import Country from '../transforms/Country';
import MediaContents from '../services/MediaContents';
import MediaContent from '../transforms/MediaContent';
import Publications from '../services/Publications';
import Publication from '../transforms/Publication';
import Site from '../transforms/Site';
import Sites from '../services/Sites';

const AuthorityFilters: Array<Filter> = [{
  associationColumn: 'authority_id',
  associationName: 'authority_names',
  attributeName: 'authority_names.first_name',
  key: 'authority_names.first_name',
  label: i18n.t('Authorities.filters.firstName'),
  type: FilterTypes.string
}, {
  associationColumn: 'authority_id',
  associationName: 'authority_names',
  attributeName: 'authority_names.last_name',
  key: 'authority_names.last_name',
  label: i18n.t('Authorities.filters.lastName'),
  type: FilterTypes.string
}, {
  associationColumn: 'authority_id',
  associationName: 'authority_names',
  attributeName: 'authority_names.country_id',
  collectionName: 'countries',
  key: 'authority_names.country_id',
  label: i18n.t('Authorities.filters.country'),
  objectName: 'country',
  onSearch: (search: string) => Countries.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (country) => Country.toDropdown(country),
  renderSearchQuery: (country: CountryType) => country.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'authority_id',
  associationName: 'authority_sources',
  attributeName: 'authority_sources.text',
  key: 'authority_sources.text',
  label: i18n.t('Authorities.filters.source'),
  type: FilterTypes.text
}, {
  attributeName: 'biography',
  key: 'biography',
  label: i18n.t('Authorities.filters.biography'),
  type: FilterTypes.text
}, {
  baseClass: 'Site',
  associationColumn: 'parent_id',
  associationName: 'authority_record_associations',
  attributeName: 'sites.id',
  collectionName: 'sites',
  key: 'site',
  label: i18n.t('Authorities.filters.site'),
  objectName: 'site',
  onSearch: (search: string) => Sites.fetchAll({ search, sort_by: 'site_names.name' }),
  renderOption: (site) => Site.toDropdown(site),
  renderSearchQuery: (site: SiteType) => site.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Collection',
  associationColumn: 'child_id',
  associationName: 'authority_record_associations',
  attributeName: 'collections.id',
  collectionName: 'collections',
  key: 'collection',
  label: i18n.t('Authorities.filters.collection'),
  objectName: 'collection',
  onSearch: (search: string) => Collections.fetchAll({ search, sort_by: 'name' }),
  renderOption: (collection) => Collection.toDropdown(collection),
  renderSearchQuery: (collection: CollectionType) => collection.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'MediaContent',
  associationColumn: 'parent_id',
  associationName: 'authority_record_associations',
  attributeName: 'media_contents.id',
  collectionName: 'media_contents',
  key: 'media_content',
  label: i18n.t('Authorities.filters.media'),
  objectName: 'media_content',
  onSearch: (search: string) => MediaContents.fetchAll({ search, sort_by: 'media_contents.name' }),
  renderOption: (mediaContent) => MediaContent.toDropdown(mediaContent),
  renderSearchQuery: (mediaContent: MediaContentType) => mediaContent.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Publication',
  associationColumn: 'parent_id',
  associationName: 'authority_record_associations',
  attributeName: 'publications.id',
  collectionName: 'publications',
  key: 'publication',
  label: i18n.t('Authorities.filters.publication'),
  objectName: 'publication',
  onSearch: (search: string) => Publications.fetchAll({ search, sort_by: 'publications.name' }),
  renderOption: (publication) => Publication.toDropdown(publication),
  renderSearchQuery: (publication: PublicationType) => publication.name,
  type: FilterTypes.relationship
}, ...Filters.getFilters()];

export default AuthorityFilters;
