// @flow

import BatchUpdateable from './BatchUpdateable';
import Site from '../transforms/Site';

/**
 * Service class for all site based API calls.
 */
class Sites extends BatchUpdateable {
  /**
   * Returns the sites base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/sites';
  }

  /**
   * Returns the sites transform.
   *
   * @returns {Site}
   */
  getTransform(): typeof Site {
    return Site;
  }
}

export default (new Sites(): Sites);
