// @flow

import _ from 'underscore';
import String from '../utils/String';
import BaseTransform from './BaseTransform';

class FormDataTransform extends BaseTransform {
  /**
   * Constructs a new FormDataTransform object. This constructor should never be used directly.
   */
  constructor() {
    super();

    // $FlowIgnore
    if (this.constructor === FormDataTransform) {
      throw new TypeError('Abstract class "FormDataTransform" cannot be instantiated directly.');
    }
  }

  /**
   * Returns the parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    // Implemented in sub-class
    return '';
  }

  /**
   * Returns the passed record to be set on POST/PUT requests.
   *
   * @param record
   *
   * @returns {FormData}
   */
  toPayload(record: any): FormData {
    const formData = new FormData();

    _.each(this.getPayloadKeys(), (key) => {
      formData.append(`${this.getParameterName()}[${key}]`, String.toString(record[key]));
    });

    return formData;
  }
}

export default FormDataTransform;
