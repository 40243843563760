// @flow

import type { BuildingUsage } from '@archnet/shared';
import i18n from '../i18n/I18n';
import { type Action } from '../types/Action';
import BuildingUsagesService from '../services/BuildingUsages';

const BuildingUsages: Action = {
  associationColumn: 'building_usage_id',
  collectionName: 'building_usages',
  key: 'building_usages',
  multiple: true,
  onLoad: (params: any) => BuildingUsagesService.fetchAll(params),
  renderOption: (buildingUsage: BuildingUsage) => buildingUsage.name,
  text: i18n.t('Common.actions.buildingUsages'),
  type: 'relationship',
  value: 'building_usages'
};

export default BuildingUsages;
