// @flow

import type { AdminNote, Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import NoteModal from './NoteModal';
import NotesList from './NotesList';

type Props = Translateable & {
  notes: Array<AdminNote>,
  onClose: () => void,
  onDelete: (note: AdminNote) => void,
  onSave: (notes: Array<AdminNote>) => void
};

const NotesModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    centered={false}
    className='notes'
    open
    size='small'
  >
    <Modal.Header
      content={props.t('NotesModal.header')}
    />
    <Modal.Content>
      <NotesList
        modal={{
          component: NoteModal
        }}
        notes={_.filter(props.notes, (note) => !note._destroy)}
        onDelete={(note) => {
          props.onDelete(note);
          return Promise.resolve();
        }}
        onSave={(note) => {
          // Add the newest note to the beginning of the list.
          props.onSave([
            note,
            ...props.notes
          ]);
          return Promise.resolve();
        }}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button
        content={props.t('Common.buttons.close')}
        inverted
        primary
        onClick={props.onClose.bind(this)}
      />
    </Modal.Actions>
  </Modal>
));

export default NotesModal;
