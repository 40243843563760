// @flow

import _ from 'underscore';
import BaseTransform from './BaseTransform';
import HomePageItems from './HomePageItems';

import type { HomePage as HomePageType } from '@archnet/shared';

/**
 * Class for handling home page records.
 */
class HomePage extends BaseTransform {
  /**
   * Returns the home page payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'title',
      'subtitle',
      'body',
      'start_date'
    ];
  }

  /**
   * Returns the passed home page for POST/PUT requests.
   *
   * @param homePage
   *
   * @returns {{home_page}}
   */
  toPayload(homePage: HomePageType): any {
    return {
      home_page: {
        ..._.pick(homePage, this.getPayloadKeys()),
        ...HomePageItems.toPayload(homePage)
      }
    };
  }
}

export default (new HomePage(): HomePage);
