// @flow

import { MediaUtils, type Publication as PublicationFlow, type Translateable } from '@archnet/shared';
import { AssociatedDropdown, FileInputButton, ViewPDFButton, } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Form, Label } from 'semantic-ui-react';
import _ from 'underscore';
import i18n from '../i18n/I18n';
import AssociatedAuthorities from '../components/AssociatedAuthorities';
import AssociatedCollections from '../components/AssociatedCollections';
import AssociatedDonations from '../components/AssociatedDonations';
import AssociatedMedia from '../components/AssociatedMedia';
import AssociatedPublications from '../components/AssociatedPublications';
import AssociatedSites from '../components/AssociatedSites';
import BuildingUsagesList from '../components/BuildingUsagesList';
import CountriesList from '../components/CountriesList';
import DonorLabel from '../components/DonorLabel';
import KeywordsList from '../components/KeywordsList';
import LanguagesList from '../components/LanguagesList';
import MaterialsList from '../components/MaterialsList';
import PrimaryImage from '../components/PrimaryImage';
import PublicationType from '../transforms/PublicationType';
import PublicationTypeModal from '../components/PublicationTypeModal';
import PublicationTypes from '../services/PublicationTypes';
import Publications from '../services/Publications';
import {
  onMultiAddChildren,
  onMultiAddParents,
  onMultiAddRelated
} from '../utils/RecordAssociation';
import RecordAssociations from '../services/RecordAssociations';
import RecordHeader from '../components/RecordHeader';
import RichTextArea from '../components/RichTextArea';
import SimpleEditPage from './SimpleEditPage';
import withAssociationType, { AssociationTypes, type AssociationTypeProps } from '../hooks/withAssociationType';
import './Publication.css';

type Props = AssociationTypeProps & Translateable & {
  ...EditContainerProps,
  item: PublicationFlow
};

const AssociationTypeDefaults = {
  icon: {
    [AssociationTypes.child]: 'file',
    [AssociationTypes.parent]: 'file outline',
    [AssociationTypes.related]: 'file alternate outline'
  },
  text: {
    [AssociationTypes.child]: i18n.t('Publication.associationTypes.child'),
    [AssociationTypes.parent]: i18n.t('Publication.associationTypes.parent'),
    [AssociationTypes.related]: i18n.t('Publication.associationTypes.related'),
  }
};

const Tabs = {
  details: 'details',
  sites: 'sites',
  collections: 'collections',
  publications: 'publications',
  authorities: 'authorities',
  donations: 'donations',
  media: 'media'
};

const Publication: AbstractComponent<any> = withTranslation()(withAssociationType(AssociationTypeDefaults)(
  (props: Props) => (
    <SimpleEditPage
      {...props}
      className='publication'
    >
      <SimpleEditPage.Header>
        <RecordHeader
          {...props}
          header={props.item.name}
          meta={props.item.record_id}
          renderImage={() => (
            <PrimaryImage
              download={props.item.content_download_url}
              item={props.item}
            >
              <FileInputButton
                color='orange'
                content={props.item.content_url
                  ? props.t('Common.buttons.replaceFile')
                  : props.t('Publication.buttons.upload')}
                icon={props.item.content_url ? 'refresh' : 'cloud upload'}
                onSelection={(files) => props.onSetState(MediaUtils.getSelectionState(files))}
              />
              { props.item.content_type === 'application/pdf' && (
                <ViewPDFButton
                  color='green'
                  url={`/admin/display?source=${props.item.content_url}`}
                />
              )}
            </PrimaryImage>
          )}
        />
      </SimpleEditPage.Header>
      <SimpleEditPage.Tab
        key={Tabs.details}
        name={props.t('Common.tabs.details')}
      >
        <Form.Input
          error={props.isError('name')}
          label={props.t('Publication.labels.name')}
          onChange={props.onTextInputChange.bind(this, 'name')}
          required={props.isRequired('name')}
          value={props.item.name || ''}
        />
        <Form.Input
          error={props.isError('publication_type_id')}
          label={props.t('Publication.labels.type')}
          required={props.isRequired('publication_type_id')}
        >
          <AssociatedDropdown
            collectionName='publication_types'
            modal={{
              component: PublicationTypeModal,
              onSave: (pt) => PublicationTypes.save(pt).then(({ data }) => data.publication_type)
            }}
            onSearch={(search) => PublicationTypes.fetchAll({ per_page: 0, search, sort_by: 'name' })}
            onSelection={props.onAssociationInputChange.bind(this, 'publication_type_id', 'publication_type')}
            renderOption={(publicationType) => PublicationType.toDropdown(publicationType)}
            searchQuery={props.item.publication_type && props.item.publication_type.name}
            value={props.item.publication_type_id}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('year')}
          label={props.t('Publication.labels.year')}
          onChange={props.onTextInputChange.bind(this, 'year')}
          required={props.isRequired('year')}
          value={props.item.year || ''}
          type='number'
        />
        <Form.Input
          error={props.isError('source_id')}
          label={props.t('Publication.labels.sourceId')}
          onChange={props.onTextInputChange.bind(this, 'source_id')}
          required={props.isRequired('source_id')}
          value={props.item.source_id || ''}
        />
        <Form.Input
          error={props.isError('source')}
          label={props.t('Publication.labels.source')}
          required={props.isRequired('source')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('source', null, { value })}
            value={props.item.source || ''}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('copyright')}
          label={props.t('Publication.labels.copyright')}
          required={props.isRequired('copyright')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('copyright', null, { value })}
            value={props.item.copyright || ''}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('contributor')}
          label={props.t('Publication.labels.contributor')}
          onChange={props.onTextInputChange.bind(this, 'contributor')}
          required={props.isRequired('contributor')}
          value={props.item.contributor || ''}
        />
        <Form.Input
          error={props.isError('dimensions')}
          label={props.t('Publication.labels.dimensions')}
          onChange={props.onTextInputChange.bind(this, 'dimensions')}
          required={props.isRequired('dimensions')}
          value={props.item.dimensions || ''}
        />
        <Form.Input
          error={props.isError('citation')}
          label={props.t('Publication.labels.citation')}
          required={props.isRequired('citation')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('citation', null, { value })}
            value={props.item.citation || ''}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('description')}
          label={props.t('Publication.labels.description')}
          required={props.isRequired('description')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('description', null, { value })}
            value={props.item.description || ''}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('abstract')}
          label={props.t('Publication.labels.abstract')}
          required={props.isRequired('abstract')}
        >
          <RichTextArea
            onChange={(value) => props.onTextInputChange('abstract', null, { value })}
            value={props.item.abstract || ''}
          />
        </Form.Input>
        <Form.Input
          label={props.t('Publication.labels.countries')}
        >
          <CountriesList
            items={props.item.countries_publications}
            onDelete={props.onDeleteChildAssociation.bind(this, 'countries_publications')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'countries_publications')}
          />
        </Form.Input>
        <Form.Input
          label={props.t('Publication.labels.languages')}
        >
          <LanguagesList
            items={props.item.languages_publications}
            onDelete={props.onDeleteChildAssociation.bind(this, 'languages_publications')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'languages_publications')}
          />
        </Form.Input>
        <Form.Input
          label={props.t('Publication.labels.keywords')}
        >
          <KeywordsList
            items={props.item.keywords_publications}
            onDelete={props.onDeleteChildAssociation.bind(this, 'keywords_publications')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'keywords_publications')}
          />
        </Form.Input>
        <Form.Input
          label={props.t('Publication.labels.materials')}
        >
          <MaterialsList
            items={props.item.materials_publications}
            onDelete={props.onDeleteChildAssociation.bind(this, 'materials_publications')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'materials_publications')}
          />
        </Form.Input>
        <Form.Input
          label={props.t('Publication.labels.buildingUsages')}
        >
          <BuildingUsagesList
            items={props.item.building_usages_publications}
            onDelete={props.onDeleteChildAssociation.bind(this, 'building_usages_publications')}
            onSave={props.onMultiAddChildAssociations.bind(this, 'building_usages_publications')}
          />
        </Form.Input>
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.media}
        name={props.t('Common.tabs.media')}
        count={props.item.associated_child_media_count}
      >
        <AssociatedMedia
          items={props.item.media_record_associations}
          modal={{
            props: {
              tabs: ['authorities', 'collections', 'donations']
            }
          }}
          onDataLoaded={(items) => props.onUpdateState({
            media_record_associations: [
              ...props.item.media_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'media_record_associations')}
          onEdit={(item, media) => props.onSaveChildAssociation('media_record_associations', {
            ...item,
            child: media
          })}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            parent_id: props.item.id,
            parent_type: 'Publication',
            child_type: 'MediaContent'
          })}
          onSave={(media) => {
            _.each(media, (m) => {
              props.onSaveChildAssociation('media_record_associations', {
                uid: uuid(),
                child_id: m.id,
                child_type: 'MediaContent',
                child: m
              });
            });
          }}
          onSaveMultiple={onMultiAddChildren.bind(this, props, 'media_record_associations', 'MediaContent')}
          onSelectPrimary={(item) => props.onSetState({
            media_record_associations: _.map(
              props.item.media_record_associations,
              (i) => ({ ...i, primary: i === item })
            )
          })}
          onUpdate={(items) => props.onSetState({ media_record_associations: items })}
          resolveMedia={(item) => item.child}
          tabs={['authorities', 'collections', 'donations']}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.sites}
        name={props.t('Common.tabs.sites')}
        count={props.item.associated_child_sites_count}
      >
        <AssociatedSites
          items={props.item.site_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            site_record_associations: [
              ...props.item.site_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'site_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'Publication',
            parent_type: 'Site'
          })}
          onSave={onMultiAddParents.bind(this, props, 'site_record_associations', 'Site')}
          onUpdate={(items) => props.onSetState({ site_record_associations: items })}
          resolveSite={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.collections}
        name={props.t('Common.tabs.collections')}
        count={props.item.associated_parent_collections_count}
      >
        <AssociatedCollections
          items={props.item.collection_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            collection_record_associations: [
              ...props.item.collection_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'collection_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'Publication',
            parent_type: 'Collection'
          })}
          onSave={onMultiAddParents.bind(this, props, 'collection_record_associations', 'Collection')}
          onUpdate={(items) => props.onSetState({ collection_record_associations: items })}
          resolveCollection={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.publications}
        name={props.t('Common.tabs.publications')}
        count={props.item.associated_child_publications_count
          + props.item.associated_parent_publications_count
          + props.item.related_parent_publications_count
          + props.item.related_child_publications_count}
      >
        {props.associationType === AssociationTypes.child && (
          <AssociatedPublications
            buttons={[{
              render: props.renderAssociationTypeDropdown.bind(this)
            }]}
            items={props.item.child_publication_record_associations}
            onDataLoaded={(items) => props.onUpdateState({
              child_publication_record_associations: [
                ...props.item.child_publication_record_associations || [],
                ...items
              ]
            })}
            onDelete={props.onDeleteChildAssociation.bind(this, 'child_publication_record_associations')}
            onLoad={(params) => RecordAssociations.fetchAll({
              ...params,
              parent_id: props.item.id,
              parent_type: 'Publication',
              child_type: 'Publication'
            })}
            onEdit={(item, publication) => props.onSaveChildAssociation('child_publication_record_associations', {
              ...item,
              child: publication
            })}
            onSave={(publications) => {
              _.each(publications, (p) => {
                props.onSaveChildAssociation('child_publication_record_associations', {
                  uid: uuid(),
                  child_id: p.id,
                  child_type: 'Publication',
                  child: p
                });
              });
            }}
            onSaveMultiple={onMultiAddChildren.bind(this, props, 'child_publication_record_associations', 'Publication')}
            onSelectPrimary={(item) => props.onSetState({
              child_publication_record_associations: _.map(
                props.item.child_publication_record_associations,
                (i) => ({ ...i, primary: i === item })
              )
            })}
            onUpdate={(items) => props.onSetState({ child_publication_record_associations: items })}
            resolvePublication={(item) => item.child}
          />
        )}
        {props.associationType === AssociationTypes.parent && (
          <AssociatedPublications
            buttons={[{
              render: props.renderAssociationTypeDropdown.bind(this)
            }]}
            items={props.item.parent_publication_record_associations}
            onDataLoaded={(items) => props.onUpdateState({
              parent_publication_record_associations: [
                ...props.item.parent_publication_record_associations || [],
                ...items
              ]
            })}
            onDelete={props.onDeleteChildAssociation.bind(this, 'parent_publication_record_associations')}
            onDrag={(items) => props.onSetState({ parent_publication_record_associations: items })}
            onLoad={(params) => RecordAssociations.fetchAll({
              ...params,
              child_id: props.item.id,
              child_type: 'Publication',
              parent_type: 'Publication'
            })}
            onEdit={(item, publication) => props.onSaveChildAssociation('parent_publication_record_associations', {
              ...item,
              parent: publication
            })}
            onSave={(publications) => {
              _.each(publications, (p) => {
                props.onSaveChildAssociation('parent_publication_record_associations', {
                  uid: uuid(),
                  child_id: p.id,
                  child_type: 'Publication',
                  child: p
                });
              });
            }}
            onSaveMultiple={onMultiAddParents.bind(this, props, 'parent_publication_record_associations', 'Publication')}
            onSelectPrimary={(item) => props.onSetState({
              parent_publication_record_associations: _.map(
                props.item.parent_publication_record_associations,
                (i) => ({ ...i, primary: i === item })
              )
            })}
            resolvePublication={(item) => item.parent}
          />
        )}
        {props.associationType === AssociationTypes.related && (
          <AssociatedPublications
            buttons={[{
              render: props.renderAssociationTypeDropdown.bind(this)
            }]}
            modal={{
              props: {
                tabs: ['authorities', 'collections', 'donations', 'sites']
              }
            }}
            items={props.item.related_publications}
            onDelete={props.onDeleteChildAssociation.bind(this, 'related_publications')}
            onDrag={(items) => props.onSetState({ related_publications: items })}
            onEdit={(item, publication) => props.onSaveChildAssociation('related_publications', {
              ...item,
              child: publication
            })}
            onSave={(publications) => {
              _.each(publications, (p) => {
                props.onSaveChildAssociation('related_publications', {
                  uid: uuid(),
                  child_publication_id: p.id,
                  child: p
                });
              });
            }}
            onSaveMultiple={onMultiAddRelated.bind(this, props, 'related_publications', 'child_publication_id')}
            onSelectPrimary={(item) => props.onSetState({
              related_publications: _.map(
                props.item.related_publications,
                (i) => ({ ...i, primary: i === item })
              )
            })}
            resolvePublication={(item) => item.child}
          />
        )}
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.authorities}
        name={props.t('Common.tabs.authorities')}
        count={props.item.associated_parent_authorities_count}
      >
        <AssociatedAuthorities
          items={props.item.authority_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            authority_record_associations: [
              ...props.item.authority_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authority_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'Publication',
            parent_type: 'Authority'
          })}
          onRoleSelection={props.onSaveChildAssociation.bind(this, 'authority_record_associations')}
          onSave={onMultiAddParents.bind(this, props, 'authority_record_associations', 'Authority')}
          onUpdate={(items) => props.onSetState({ authority_record_associations: items })}
          resolveAuthority={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
      <SimpleEditPage.Tab
        key={Tabs.donations}
        name={props.t('Common.tabs.donations')}
        count={props.item.associated_parent_donations_count}
      >
        <AssociatedDonations
          items={props.item.donation_record_associations}
          onDataLoaded={(items) => props.onUpdateState({
            donation_record_associations: [
              ...props.item.donation_record_associations || [],
              ...items
            ]
          })}
          onDelete={props.onDeleteChildAssociation.bind(this, 'donation_record_associations')}
          onLoad={(params) => RecordAssociations.fetchAll({
            ...params,
            child_id: props.item.id,
            child_type: 'Donation',
            parent_type: 'Publication'
          })}
          onSave={onMultiAddParents.bind(this, props, 'donation_record_associations', 'Donation')}
          onUpdate={(items) => props.onSetState({ donation_record_associations: items })}
          renderDescription={(item) => (
            <Label.Group>
              {_.map(item.parent.donor_record_associations, (ra) => <DonorLabel donor={ra.parent} />)}
            </Label.Group>
          )}
          resolveDonation={(item) => item.parent}
        />
      </SimpleEditPage.Tab>
    </SimpleEditPage>
  )
));

export default {
  component: Publication,
  onInitialize: (id: number): Promise<any> => (
    Publications
      .fetchOne(id)
      .then(({ data }) => data.publication)
  ),
  onSave: (publication: PublicationFlow): Promise<any> => (
    Publications
      .save(publication)
      .then(({ data }) => data.publication)
  )
};
