// @flow

import type { Donation, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';

type Props = Translateable & {
  ...EditContainerProps,
  item: Donation
};

const DonationModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='donation-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('DonationModal.title.edit')
        : props.t('DonationModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('name')}
        label={props.t('DonationModal.labels.name')}
        required={props.isRequired('name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('description')}
        label={props.t('DonationModal.labels.description')}
        required={props.isRequired('description')}
        onChange={props.onTextInputChange.bind(this, 'description')}
        value={props.item.description || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default DonationModal;
