// @flow

import useEditContainer from '../components/ANEditContainer';
import React, { type ComponentType, type AbstractComponent } from 'react';
import { RouterHistory, withRouter, useParams } from 'react-router-dom';

type Props = {
  component: ComponentType<any>,
  history: typeof RouterHistory,
  onInitialize: (id: number) => Promise<any>,
  onSave: (item: any) => Promise<any>
}

const EditPageContainer: AbstractComponent<any> = withRouter((props: Props) => {
  const { id } = useParams();
  const WrappedComponent = useEditContainer(props.component);
  const { pathname } = props.history.location;
  const url = pathname.substring(0, pathname.lastIndexOf('/'));

  return (
    <WrappedComponent
      {...props}
      item={{ id }}
      onClose={() => props.history.goBack()}
      onInitialize={(itemId: number) => props.onInitialize(itemId).catch(({ response }) => {
        if (response.status === 404) {
          props.history.replace({
            pathname: '/admin/404'
          });
        }
      })}
      onSave={(item) => props.onSave(item).then((record) => props.history.replace({
        pathname: `${url}/${record.id}`,
        state: {
          saved: true
        }
      }))}
    />
  );
});

export default EditPageContainer;
