// @flow

import React, { type Node } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Image, Label } from 'semantic-ui-react';
import './ImageLabel.css';

type Props = {
  color?: string,
  detail?: string,
  html?: boolean,
  icon?: string,
  image?: string,
  url?: string,
  value: string
};

const ImageLabel = (props: Props): Node => (
  <Label
    as={props.url ? Link : undefined}
    className='image-label'
    color={props.color}
    image={!!props.image}
    to={props.url}
  >
    { props.image && (
      <Image
        src={props.image}
      />
    )}
    { !props.image && props.icon && (
      <Icon
        name={props.icon}
      />
    )}
    { props.html && (
      <div
        className='image-label-custom-html'
        dangerouslySetInnerHTML={{ __html: props.value }}
      />
    )}
    { !props.html && (
      props.value
    )}
    { props.detail && (
      <Label.Detail
        content={props.detail}
      />
    )}
  </Label>
);

ImageLabel.defaultProps = {
  color: undefined,
  detail: undefined,
  html: false,
  icon: undefined,
  image: undefined,
  url: undefined
};

export default ImageLabel;
