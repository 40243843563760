// @flow

import type { AdminNote, Translateable } from '@archnet/shared';
import { useList as createList } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Item
} from 'semantic-ui-react';
import _ from 'underscore';
import './NotesList.css';

type Props = Translateable & {
  notes: Array<AdminNote>,
  onDelete: (item: AdminNote) => Promise<any>,
};

const NotesList = (props: Props) => {
  if (_.isEmpty(props.notes)) {
    return null;
  }

  return (
    <Item.Group
      className='notes-list'
      divided
      relaxed
    >
      {_.map(props.notes, (note) => (
        <Item>
          <Item.Content>
            <Item.Header
              content={note.title}
            />
            <Item.Meta
              content={note.created_at
                ? new Date(note.created_at).toString()
                : props.t('NotesList.justNow')}
            />
            <Item.Description
              content={note.note}
            />
          </Item.Content>
          <Item.Content>
            <Button
              basic
              floated='right'
              icon='times'
              onClick={props.onDelete.bind(this, note)}
            />
          </Item.Content>
        </Item>
      ))}
    </Item.Group>
  );
};

export default (withTranslation()(createList(NotesList)): AbstractComponent < any >);
