// @flow

import type { Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { useState, type Node, useCallback } from 'react';
import uuid from 'react-uuid';
import { Form, Label } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedAuthorities from './AssociatedAuthorities';
import AssociatedCollections from './AssociatedCollections';
import AssociatedDonations from './AssociatedDonations';
import DonorLabel from './DonorLabel';
import MediaType from '../transforms/MediaType';
import MediaTypeModal from './MediaTypeModal';
import MediaTypes from '../services/MediaTypes';
import RichTextArea from './RichTextArea';
import TabsMenu from './TabsMenu';

const Tabs = {
  details: 'details',
  sites: 'sites',
  authorities: 'authorities',
  collections: 'collections',
  donations: 'donations'
};

type Props = EditContainerProps & Translateable & {
  tabs: Array<string>
};

const MediaUploadForm = (props: Props): Node => {
  const [tab, setTab] = useState(Tabs.details);

  /**
   * Calls the `/api/media_types` API endpoint.
   *
   * @type {function(*): Promise<*>}
   */
  const onMediaTypeSearch = useCallback((search) => MediaTypes.fetchAll({ per_page: 0, search, sort_by: 'name' }), []);

  return (
    <div>
      <TabsMenu
        onTabClick={(t) => setTab(t.key)}
        tabs={[{
          active: tab === Tabs.details,
          key: Tabs.details,
          label: props.t('Common.tabs.details'),
          visible: true
        }, {
          active: tab === Tabs.sites,
          key: Tabs.sites,
          label: props.t('Common.tabs.sites'),
          visible: _.include(props.tabs, Tabs.sites)
        }, {
          active: tab === Tabs.authorities,
          key: Tabs.authorities,
          label: props.t('Common.tabs.authorities'),
          visible: _.include(props.tabs, Tabs.authorities)
        }, {
          active: tab === Tabs.collections,
          key: Tabs.collections,
          label: props.t('Common.tabs.collections'),
          visible: _.include(props.tabs, Tabs.collections)
        }, {
          active: tab === Tabs.donations,
          key: Tabs.donations,
          label: props.t('Common.tabs.donations'),
          visible: _.include(props.tabs, Tabs.donations)
        }]}
      />
      { tab === Tabs.details && (
        <>
          <Form.Input
            error={props.isError('media_type_id')}
            label={props.t('MediaContent.labels.type')}
            required={props.isRequired('media_type_id')}
          >
            <AssociatedDropdown
              collectionName='media_types'
              modal={{
                component: MediaTypeModal,
                props: {
                  required: ['name', 'kind']
                },
                onSave: (mediaType) => MediaTypes.save(mediaType).then(({ data }) => data.media_type)
              }}
              onSearch={onMediaTypeSearch}
              onSelection={props.onAssociationInputChange.bind(this, 'media_type_id', 'media_type')}
              renderOption={(mediaType) => MediaType.toDropdown(mediaType)}
              searchQuery={props.item.media_type && props.item.media_type.name}
              value={props.item.media_type_id}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('year')}
            label={props.t('MediaContent.labels.year')}
            onChange={props.onTextInputChange.bind(this, 'year')}
            required={props.isRequired('year')}
            value={props.item.year || ''}
          />
          <Form.Input
            error={props.isError('year_description')}
            label={props.t('MediaContent.labels.yearDescription')}
            onChange={props.onTextInputChange.bind(this, 'year_description')}
            required={props.isRequired('year_description')}
            value={props.item.year_description || ''}
          />
          <Form.Input
            error={props.isError('source_id')}
            label={props.t('MediaContent.labels.sourceId')}
            onChange={props.onTextInputChange.bind(this, 'source_id')}
            required={props.isRequired('source_id')}
            value={props.item.source_id || ''}
          />
          <Form.Input
            error={props.isError('source')}
            label={props.t('MediaContent.labels.source')}
            required={props.isRequired('source')}
          >
            <RichTextArea
              onChange={(value) => props.onTextInputChange('source', null, { value })}
              value={props.item.source || ''}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('copyright')}
            label={props.t('MediaContent.labels.copyright')}
            required={props.isRequired('copyright')}
          >
            <RichTextArea
              onChange={(value) => props.onTextInputChange('copyright', null, { value })}
              value={props.item.copyright || ''}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('contributor')}
            label={props.t('MediaContent.labels.contributor')}
            required={props.isRequired('contributor')}
          >
            <RichTextArea
              onChange={(value) => props.onTextInputChange('contributor', null, { value })}
              value={props.item.contributor || ''}
            />
          </Form.Input>
        </>
      )}
      { tab === Tabs.authorities && (
        <AssociatedAuthorities
          items={props.item.authority_record_associations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authority_record_associations')}
          onRoleSelection={props.onSaveChildAssociation.bind(this, 'authority_record_associations')}
          onSave={(authorities) => {
            _.each(authorities, (authority) => {
              props.onSaveChildAssociation('authority_record_associations', {
                uid: uuid(),
                parent_id: authority.id,
                parent_type: 'Authority',
                parent: authority
              });
            });
          }}
          onUpdate={(items) => props.onSetState({ authority_record_associations: items })}
          resolveAuthority={(item) => item.parent}
        />
      )}
      { tab === Tabs.collections && (
        <AssociatedCollections
          items={props.item.collection_record_associations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'collection_record_associations')}
          onSave={(collections) => {
            _.each(collections, (collection) => {
              props.onSaveChildAssociation('collection_record_associations', {
                uid: uuid(),
                parent_id: collection.id,
                parent_type: 'Collection',
                parent: collection
              });
            });
          }}
          onUpdate={(items) => props.onSetState({ collection_record_associations: items })}
          resolveCollection={(item) => item.parent}
        />
      )}
      { tab === Tabs.donations && (
        <AssociatedDonations
          items={props.item.donation_record_associations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'donation_record_associations')}
          onSave={(donations) => {
            _.each(donations, (donation) => {
              props.onSaveChildAssociation('donation_record_associations', {
                uid: uuid(),
                parent_id: donation.id,
                parent_type: 'Donation',
                parent: donation
              });
            });
          }}
          onUpdate={(items) => props.onSetState({ donation_record_associations: items })}
          renderDescription={(item) => (
            <Label.Group>
              { _.map(item.parent.donor_record_associations, (ra) => <DonorLabel donor={ra.parent} />) }
            </Label.Group>
          )}
          resolveDonation={(item) => item.parent}
        />
      )}
    </div>
  );
};

export default MediaUploadForm;
