// @flow

import MediaUtils from './Media';
import SiteUtils from './Site';
import _ from 'underscore';

import type { MediaContent } from '../types/MediaContent';

type ItemView = {
  description?: string,
  header?: string,
  image?: string,
  meta?: string,
  url?: string
};

const DefaultAttributes = {
  description: '',
  image: '',
  header: '',
  meta: ''
};

const ItemTypes = {
  authority: 'Authority',
  collection: 'Collection',
  donation: 'Donation',
  donor: 'Donor',
  media: 'MediaContent',
  publication: 'Publication',
  site: 'Site'
};

/**
 * Returns the url for the passed media content item.
 *
 * @param item
 *
 * @returns {*}
 */
const getMediaUrl = (item: MediaContent): ?string => {
  let url;

  if (!_.isEmpty(item.site_record_associations)) {
    url = `/sites/${_.first(item.site_record_associations).parent_id}?media_content_id=${item.id}`;
  } else if (!_.isEmpty(item.authority_record_associations)) {
    url = `/authorities/${_.first(item.authority_record_associations).parent_id}?media_content_id=${item.id}`;
  } else if (!_.isEmpty(item.collection_record_associations)) {
    url = `/collections/${_.first(item.collection_record_associations).parent_id}?media_content_id=${item.id}`;
  }

  return url;
};

const getViewAttributes = (item: any, type: string): ItemView => {
  let attributes;

  switch (type) {
    case ItemTypes.site:
      attributes = {
        description: item.description,
        header: item.name,
        image: MediaUtils.getImageUrl(item),
        meta: SiteUtils.formatLocation(item),
        url: `/sites/${item.id}`
      };
      break;

    case ItemTypes.authority:
      attributes = {
        description: item.biography,
        header: item.name,
        image: MediaUtils.getImageUrl(item),
        meta: item.country && item.country.name,
        url: `/authorities/${item.id}`
      };
      break;

    case ItemTypes.collection:
      attributes = {
        description: item.description,
        header: item.name,
        image: MediaUtils.getImageUrl(item),
        url: `/collections/${item.id}`
      };
      break;

    case ItemTypes.publication:
      attributes = {
        description: item.description,
        header: item.name,
        image: MediaUtils.getImageUrl(item),
        meta: item.year,
        url: `/publications/${item.id}`
      };
      break;

    case ItemTypes.media:
      attributes = {
        description: item.description,
        header: item.name,
        image: MediaUtils.getImageUrl(item),
        meta: item.media_type && item.media_type.name,
        url: getMediaUrl(item)
      };
      break;

    case ItemTypes.donation:
      attributes = {
        image: MediaUtils.getImageUrl(item),
        header: item.name,
        meta: item.record_id,
        url: `/donations/${item.id}`
      };
      break;

    case ItemTypes.donor:
      attributes = {
        image: MediaUtils.getPreviewUrl(item),
        header: item.name,
        meta: item.record_id,
        url: `/donors/${item.id}`
      };
      break;

    default:
      attributes = {};
  }

  return _.defaults(attributes, DefaultAttributes);
};

export default {
  getMediaUrl,
  getViewAttributes
};
