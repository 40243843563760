// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming nested building usage records.
 */
class BuildingUsages extends NestedAttributes {
  /**
   * Returns the building usages payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'building_usage_id',
      '_destroy'
    ];
  }
}

export default (new BuildingUsages(): BuildingUsages);
