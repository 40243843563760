// @flow

import type {Translateable} from '@archnet/shared';
import React, {type AbstractComponent} from 'react';
import {withTranslation} from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import CountriesService from '../services/Countries';

const Countries: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='countries'
    collectionName='countries'
    searchable
    columns={[{
      name: 'name',
      label: props.t('Countries.columns.name'),
      sortable: true
    }, {
      name: 'un_code',
      label: props.t('Countries.columns.code'),
      sortable: true
    }, {
      name: 'latitude',
      label: props.t('Countries.columns.latitude'),
      sortable: true
    }, {
      name: 'longitude',
      label: props.t('Countries.columns.longitude'),
      sortable: true
    }]}
    onDelete={(country) => CountriesService.delete(country)}
    onLoad={(params) => CountriesService.fetchAll(params)}
    perPageOptions={[10, 25, 50, 100]}
    route='countries'
  />
));

export default Countries;
