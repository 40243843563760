// @flow

import _ from 'underscore';

import type { MediaType as MediaTypeType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming media type records.
 */
class MediaType {
  PAYLOAD_KEYS: Array<string> = [
    'name',
    'kind'
  ];

  /**
   * Returns the passed media type as a dropdown option.
   *
   * @param mediaType
   *
   * @returns {{description: string, text: string, value: number, key: number}}
   */
  toDropdown(mediaType: MediaTypeType): Option {
    return {
      key: mediaType.id,
      value: mediaType.id,
      text: mediaType.name,
      description: mediaType.kind
    };
  }

  /**
   * Returns the media type object to be sent to the server on POST/PUT requests.
   *
   * @param mediaType
   *
   * @returns {{media_type: (*)}}
   */
  toPayload(mediaType: MediaTypeType): any {
    return {
      media_type: _.pick(mediaType, this.PAYLOAD_KEYS)
    };
  }
}

export default (new MediaType(): MediaType);
