// @flow

import {
  MediaUtils,
  RichText,
  type Publication,
  type RecordAssociation,
  type Translateable
} from '@archnet/shared';
import {
  DropdownButton,
  EditModal,
  ListFilters,
  Selectize,
  ViewPDFButton
} from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, {
  Component,
  type AbstractComponent,
  type ComponentType,
  type Element,
  type Node
} from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Header, Item, Label } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedRecordsList from './AssociatedRecordsList';
import PrimaryImageView from './PrimaryImageView';
import PublicationFilters from '../filters/PublicationFilters';
import PublicationModal from './PublicationModal';
import PublicationUploadForm from './PublicationUploadForm';
import Publications from '../services/Publications';
import PublishedLabel from './PublishedLabel';
import SelectizeHeader from './SelectizeHeader';
import UploadModal from './UploadModal';
import './AssociatedPublications.css';

type Props = Translateable & {
  ...EditContainerProps,
  buttons: ?Array<any>,
  items: Array<RecordAssociation>,
  modal: {
    component: ComponentType<any>,
    props: any
  },
  onDataLoaded?: (items: Array<RecordAssociation>) => void,
  onDelete: (item: RecordAssociation) => void,
  onEdit: (item: RecordAssociation, publication: Publication) => void,
  onLoad?: (params: any) => Promise<any>,
  onSave: (item: Array<Publication>) => void,
  onSaveMultiple: (item: Array<Publication>) => void,
  onSelectPrimary: (item: RecordAssociation) => void,
  onUpdate?: (items: Array<RecordAssociation>) => void,
  renderExtra: (item: RecordAssociation) => Element<any>,
  renderHeader: (item: RecordAssociation) => Element<any>,
  renderImage: (item: RecordAssociation) => Element<any>,
  renderMeta: (item: RecordAssociation) => Element<any>,
  resolvePublication: (item: RecordAssociation) => Publication
};

type State = {
  modal: ?string,
  selectedItem: ?RecordAssociation
};

const Modal = {
  link: 'link',
  upload: 'upload'
};

class AssociatedPublications extends Component<Props, State> {
  static defaultProps: any;

  /**
   * Constructs a new AssociatedPublications component.
   *
   * @param props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      modal: null,
      selectedItem: null
    };
  }

  /**
   * Saves the passed publication and clears the selected item from the state.
   *
   * @param item
   *
   * @returns {*}
   */
  onSaveEditModal(item: Publication): Promise<any> {
    return Publications
      .save(item)
      .then(({ data }) => {
        if (this.state.selectedItem) {
          this.props.onEdit(this.state.selectedItem, data.publication);
        }
        this.setState({ selectedItem: null });
      });
  }

  /**
   * Calls onSave and hides the link modal.
   *
   * @param items
   */
  onSaveLinkModal(items: Array<Publication>) {
    this.props.onSave(items);
    this.setState({ modal: null });
  }

  /**
   * Renders the AssociatedPublications component.
   *
   * @returns {*}
   */
  render(): Node {
    return (
      <div
        className='associated-publications'
      >
        <AssociatedRecordsList
          actions={[{
            basic: true,
            icon: 'check',
            label: this.props.t('AssociatedPublications.buttons.primary'),
            onClick: this.props.onSelectPrimary.bind(this),
            resolveColor: (item) => (item.primary ? 'green' : undefined)
          }, {
            basic: true,
            icon: 'edit',
            label: this.props.t('AssociatedPublications.buttons.edit'),
            onClick: (selectedItem) => this.setState({ selectedItem })
          }, {
            basic: true,
            color: 'red',
            icon: 'trash',
            label: this.props.t('AssociatedPublications.buttons.delete'),
            onClick: this.props.onDelete.bind(this)
          }]}
          buttons={[{
            render: () => this.renderAddButton()
          },
          ...(this.props.buttons || [])
          ]}
          items={this.props.items}
          onDataLoaded={this.props.onDataLoaded}
          onLoad={this.props.onLoad}
          onUpdate={this.props.onUpdate}
          renderExtra={(ra) => this.renderExtra(ra)}
          renderHeader={(ra) => this.renderHeader(ra)}
          renderImage={(ra) => this.renderImage(ra)}
          renderMeta={(ra) => this.renderMeta(ra)}
        />
        {this.renderEditModal()}
        {this.renderUploadModal()}
        {this.renderLinkModal()}
      </div>
    );
  }

  /**
   * Renders the add button component.
   *
   * @returns {JSX.Element}
   */
  renderAddButton(): Node {
    return (
      <DropdownButton
        color='blue'
        direction='right'
        icon='cloud upload'
        onChange={(e, { value }) => this.setState({ modal: value })}
        options={[{
          icon: 'laptop',
          key: Modal.upload,
          text: this.props.t('AssociatedPublications.buttons.computer'),
          value: Modal.upload
        }, {
          icon: 'linkify',
          key: Modal.link,
          text: this.props.t('AssociatedPublications.buttons.archnet'),
          value: Modal.link
        }]}
        text={this.props.t('AssociatedPublications.buttons.upload')}
        value={this.state.modal}
      />
    );
  }

  /**
   * Renders the edit modal component if an item has been selected.
   *
   * @returns {null|*}
   */
  renderEditModal(): Node {
    if (!this.state.selectedItem) {
      return null;
    }

    return (
      <EditModal
        component={PublicationModal}
        item={this.props.resolvePublication(this.state.selectedItem)}
        onClose={() => this.setState({ selectedItem: null })}
        onInitialize={(id: number) => Publications.fetchOne(id).then(({ data }) => data.publication)}
        onSave={(item) => this.onSaveEditModal(item)}
        required={['name', 'publication_type_id']}
      />
    );
  }

  /**
   * Renders the extra content for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  renderExtra(item: RecordAssociation): Node {
    if (this.props.renderExtra) {
      return this.props.renderExtra(item);
    }

    const publication = this.resolvePublication(item);

    return (
      <Label.Group>
        <PublishedLabel
          icon
          published={publication.published}
        />
        { publication.publication_type && (
          <Label>
            {this.props.t('AssociatedPublications.labels.type')}
            <Label.Detail
              content={publication.publication_type.name}
            />
          </Label>
        )}
        { publication.year && (
          <Label>
            {this.props.t('AssociatedPublications.labels.year')}
            <Label.Detail
              content={publication.year}
            />
          </Label>
        )}
        { publication.copyright && (
          <Label>
            {this.props.t('AssociatedPublications.labels.copyright')}
            <Label.Detail>
              <RichText
                content={publication.copyright}
              />
            </Label.Detail>
          </Label>
        )}
      </Label.Group>
    );
  }

  /**
   * Renders the header for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  renderHeader(item: RecordAssociation): Node {
    if (this.props.renderHeader) {
      return this.props.renderHeader(item);
    }

    const publication = this.resolvePublication(item);

    return (
      <Link
        to={`/admin/publications/${publication.id}`}
      >
        <Header
          as='h3'
          content={publication.name}
        />
      </Link>
    );
  }

  /**
   * Renders the image for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  renderImage(item: RecordAssociation): Node {
    if (this.props.renderImage) {
      return this.props.renderImage(item);
    }

    const publication = this.resolvePublication(item);

    return (
      <PrimaryImageView
        item={publication}
      >
        { publication.content_type === 'application/pdf' && (
          <ViewPDFButton
            primary
            url={`/admin/display?source=${publication.content_url}`}
          />
        )}
      </PrimaryImageView>
    );
  }

  /**
   * Renders the link modal.
   *
   * @returns {null|*}
   */
  renderLinkModal(): Node {
    if (this.state.modal !== Modal.link) {
      return null;
    }

    return (
      <Selectize
        collectionName='publications'
        filters={{
          component: ListFilters,
          props: {
            filters: PublicationFilters
          }
        }}
        onClose={() => this.setState({ modal: null })}
        onLoad={(params) => Publications.search({ ...params, per_page: 5, sort_by: 'name' })}
        onSave={(item) => this.onSaveLinkModal(item)}
        renderHeader={(headerProps) => (
          <SelectizeHeader
            {...headerProps}
            type='Publication'
          />
        )}
        renderItem={(publication) => this.renderPublication(publication)}
        selectedItems={_.map(this.props.items, (item) => this.resolvePublication(item))}
        title={this.props.t('AssociatedPublications.link.title')}
        width='60%'
      />
    );
  }

  /**
   * Renders the metadata for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  renderMeta(item: RecordAssociation): Node {
    if (this.props.renderMeta) {
      return this.props.renderMeta(item);
    }

    const publication = this.resolvePublication(item);

    return publication.publication_type && publication.publication_type.name;
  }

  /**
   * Renders the selectize item row for the passed publication.
   *
   * @param item
   *
   * @returns {*}
   */
  renderPublication(item: Publication): Node {
    return (
      <Item.Group>
        <Item>
          <Item.Image
            style={{
              width: 'unset'
            }}
          >
            <PrimaryImageView
              dimmable={false}
              item={item}
              size='tiny'
            />
          </Item.Image>
          <Item.Content>
            <Item.Header
              content={item.name}
            />
            <Item.Meta
              content={item.record_id}
            />
            <Item.Description
              content={item.publication_type && item.publication_type.name}
            />
            <Item.Extra>
              <PublishedLabel
                icon
                published={item.published}
              />
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    );
  }

  /**
   * Renders the file upload modal component.
   *
   * @returns {JSX.Element|null}
   */
  renderUploadModal(): Node {
    if (this.state.modal !== Modal.upload) {
      return null;
    }

    return (
      <EditModal
        component={UploadModal}
        modal={{
          component: PublicationUploadForm,
          ...this.props.modal
        }}
        form={PublicationUploadForm}
        onAddFile={(file) =>  ({
          ...MediaUtils.getSelectionState([file]),
          name: file.name
        })}
        onClose={() => this.setState({ modal: null })}
        onSave={(publications) => Publications
          .upload(publications)
          .then(({ data = {} }) => {
            this.setState({ modal: null });
            return this.props.onSave(data.publications);
          })}
        required={['publication_type_id']}
        title={this.props.t('AssociatedPublications.upload.title')}
      />
    );
  }

  /**
   * Returns the publication object for the passed item.
   *
   * @param item
   *
   * @returns {*|{}}
   */
  resolvePublication(item: RecordAssociation): Publication {
    return (this.props.resolvePublication && this.props.resolvePublication(item)) || {};
  }
}

AssociatedPublications.defaultProps = {
  buttons: []
};

export default (withTranslation()(AssociatedPublications): AbstractComponent<any>);
