// @flow

import { MediaUtils, PlayButton, type MediaContent } from '@archnet/shared';
import { LazyImage, LazyVideo } from '@performant-software/semantic-components';
import React, { type Node } from 'react';
import './LazyMedia.css';

type Props = {
  children?: Node,
  media: MediaContent
};

const LazyMedia = ({ media, children, ...props }: Props): Node => {
  if (MediaUtils.isVideo(media) && MediaUtils.isExternal(media)) {
    const src = MediaUtils.getExternalUrl(media);
    const preview = MediaUtils.getPreviewUrl(media);

    return (
      <LazyVideo
        {...props}
        autoPlay
        embedded
        icon={(
          <div
            className='lazy-media placeholder icon'
          >
            <div>
              <PlayButton />
            </div>
          </div>
        )}
        key={src}
        preview={preview}
        src={src}
      >
        { children }
      </LazyVideo>
    );
  }

  if (MediaUtils.isVideo(media)) {
    return (
      <LazyVideo
        {...props}
        key={media.content_url}
        preview={MediaUtils.getPreviewUrl(media)}
        src={media.content_url}
      >
        { children }
      </LazyVideo>
    );
  }

  if (MediaUtils.isImage(media) || media?.content_url) {
    return (
      <LazyImage
        {...props}
        key={media.content_url}
        preview={MediaUtils.getPreviewUrl(media)}
        src={MediaUtils.getImageUrl(media)}
      >
        { children }
      </LazyImage>
    );
  }

  return (
    <LazyImage
      {...props}
    >
      { children }
    </LazyImage>
  );
};

LazyMedia.defaultProps = {
  dimmable: true
};

export default LazyMedia;
