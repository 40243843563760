// @flow

import React, { type Element, type Node } from 'react';
import AppMedia from '../config/Media';

const mediaStyles = AppMedia.createMediaStyle();
const { MediaContextProvider } = AppMedia;

type Props = {
  children: Element<any>
};

const MediaContext = (props: Props): Node => (
  <MediaContextProvider>
    <style>
      { mediaStyles }
    </style>
    { props.children }
  </MediaContextProvider>
);

export default MediaContext;
