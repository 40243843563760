// @flow

import _ from 'underscore';

import type { Language as LanguageType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming language records.
 */
class Language {
  PAYLOAD_KEYS: Array<string> = [
    'name'
  ];

  /**
   * Returns the passed language as a dropdown option.
   *
   * @param language
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(language: LanguageType): Option {
    return {
      key: language.id,
      value: language.id,
      text: language.name
    };
  }

  /**
   * Returns the language to be sent to the server on POST/PUT requests.
   *
   * @param language
   *
   * @returns {{language: (*)}}
   */
  toPayload(language: LanguageType): any {
    return {
      language: _.pick(language, this.PAYLOAD_KEYS)
    };
  }
}

export default (new Language(): Language);
