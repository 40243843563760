// @flow

import _ from 'underscore';

import type { Country as CountryType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming Country records.
 */
class Country {
  PAYLOAD_KEYS: Array<string> = [
    'name',
    'un_code',
    'un_code_long',
    'region',
    'continent',
    'latitude',
    'longitude'
  ];

  /**
   * Returns the passed Country as a dropdown option.
   *
   * @param country
   *
   * @returns {{description: string, text: string, value: number, key: number}}
   */
  toDropdown(country: CountryType): Option {
    return {
      key: country.id,
      value: country.id,
      text: country.name,
      description: country.record_id
    };
  }

  /**
   * Returns the country object to be sent of PUT/POST requests.
   *
   * @param country
   *
   * @returns {{country: (*)}}
   */
  toPayload(country: CountryType): any {
    return {
      country: _.pick(country, this.PAYLOAD_KEYS)
    };
  }
}

export default (new Country(): Country);
