// @flow

import {
  DropdownButton,
  EditModal,
  ListFilters,
  Selectize
} from '@performant-software/semantic-components';
import React, {
  Component,
  type AbstractComponent,
  type ComponentType,
  type Element,
  type Node
} from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Header,
  Item,
  Label
} from 'semantic-ui-react';
import _ from 'underscore';
import {
  MediaSources,
  MediaTypes,
  RichText,
  type MediaContent,
  type RecordAssociation,
  type Translateable
} from '@archnet/shared';
import AssociatedRecordsList from './AssociatedRecordsList';
import LazyMedia from './LazyMedia';
import MediaContentFilters from '../filters/MediaContentFilters';
import MediaContentModal from './MediaContentModal';
import MediaContents from '../services/MediaContents';
import MediaUploadForm from './MediaUploadForm';
import PublishedLabel from './PublishedLabel';
import SelectizeHeader from './SelectizeHeader';
import UploadModal from './UploadModal';
import './AssociatedMedia.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';

type Props = Translateable & {
  ...EditContainerProps,
  items: Array<RecordAssociation>,
  modal: {
    component: ComponentType<any>,
    props: any
  },
  onDataLoaded?: (items: Array<RecordAssociation>) => void,
  onDelete: (item: RecordAssociation) => void,
  onEdit: (item: ?RecordAssociation, media: MediaContent) => void,
  onLoad?: (params: any) => Promise<any>,
  onSave: (items: Array<MediaContent>) => void,
  onSaveMultiple: (items: Array<MediaContent>) => void,
  onSelectPrimary: (item: RecordAssociation) => void,
  onUpdate?: (items: Array<RecordAssociation>) => void,
  renderDescription: (item: RecordAssociation) => Element<any>,
  renderHeader: (item: RecordAssociation) => Element<any>,
  renderImage: (item: RecordAssociation) => Element<any>,
  renderMeta: (item: RecordAssociation) => Element<any>,
  resolveMedia: (item: RecordAssociation) => MediaContent
};

type State = {
  filter: string,
  modal: ?string,
  selectedItem: ?RecordAssociation
};

const Filters = {
  all: 'all',
  images: 'images',
  videos: 'videos'
};

const Modal = {
  external: 'external',
  link: 'link',
  upload: 'upload'
};

class AssociatedMedia extends Component<Props, State> {
  static Views: any;

  /**
   * Constructs a new AssociatedMedia component.
   *
   * @param props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      filter: Filters.all,
      modal: null,
      selectedItem: null
    };
  }

  /**
   * Returns the list of media contents for the item's record associations.
   *
   * @returns {Array<$NonMaybeType<T>>|Array<T>}
   */
  getItems(): Array<RecordAssociation> {
    return _.filter(this.props.items, (item) => {
      let include = true;

      const media = this.props.resolveMedia(item);

      if (this.state.filter === Filters.images) {
        include = media.media_type.kind === MediaTypes.kind.image;
      } else if (this.state.filter === Filters.videos) {
        include = media.media_type.kind === MediaTypes.kind.video;
      }

      return include;
    });
  }

  /**
   * Saves the passed media content item and calls the onSave prop.
   *
   * @param item
   *
   * @returns {Q.Promise<void>}
   */
  onSaveAddModal(item: MediaContent): Promise<any> {
    return MediaContents
      .save(item)
      .then(({ data }) => {
        this.props.onSave([data.media_content]);
        this.setState({ modal: null });
      });
  }

  /**
   * Saves the passed item to the collection of record associations and closes the modal.
   *
   * @param item
   */
  onSaveEditModal(item: RecordAssociation): Promise<any> {
    return MediaContents
      .save(item)
      .then(({ data }) => {
        this.props.onEdit(this.state.selectedItem, data.media_content);
        this.setState({ selectedItem: null });
      });
  }

  /**
   * Saves the selected media as record associations and closes to modal.
   *
   * @param items
   */
  onSaveLinkModal(items: Array<MediaContent>) {
    this.props.onSaveMultiple(items);
    this.setState({ modal: null });
  }

  /**
   * Renders the AssociatedMedia component.
   *
   * @returns {*}
   */
  render(): Node {
    return (
      <div
        className='associated-media'
      >
        <AssociatedRecordsList
          actions={[{
            basic: true,
            icon: 'check',
            label: this.props.t('AssociatedMedia.buttons.primary'),
            onClick: this.props.onSelectPrimary.bind(this),
            resolveColor: (item) => (item.primary ? 'green' : undefined)
          }, {
            basic: true,
            icon: 'edit',
            label: this.props.t('AssociatedMedia.buttons.edit'),
            onClick: (selectedItem) => this.setState({ selectedItem })
          }, {
            basic: true,
            color: 'red',
            icon: 'trash',
            label: this.props.t('AssociatedMedia.buttons.delete'),
            onClick: this.props.onDelete.bind(this)
          }]}
          buttons={[{
            render: () => this.renderAddButton()
          }, {
            render: () => this.renderFilterButton()
          }]}
          items={this.getItems()}
          onDataLoaded={this.props.onDataLoaded}
          onLoad={this.props.onLoad}
          onUpdate={this.props.onUpdate}
          renderDescription={(ra) => this.renderDescription(ra)}
          renderExtra={(ra) => this.renderExtra(ra)}
          renderHeader={(ra) => this.renderHeader(ra)}
          renderImage={(ra) => this.renderImage(ra)}
          renderMeta={(ra) => this.renderMeta(ra)}
        />
        { this.renderAddModal() }
        { this.renderEditModal() }
        { this.renderLinkModal() }
        { this.renderUploadModal() }
      </div>
    );
  }

  /**
   * Renders the add button component.
   *
   * @returns {JSX.Element}
   */
  renderAddButton(): Node {
    return (
      <DropdownButton
        color='blue'
        direction='right'
        icon='cloud upload'
        onChange={(e, { value }) => this.setState({ modal: value })}
        options={[{
          icon: 'laptop',
          key: Modal.upload,
          text: this.props.t('AssociatedMedia.buttons.computer'),
          value: Modal.upload
        }, {
          icon: 'youtube',
          key: Modal.external,
          text: this.props.t('AssociatedMedia.buttons.youtube'),
          value: Modal.external
        }, {
          icon: 'linkify',
          key: Modal.link,
          text: this.props.t('AssociatedMedia.buttons.archnet'),
          value: Modal.link
        }]}
        text={this.props.t('AssociatedMedia.buttons.upload')}
        value={this.state.modal}
      />
    );
  }

  /**
   * Renders the add modal component.
   *
   * @returns {JSX.Element|null}
   */
  renderAddModal(): Node {
    if (this.state.modal !== Modal.external) {
      return null;
    }

    return (
      <EditModal
        component={MediaContentModal}
        item={{
          external_source: MediaSources.youtube
        }}
        onClose={() => this.setState({ modal: null })}
        onSave={(item) => this.onSaveAddModal(item)}
      />
    );
  }

  /**
   * Renders the description for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  renderDescription(item: RecordAssociation): Node {
    if (this.props.renderDescription) {
      return this.props.renderDescription(item);
    }

    const media = this.resolveMedia(item);

    return (
      <RichText
        content={media.caption}
      />
    );
  }

  /**
   * Renders the edit modal component if an item has been selected.
   *
   * @returns {null|*}
   */
  renderEditModal(): Node {
    if (!this.state.selectedItem) {
      return null;
    }

    return (
      <EditModal
        component={MediaContentModal}
        item={this.props.resolveMedia(this.state.selectedItem)}
        onClose={() => this.setState({ selectedItem: null })}
        onSave={(item) => this.onSaveEditModal(item)}
      />
    );
  }

  /**
   * Renders the extra content for the passed item.
   *
   * @param item
   *
   * @returns {null|*}
   */
  renderExtra(item: RecordAssociation): Node {
    if (!this.props.resolveMedia) {
      return null;
    }

    const media = this.props.resolveMedia(item);

    return (
      <Label.Group>
        <PublishedLabel
          icon
          published={media.published}
        />
        { media.media_type && (
          <Label>
            { this.props.t('AssociatedMedia.labels.type') }
            <Label.Detail
              content={media.media_type.name}
            />
          </Label>
        )}
        { media.year && (
          <Label>
            { this.props.t('AssociatedMedia.labels.year') }
            <Label.Detail
              content={media.year}
            />
          </Label>
        )}
        { media.copyright && (
          <Label>
            { this.props.t('AssociatedMedia.labels.copyright') }
            <Label.Detail>
              <RichText
                content={media.copyright}
              />
            </Label.Detail>
          </Label>
        )}
      </Label.Group>
    );
  }

  /**
   * Renders the filter button.
   *
   * @returns {*}
   */
  renderFilterButton(): Node {
    return (
      <DropdownButton
        color='teal'
        icon='filter'
        onChange={(e, { value }) => this.setState({ filter: value })}
        options={[{
          icon: 'images',
          key: Filters.all,
          text: this.props.t('AssociatedMedia.filters.all'),
          value: Filters.all
        }, {
          icon: 'photo',
          key: Filters.images,
          text: this.props.t('AssociatedMedia.filters.images'),
          value: Filters.images
        }, {
          icon: 'video',
          key: Filters.videos,
          text: this.props.t('AssociatedMedia.filters.videos'),
          value: Filters.videos
        }]}
        text={this.props.t(`AssociatedMedia.filters.${this.state.filter}`)}
        value={this.state.filter}
      />
    );
  }

  /**
   * Renders the header for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  renderHeader(item: RecordAssociation): Node {
    if (this.props.renderHeader) {
      return this.props.renderHeader(item);
    }

    const media = this.resolveMedia(item);

    return (
      <Link
        to={`/admin/media_contents/${media.id}`}
      >
        <Header
          as='h3'
          content={media.name}
        />
      </Link>
    );
  }

  /**
   * Renders the image for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  renderImage(item: RecordAssociation): Node {
    if (this.props.renderImage) {
      return this.props.renderImage(item);
    }

    const media = this.resolveMedia(item);

    return (
      <LazyMedia
        media={media}
      />
    );
  }

  /**
   * Renders the link modal.
   *
   * @returns {null|*}
   */
  renderLinkModal(): Node {
    if (this.state.modal !== Modal.link) {
      return null;
    }

    return (
      <Selectize
        collectionName='media_contents'
        filters={{
          component: ListFilters,
          props: {
            filters: MediaContentFilters
          }
        }}
        onClose={() => this.setState({ modal: null })}
        onLoad={(params) => MediaContents.search({ ...params, per_page: 5, sort_by: 'name' })}
        onSave={(items) => this.onSaveLinkModal(items)}
        renderHeader={(props) => (
          <SelectizeHeader
            {...props}
            type='MediaContent'
          />
        )}
        renderItem={(ra) => this.renderMediaContents(ra)}
        selectedItems={_.map(this.props.items, (item) => this.resolveMedia(item))}
        title={this.props.t('AssociatedMedia.link.title')}
        width='60%'
      />
    );
  }

  /**
   * Renders the passed media content item for the Selectize component.
   *
   * @param item
   *
   * @returns {*}
   */
  renderMediaContents(item: MediaContent): Node {
    return (
      <Item.Group>
        <Item>
          <Item.Image
            style={{
              width: 'unset'
            }}
          >
            <LazyMedia
              dimmable={false}
              media={item}
              size='tiny'
            />
          </Item.Image>
          <Item.Content>
            <Item.Header
              content={item.name}
            />
            <Item.Meta>
              <RichText
                content={item.caption}
              />
            </Item.Meta>
            <Item.Meta
              content={item.record_id}
            />
            <Item.Description
              content={item.caption}
            />
            <Item.Extra>
              <PublishedLabel
                icon
                published={item.published}
              />
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    );
  }

  /**
   * Renders the metadata for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  renderMeta(item: RecordAssociation): Node {
    if (this.props.renderMeta) {
      return this.props.renderMeta(item);
    }

    const media = this.resolveMedia(item);
    return media.record_id;
  }

  /**
   * Returns the media contents object for the passed record association.
   *
   * @param item
   *
   * @returns {*|{}}
   */
  resolveMedia(item: RecordAssociation): MediaContent {
    return (this.props.resolveMedia && this.props.resolveMedia(item)) || {};
  }

  /**
   * Renders the upload modal.
   *
   * @returns {JSX.Element|null}
   */
  renderUploadModal(): Node {
    if (this.state.modal !== Modal.upload) {
      return null;
    }

    return (
      <EditModal
        component={UploadModal}
        modal={{
          component: MediaUploadForm,
          ...this.props.modal
        }}
        form={MediaUploadForm}
        onAddFile={(file) => {
          const url = URL.createObjectURL(file);

          return {
            content: file,
            content_download_url: url,
            content_iiif_url: url,
            content_preview_url: url,
            content_thumbnail_url: url,
            content_url: url,
            content_type: file.type,
            name: file.name
          };
        }}
        onClose={() => this.setState({ modal: null })}
        onSave={(media) => MediaContents
          .upload(media)
          .then(({ data = {} }) => {
            this.setState({ modal: null });
            return this.props.onSave(data.media_contents);
          })}
        required={['media_type_id']}
        title={this.props.t('AssociatedMedia.upload.title')}
      />
    );
  }
}

export default (withTranslation()(AssociatedMedia): AbstractComponent<any>);
