// @flow

import { ItemUtils, type Publication as PublicationType } from '@archnet/shared';
import React from 'react';
import { LazyImage } from '@performant-software/semantic-components';
import { Item } from 'semantic-ui-react';
import { type Action } from '../types/Action';
import i18n from '../i18n/I18n';
import PublicationFilters from '../filters/PublicationFilters';
import PublicationsService from '../services/Publications';
import SelectizeHeader from '../components/SelectizeHeader';

const Publications: Action = {
  collectionName: 'publications',
  filters: PublicationFilters,
  key: 'publications',
  multiple: true,
  onLoad: (params: any) => PublicationsService.fetchAll({ ...params, per_page: 5 }),
  renderHeader: (props: any) => (
    <SelectizeHeader
      {...props}
      type='Publication'
    />
  ),
  renderOption: (publication: PublicationType) => {
    const attributes = ItemUtils.getViewAttributes(publication, 'Publication');

    return (
      <Item.Group>
        <Item>
          <Item.Image
            style={{
              width: 'unset'
            }}
          >
            <LazyImage
              dimmable={false}
              src={attributes.image}
              size='tiny'
            />
          </Item.Image>
          <Item.Content>
            <Item.Header
              content={attributes.header}
            />
            <Item.Meta
              content={attributes.meta}
            />
          </Item.Content>
        </Item>
      </Item.Group>
    );
  },
  renderSelected: (items: Array<PublicationType>) => (
    <SelectizeHeader
      collapsable={false}
      perPage={3}
      selectedItems={items}
      type='Publication'
    />
  ),
  text: i18n.t('Common.actions.publications'),
  type: 'relationship',
  value: 'publications'
};

export default Publications;
