// @flow

import type { AdminNote, Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Form,
  Header,
  Modal
} from 'semantic-ui-react';

type Props = Translateable & {
  ...EditContainerProps,
  item: AdminNote
}

const NoteModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='note-modal'
    noValidate
    open
    size='small'
  >
    <Header
      content={props.t('NoteModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        error={props.isError('title')}
        label={props.t('NoteModal.labels.title')}
        onChange={props.onTextInputChange.bind(this, 'title')}
        required={props.isRequired('title')}
        value={props.item.title || ''}
      />
      <Form.TextArea
        error={props.isError('note')}
        label={props.t('NoteModal.labels.content')}
        onChange={props.onTextInputChange.bind(this, 'note')}
        required={props.isRequired('note')}
        value={props.item.note || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default NoteModal;
