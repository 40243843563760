// @flow

import type { Donor, RecordAssociation, Translateable } from '@archnet/shared';
import React, { useState, type Element, type AbstractComponent } from 'react';
import { ListFilters, Selectize } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header, Item } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedRecordsList from './AssociatedRecordsList';
import DonorFilters from '../filters/DonorFilters';
import DonorModal from './DonorModal';
import Donors from '../services/Donors';
import PrimaryImageView from './PrimaryImageView';
import PublishedLabel from './PublishedLabel';
import SelectizeHeader from './SelectizeHeader';

type Props = Translateable & {
  items: Array<RecordAssociation>,
  onDataLoaded?: (items: Array<RecordAssociation>) => void,
  onDelete: (item: RecordAssociation) => void,
  onLoad?: (params: any) => Promise<any>,
  onSave: (item: RecordAssociation) => void,
  onUpdate?: (items: Array<RecordAssociation>) => void,
  renderExtra: (item: RecordAssociation) => Element<any>,
  renderHeader: (item: RecordAssociation) => Element<any>,
  renderImage: (item: RecordAssociation) => Element<any>,
  renderMeta: (item: RecordAssociation) => Element<any>,
  resolveDonor: (item: RecordAssociation) => Donor
};

const AssociatedDonors: AbstractComponent<any> = withTranslation()((props: Props) => {
  const [modal, setModal] = useState(false);

  /**
   * Renders the passed donor for the Selectize component.
   *
   * @param donor
   *
   * @returns {*}
   */
  const renderDonor = (donor: Donor) => (
    <Item.Group>
      <Item>
        <Item.Image
          style={{
            width: 'unset'
          }}
        >
          <PrimaryImageView
            item={donor}
            size='tiny'
          />
        </Item.Image>
        <Item.Content>
          <Item.Header
            content={donor.name}
          />
          <Item.Meta
            content={donor.record_id}
          />
          <Item.Extra>
            <PublishedLabel
              icon
              published={donor.published}
            />
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
  );

  /**
   * Renders the extra information for the passed item.
   *
   * @param item
   *
   * @returns {JSX.Element|*}
   */
  const renderExtra = (item: RecordAssociation) => {
    if (props.renderExtra) {
      return props.renderExtra(item);
    }

    const donor = resolveDonor(item);

    return (
      <PublishedLabel
        icon
        published={donor.published}
      />
    );
  };

  /**
   * Renders the header for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderHeader = (item: RecordAssociation) => {
    if (props.renderHeader) {
      return props.renderHeader(item);
    }

    const donor = resolveDonor(item);

    return (
      <Link
        to={`/admin/donors/${donor.id}`}
      >
        <Header
          as='h3'
          content={donor.name}
        />
      </Link>
    );
  };

  /**
   * Renders the image for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderImage = (item: RecordAssociation) => {
    if (props.renderImage) {
      return props.renderImage(item);
    }

    const donor = resolveDonor(item);

    return (
      <PrimaryImageView
        item={donor}
      />
    );
  };

  /**
   * Renders the metadata for the passed item.
   *
   * @param item
   *
   * @returns {string|*}
   */
  const renderMeta = (item: RecordAssociation) => {
    if (props.renderMeta) {
      return props.renderMeta(item);
    }

    const donor = resolveDonor(item);
    return donor.record_id;
  };

  /**
   * Returns the related donor for the passed donor record association.
   *
   * @param item
   *
   * @returns {*|{}}
   */
  const resolveDonor = (item: RecordAssociation) => (props.resolveDonor && props.resolveDonor(item)) || {};

  return (
    <>
      <AssociatedRecordsList
        actions={[{
          basic: true,
          color: 'red',
          icon: 'trash',
          label: props.t('Common.buttons.delete'),
          onClick: props.onDelete.bind(this)
        }]}
        buttons={[{
          color: 'green',
          content: props.t('AssociatedDonors.buttons.add'),
          icon: 'plus',
          onClick: () => setModal(true)
        }]}
        items={props.items}
        onDataLoaded={props.onDataLoaded}
        onLoad={props.onLoad}
        onUpdate={props.onUpdate}
        renderExtra={renderExtra.bind(this)}
        renderHeader={renderHeader.bind(this)}
        renderImage={renderImage.bind(this)}
        renderMeta={renderMeta.bind(this)}
      />
      { modal && (
        <Selectize
          collectionName='donors'
          filters={{
            component: ListFilters,
            props: {
              filters: DonorFilters
            }
          }}
          modal={{
            component: DonorModal,
            onSave: (donor) => Donors.save(donor).then(({ data }) => data.donor)
          }}
          onClose={() => setModal(false)}
          onLoad={(params) => Donors.search({ ...params, sort_by: 'name', per_page: 5 })}
          onSave={(donors) => {
            props.onSave(donors);
            setModal(false);
          }}
          renderHeader={(headerProps) => (
            <SelectizeHeader
              {...headerProps}
              type='Donor'
            />
          )}
          renderItem={renderDonor.bind(this)}
          selectedItems={_.map(props.items, resolveDonor)}
          title={props.t('AssociatedDonors.selectize.title')}
          width='60%'
        />
      )}
    </>
  );
});

export default AssociatedDonors;
