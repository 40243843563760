// @flow

import { type Material } from '@archnet/shared';
import i18n from '../i18n/I18n';
import { type Action } from '../types/Action';
import MaterialsService from '../services/Materials';

const Materials: Action = {
  associationColumn: 'material_id',
  collectionName: 'materials',
  key: 'materials',
  multiple: true,
  onLoad: (params: any) => MaterialsService.fetchAll(params),
  renderOption: (material: Material) => material.name,
  text: i18n.t('Common.actions.materials'),
  type: 'relationship',
  value: 'materials'
};

export default Materials;
