// @flow

import type { BuildingUsage, Translateable } from '@archnet/shared';
import { Selectize } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Item } from 'semantic-ui-react';
import _ from 'underscore';
import BuildingUsageModal from './BuildingUsageModal';
import BuildingUsages from '../services/BuildingUsages';
import SimpleTagsList from './SimpleTagsList';

type BuildingUsageable = {
  id: number,
  building_usage_id: number,
  building_usage: BuildingUsage
};

type Props = Translateable & {
  items: Array<BuildingUsageable>,
  onDelete: (item: BuildingUsageable) => void,
  onSave: (items: Array<BuildingUsageable>) => void
};

const BuildingUsagesList: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleTagsList
    items={_.filter(props.items, (item) => !item._destroy)}
    onDelete={props.onDelete.bind(this)}
    renderDetail={(item) => item.building_usage.building_type_name}
    renderItem={(item) => item.building_usage.name}
    renderModal={({ onClose }) => (
      <Selectize
        collectionName='building_usages'
        modal={{
          component: BuildingUsageModal,
          onSave: (bu) => BuildingUsages.save(bu).then(({ data }) => data.building_usage)
        }}
        onClose={onClose}
        onLoad={(params) => BuildingUsages.fetchAll({ ...params, per_page: 8, sort_by: 'name' })}
        onSave={(buildingUsages) => {
          const findBuildingUsage = (item: BuildingUsageable) => (
            _.findWhere(props.items, { building_usage_id: item.id })
          );

          const createBuildingUsage = (item: BuildingUsageable) => ({
            uid: uuid(),
            building_usage_id: item.id,
            building_usage: item
          });

          // Create or find items
          const items = _.map(buildingUsages, (bu) => findBuildingUsage(bu) || createBuildingUsage(bu));

          // Save selected building usages
          props.onSave(items);

          // Close the modal
          onClose();
        }}
        renderItem={(buildingUsage) => (
          <Item.Group>
            <Item
              header={buildingUsage.name}
              meta={buildingUsage.building_type_name}
            />
          </Item.Group>
        )}
        selectedItems={_.map(props.items, (item) => item.building_usage)}
        title={props.t('BuildingUsagesList.title')}
      />
    )}
  />
));

export default BuildingUsagesList;
