// @flow

import { CopyrightRestrictions, type Translateable } from '@archnet/shared';
import { ListFilters, ViewPDFButton } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Header, Label } from 'semantic-ui-react';
import _ from 'underscore';
import { ActionTypes, getActions } from '../actions/Actions';
import AdminItemList from '../components/AdminItemList';
import DonationFilters from '../filters/DonationFilters';
import DonationsService from '../services/Donations';
import DonorFilters from '../filters/DonorFilters';
import DonorLabel from '../components/DonorLabel';
import Donors from '../services/Donors';
import PrimaryImageView from '../components/PrimaryImageView';
import WorkStatuses from '../resources/donations/WorkStatuses.json';
import './Donations.css';

const Donations: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminItemList
    className='donations'
    collectionName='donations'
    editActions={[{
      attributeName: 'copyright_restriction',
      key: 'copyright_restriction',
      options: CopyrightRestrictions,
      text: props.t('Donations.actions.copyright'),
      type: ActionTypes.select,
      value: 'copyright_restriction'
    }, {
      attributeName: 'date',
      key: 'date',
      text: props.t('Donations.actions.date'),
      type: ActionTypes.date,
      value: 'date'
    }, {
      attributeName: 'negotiated_by',
      key: 'negotiated_by',
      text: props.t('Donations.actions.negotiatedBy'),
      type: ActionTypes.string,
      value: 'negotiated_by'
    }, {
      attributeName: 'permission_type',
      key: 'permission_type',
      text: props.t('Donations.actions.permissionType'),
      type: ActionTypes.string,
      value: 'permission_type'
    }, {
      attributeName: 'work_status',
      key: 'work_status',
      options: WorkStatuses,
      text: props.t('Donations.actions.workStatus'),
      type: ActionTypes.select,
      value: 'work_status'
    }, {
      attributeName: 'work_status_date',
      key: 'work_status_date',
      text: props.t('Donations.actions.workStatusDate'),
      type: ActionTypes.date,
      value: 'work_status_date'
    }, {
      collectionName: 'donors',
      filters: DonorFilters,
      key: 'donors',
      multiple: true,
      onLoad: (params: any) => Donors.fetchAll({ ...params, per_page: 10 }),
      parentType: 'Donor',
      renderOption: (donor) => donor.name,
      text: props.t('Donations.actions.donors'),
      type: ActionTypes.relationship,
      value: 'donors'
    }, ...getActions({
      exclude: ['locked', 'published', 'date_published']
    })]}
    filters={{
      component: ListFilters,
      props: {
        filters: DonationFilters
      }
    }}
    onBatchUpdate={(ids, params) => DonationsService.batchUpdate(ids, params)}
    onDelete={(donation) => DonationsService.delete(donation)}
    onLoad={(params) => DonationsService.search(params)}
    renderExtra={(item) => (
      <Label.Group>
        {_.map(item.donor_record_associations, (ra) => <DonorLabel donor={ra.parent} />)}
      </Label.Group>
    )}
    renderHeader={(item) => <Header content={item.name} />}
    renderImage={(item) => (
      <PrimaryImageView item={item}>
        { item.primary_image?.child?.content_type === 'application/pdf' && (
          <ViewPDFButton
            primary
            url={`/admin/display?source=${item.primary_image?.child?.content_url}`}
          />
        )}
      </PrimaryImageView>
    )}
    renderMeta={(item) => item.record_id}
    route='donations'
    sort={[{
      key: 'name',
      value: 'name',
      text: props.t('Common.sort.name')
    }, {
      key: 'record_id',
      value: 'record_id',
      text: props.t('Common.sort.recordId')
    }, {
      key: 'created_at',
      value: 'created_at',
      text: props.t('Common.sort.createdAt')
    }, {
      key: 'updated_at',
      value: 'updated_at',
      text: props.t('Common.sort.updatedAt')
    }]}
  />
));

export default Donations;
