// @flow

import type { Keyword as KeywordType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type Node } from 'react';
import { Form } from 'semantic-ui-react';

type Props = EditContainerProps & Translateable & {
  item: KeywordType
};

const KeywordForm = (props: Props): Node => (
  <>
    <Form.Input
      error={props.isError('name')}
      label={props.t('Keyword.labels.name')}
      onChange={props.onTextInputChange.bind(this, 'name')}
      required={props.isRequired('name')}
      value={props.item.name || ''}
    />
  </>
);

export default KeywordForm;
