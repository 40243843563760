// @flow

import type { BuildingUsage as BuildingUsageType, Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import BuildingTypes from '../services/BuildingTypes';
import BuildingType from '../transforms/BuildingType';
import './BuildingUsageModal.css';

type Props = Translateable & {
  ...EditContainerProps,
  item: BuildingUsageType
};

const BuildingUsageModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='building-usage-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.t('BuildingUsageModal.title.new')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        label={props.t('BuildingUsageModal.labels.buildingUsageName')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
      <Form.Input
        label={props.t('BuildingTypeModal.labels.buildingTypeName')}
      >
        <AssociatedDropdown
          collectionName='building_types'
          onSearch={(search) => BuildingTypes.fetchAll({ per_page: 0, search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'building_type_id', 'building_type')}
          renderOption={(bt) => BuildingType.toDropdown(bt)}
          searchQuery={props.item.building_type && props.item.building_type.name}
          value={props.item.building_type_id || ''}
          modal={{
            component: withTranslation()(AddBuildingTypeModal),
            onSave: (buildingType) => BuildingTypes.save(buildingType).then(({ data }) => data.building_type)
          }}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
));

const AddBuildingTypeModal = (props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='building-type-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.t('BuildingTypeModal.title.new')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        label={props.t('BuildingTypeModal.labels.buildingTypeName')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default BuildingUsageModal;
