// @flow

import type { Translateable } from '@archnet/shared';
import { AssociatedDropdown, DatePicker } from '@performant-software/semantic-components';
import React, {
  useCallback,
  useEffect,
  useState,
  type AbstractComponent,
  type Element
} from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Confirm,
  Dropdown,
  Header,
  Icon,
  Input,
  Modal
} from 'semantic-ui-react';
import _ from 'underscore';
import { ActionTypes } from '../actions/Actions';
import BatchUpdateSelectize, { Operators } from './BatchUpdateSelectize';
import Date from '../utils/Date';
import type { Filter } from '../types/Filter';
import RichTextArea from './RichTextArea';
import './BatchUpdateModal.css';

type Option = {
  key: string,
  text: string,
  value: any
};

type UpdateAction = {
  collectionName?: string,
  filters?: Array<Filter>,
  onLoad?: (params: any) => Promise<any>,
  options?: Array<Option>,
  renderHeader?: (props: any) => Element<any>,
  renderOption?: (item: any) => Element<any>,
  renderSelected: (items: Array<any>) => Element<any>,
  key: string,
  value: string,
  text: string,
  type: string,
  multiple?: boolean
};

type Props = Translateable & {
  action: UpdateAction,
  onClose: () => void,
  onSave: (params: any) => Promise<any>,
  recordCount: number,
  saving?: boolean
};

const BatchUpdateModal: AbstractComponent<any> = withTranslation()((props: Props) => {
  const [confirm, setConfirm] = useState(false);
  const [operator, setOperator] = useState(Operators.add);
  const [state, setState] = useState({});
  const [value, setValue] = useState<any>(null);

  const { action } = props;

  const renderInput = useCallback(() => {
    // Render a checkbox
    if (action.type === ActionTypes.boolean) {
      return (
        <Checkbox
          checked={value}
          onChange={() => setValue((prevValue) => !prevValue)}
        />
      );
    }

    // Render a dropdown element
    if (action.type === ActionTypes.select) {
      return (
        <Dropdown
          multiple={action.multiple}
          onChange={(e, data) => setValue(data.value)}
          options={action.options}
          search
          selection
          selectOnBlur={false}
          value={value}
        />
      );
    }

    // Render a text input element
    if (action.type === ActionTypes.string) {
      return (
        <Input
          fluid
          onChange={(e, data) => setValue(data.value)}
          value={value}
        />
      );
    }

    // Render rich text area element
    if (action.type === ActionTypes.text) {
      return (
        <RichTextArea
          onChange={(v) => setValue(v)}
          value={value}
        />
      );
    }

    // Render the date picker
    if (action.type === ActionTypes.date) {
      return (
        <DatePicker
          onChange={(date) => setValue(date.toString())}
          value={Date.withoutTime(value)}
        />
      );
    }

    // Render the batch update selectize for multiple relationship types
    if (action.type === ActionTypes.relationship && action.multiple) {
      return (
        <BatchUpdateSelectize
          collectionName={action.collectionName}
          filters={action.filters}
          onLoad={action.onLoad && action.onLoad.bind(this)}
          onOperatorChange={(o) => setOperator(o)}
          onSelection={(items) => setValue(_.pluck(items, 'id'))}
          operator={operator}
          renderHeader={action.renderHeader}
          renderOption={action.renderOption}
          renderSelected={action.renderSelected}
          title={action.text}
          setState={(attributes) => setState((prevState) => ({ ...prevState, ...attributes }))}
          state={state}
        />
      );
    }

    // Render the associated dropdown for relationship types
    if (action.type === ActionTypes.relationship) {
      return (
        <AssociatedDropdown
          collectionName={action.collectionName}
          onSearch={(search) => action.onLoad && action.onLoad({ search })}
          onSelection={(item) => setValue(item.id)}
          renderOption={action.renderOption}
          value={value || ''}
        />
      );
    }

    return null;
  }, [value, state, props.action]);

  /**
   * For boolean values, default the value to "false".
   */
  useEffect(() => {
    if (action.type === ActionTypes.boolean) {
      setValue(false);
    }
  }, []);

  return (
    <Modal
      centered={false}
      className='batch-update'
      open
    >
      <Confirm
        cancelButton={props.t('Common.buttons.no')}
        centered={false}
        confirmButton={props.t('Common.buttons.yes')}
        content={props.t('BatchUpdateModal.confirmation.content', { count: props.recordCount })}
        header={(
          <Header
            as='h2'
          >
            <Icon
              name='warning sign'
            />
            <Header.Content>
              { props.t('BatchUpdateModal.confirmation.header') }
            </Header.Content>
          </Header>
        )}
        onCancel={() => setConfirm(false)}
        onConfirm={() => {
          setConfirm(false);
          props.onSave({
            ...state,
            operator,
            value
          });
        }}
        open={confirm}
      />
      <Modal.Header>
        <Header
          content={props.t('BatchUpdateModal.title', { name: props.action.text })}
          subheader={props.t('BatchUpdateModal.subtitle', { count: props.recordCount })}
        />
      </Modal.Header>
      <Modal.Content>
        { renderInput() }
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={props.t('Common.buttons.update')}
          disabled={props.saving}
          loading={props.saving}
          onClick={() => setConfirm(true)}
          primary
        />
        <Button
          content={props.t('Common.buttons.cancel')}
          inverted
          primary
          onClick={props.onClose.bind(this)}
        />
      </Modal.Actions>
    </Modal>
  );
});

export default BatchUpdateModal;
