// @flow

import type {
  Authority as AuthorityType,
  AuthorityRole as AuthorityRoleType,
  BuildingUsage as BuildingUsageType,
  Collection as CollectionType,
  Country as CountryType,
  EventType as EventTypeType,
  Keyword as KeywordType,
  Material as MaterialType,
  StylePeriod as StylePeriodType,
  BuildingType as BuildingTypeType
} from '@archnet/shared';
import { FilterTypes } from '@performant-software/semantic-components';
import i18n from '../i18n/I18n';
import Authorities from '../services/Authorities';
import Authority from '../transforms/Authority';
import AuthorityRoles from '../services/AuthorityRoles';
import AuthorityRole from '../transforms/AuthorityRole';
import BuildingUsage from '../transforms/BuildingUsage';
import BuildingUsages from '../services/BuildingUsages';
import BuildingType from '../transforms/BuildingType';
import BuildingTypes from '../services/BuildingTypes';
import Collection from '../transforms/Collection';
import Collections from '../services/Collections';
import Countries from '../services/Countries';
import Country from '../transforms/Country';
import EventType from '../transforms/EventType';
import EventTypes from '../services/EventTypes';
import type { Filter } from '../types/Filter';
import Filters from './Filters';
import Keyword from '../transforms/Keyword';
import Keywords from '../services/Keywords';
import Material from '../transforms/Material';
import Materials from '../services/Materials';
import StylePeriod from '../transforms/StylePeriod';
import StylePeriods from '../services/StylePeriods';

const SiteFilters: Array<Filter> = [{
  associationColumn: 'site_id',
  associationName: 'site_names',
  attributeName: 'site_names.name',
  key: 'site_names.name',
  label: i18n.t('Sites.filters.name'),
  type: FilterTypes.string
}, {
  attributeName: 'description',
  key: 'description',
  label: i18n.t('Sites.filters.description'),
  type: FilterTypes.text
}, {
  associationColumn: 'country_id',
  associationName: 'country',
  attributeName: 'id',
  collectionName: 'countries',
  key: 'country',
  label: i18n.t('Sites.filters.country'),
  objectName: 'country',
  onSearch: (search: string) => Countries.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (country) => Country.toDropdown(country),
  renderSearchQuery: (country: CountryType) => country.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Authority',
  associationColumn: 'child_id',
  associationName: 'site_record_associations',
  attributeName: 'authorities.id',
  collectionName: 'authorities',
  key: 'authority',
  label: i18n.t('Sites.filters.authority'),
  objectName: 'authority',
  onSearch: (search: string) => Authorities.fetchAll({ search, sort_by: ['first_name', 'last_name'] }),
  renderOption: (authority) => Authority.toDropdown(authority),
  renderSearchQuery: (authority: AuthorityType) => authority.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Collection',
  associationColumn: 'child_id',
  associationName: 'site_record_associations',
  attributeName: 'collections.id',
  collectionName: 'collections',
  key: 'collection',
  label: i18n.t('Sites.filters.collection'),
  objectName: 'collection',
  onSearch: (search: string) => Collections.fetchAll({ search, sort_by: 'name' }),
  renderOption: (collection) => Collection.toDropdown(collection),
  renderSearchQuery: (collection: CollectionType) => collection.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'site_id',
  associationName: 'sites_style_periods',
  attributeName: 'sites_style_periods.style_period_id',
  collectionName: 'style_periods',
  key: 'sites_style_periods.style_period_id',
  label: i18n.t('Sites.filters.stylePeriod'),
  objectName: 'style_period',
  onSearch: (search: string) => StylePeriods.fetchAll({ search, sort_by: 'name' }),
  renderOption: (stylePeriod) => StylePeriod.toDropdown(stylePeriod),
  renderSearchQuery: (stylePeriod: StylePeriodType) => stylePeriod.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'site_id',
  associationName: 'materials_sites',
  attributeName: 'materials_sites.material_id',
  collectionName: 'materials',
  key: 'materials_sites.material_id',
  label: i18n.t('Sites.filters.material'),
  objectName: 'material',
  onSearch: (search: string) => Materials.fetchAll({ search, sort_by: 'name' }),
  renderOption: (material) => Material.toDropdown(material),
  renderSearchQuery: (material: MaterialType) => material.name,
  type: FilterTypes.relationship
}, {
  attributeName: 'dimensions',
  key: 'dimensions',
  label: i18n.t('Sites.filters.dimensions'),
  type: FilterTypes.string
}, {
  associationColumn: 'site_id',
  associationName: 'building_usages_sites',
  attributeName: 'building_usages_sites.building_usage_id',
  collectionName: 'building_usages',
  key: 'building_usages_sites.building_usage_id',
  label: i18n.t('Sites.filters.buildingUsage'),
  objectName: 'building_usage',
  onSearch: (search: string) => BuildingUsages.fetchAll({ search, sort_by: 'name' }),
  renderOption: (buildingUsage) => BuildingUsage.toDropdown(buildingUsage),
  renderSearchQuery: (buildingUsage: BuildingUsageType) => buildingUsage.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'site_id',
  associationName: 'building_types_sites',
  attributeName: 'building_usages_sites.building_usage.building_type_id',
  collectionName: 'building_types',
  key: 'building_type_sites.building_type_id',
  label: i18n.t('Sites.filters.buildingType'),
  objectName: 'building_types',
  onSearch: (search: string) => BuildingTypes.fetchAll({ search, sort_by: 'name' }),
  renderOption: (buildingType) => BuildingType.toDropdown(buildingType),
  renderSearchQuery: (buildingType: BuildingTypeType) => buildingType.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'site_id',
  associationName: 'keywords_sites',
  attributeName: 'keywords_sites.keyword_id',
  collectionName: 'keywords',
  key: 'keywords_sites.keyword_id',
  label: i18n.t('Sites.filters.keyword'),
  objectName: 'keyword',
  onSearch: (search: string) => Keywords.fetchAll({ search, sort_by: 'name' }),
  renderOption: (keyword) => Keyword.toDropdown(keyword),
  renderSearchQuery: (keyword: KeywordType) => keyword.name,
  type: FilterTypes.relationship
}, {
  attributeName: 'place_name',
  key: 'place_name',
  label: i18n.t('Sites.filters.placeName'),
  type: FilterTypes.string
}, {
  baseClass: 'Authority',
  associationColumn: 'child_id',
  associationName: 'site_record_associations',
  attributeName: 'record_associations.data',
  collectionName: 'authority_roles',
  key: 'authority_roles',
  label: i18n.t('Common.filters.authorityRole'),
  objectName: 'authority_role',
  onSearch: (search: string) => AuthorityRoles.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (authorityRole) => AuthorityRole.toDropdown(authorityRole),
  renderSearchQuery: (authorityRole: AuthorityRoleType) => authorityRole.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'site_id',
  associationName: 'event_items',
  attributeName: 'event_items.event_type_id',
  collectionName: 'event_types',
  key: 'event_types.title',
  label: i18n.t('Common.filters.eventType'),
  objectName: 'event_type.title',
  onSearch: (search: string) => EventTypes.fetchAll({ per_page: 0, search, sort_by: 'title' }),
  renderOption: (eventType) => EventType.toDropdown(eventType),
  renderSearchQuery: (eventType: EventTypeType) => eventType.title,
  type: FilterTypes.relationship
}, {
  associationColumn: 'site_id',
  associationName: 'event_items',
  attributeName: 'event_items.description',
  key: 'event_items.description',
  label: i18n.t('Common.filters.eventDescription'),
  type: FilterTypes.text
}, {
  associationColumn: 'site_id',
  associationName: 'event_items',
  attributeName: 'event_items.start_year',
  key: 'event_items.start_year',
  label: i18n.t('Common.filters.eventStart'),
  type: FilterTypes.integer
}, {
  associationColumn: 'site_id',
  associationName: 'event_items',
  attributeName: 'event_items.end_year',
  key: 'event_items.end_year',
  label: i18n.t('Common.filters.eventEnd'),
  type: FilterTypes.integer
}, ...Filters.getFilters()];

export default SiteFilters;
