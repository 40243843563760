// @flow

import type { User as UserType } from '@archnet/shared';
import { FilterTypes } from '@performant-software/semantic-components';
import _ from 'underscore';
import type { Filter } from '../types/Filter';
import i18n from '../i18n/I18n';
import User from '../transforms/User';
import Users from '../services/Users';

const Filters: Array<Filter> = [{
  attributeName: 'record_id',
  key: 'record_id',
  label: i18n.t('Common.filters.recordId'),
  type: FilterTypes.string
}, {
  attributeName: 'published',
  key: 'published',
  label: i18n.t('Common.filters.published'),
  type: FilterTypes.boolean,
  value: false
}, {
  attributeName: 'date_published',
  key: 'date_published',
  label: i18n.t('Common.filters.datePublished'),
  type: FilterTypes.date
}, {
  attributeName: 'locked',
  key: 'locked',
  label: i18n.t('Common.filters.locked'),
  type: FilterTypes.boolean
}, {
  attributeName: 'hcp_item',
  key: 'hcp_item',
  label: i18n.t('Common.filters.hcpItem'),
  type: FilterTypes.boolean
}, {
  attributeName: 'award_winner',
  key: 'award_winner',
  label: i18n.t('Common.filters.awardWinner'),
  type: FilterTypes.boolean
}, {
  attributeName: 'flag_for_review',
  key: 'flag_for_review',
  label: i18n.t('Common.filters.flagForReview'),
  type: FilterTypes.boolean
}, {
  associationColumn: 'created_by_id',
  associationName: 'create_user',
  attributeName: 'id',
  collectionName: 'users',
  key: 'created_by',
  label: i18n.t('Common.filters.createdBy'),
  objectName: 'user',
  onSearch: (search: string) => Users.fetchAll({ search, sort_by: ['firstname', 'lastname'] }),
  renderOption: (user) => User.toDropdown(user),
  renderSearchQuery: (user: UserType) => user.full_name,
  type: FilterTypes.relationship
}, {
  attributeName: 'created_at',
  key: 'created_at',
  label: i18n.t('Common.filters.createdAt'),
  type: FilterTypes.date
}, {
  associationColumn: 'updated_by_id',
  associationName: 'update_user',
  attributeName: 'id',
  collectionName: 'users',
  key: 'updated_by',
  label: i18n.t('Common.filters.updatedBy'),
  objectName: 'user',
  onSearch: (search: string) => Users.fetchAll({ search, sort_by: ['firstname', 'lastname'] }),
  renderOption: (user) => User.toDropdown(user),
  renderSearchQuery: (user: UserType) => user.full_name,
  type: FilterTypes.relationship
}, {
  attributeName: 'updated_at',
  key: 'updated_at',
  label: i18n.t('Common.filters.updatedAt'),
  type: FilterTypes.date
}, {
  associationColumn: 'parent_id',
  associationName: 'admin_notes',
  attributeName: 'admin_notes.note',
  key: 'admin_notes.note',
  label: i18n.t('Common.filters.notes'),
  type: FilterTypes.text
}];

/**
 * Returns the list of filters, optionally only the ones included in the passed list of keys.
 *
 * @param keys
 *
 * @returns {[{attributeName: string, label: string, type: string, key: string}, {attributeName: string, label: string,
 * type: string, key: string}, {attributeName: string, label: string, type: string, key: string}, {attributeName:
 * string, label: string, type: string, key: string}, {attributeName: string, label: string, type: string, key: string},
 * null, null, null, null, null]|*}
 */
type GetFiltersType = (keys: any) => Array<Filter>;

const getFilters: GetFiltersType = (...keys: Array<string>) => {
  if (_.isEmpty(keys)) {
    return Filters;
  }

  return _.filter(Filters, (filter) => _.contains(keys, filter.key));
};

/**
 * Returns the list of filters, optionally excluding the ones specified in the passed list of keys.
 *
 * @param keys
 *
 * @returns {[{attributeName: string, label: string, type: string, key: string}, {attributeName: string, label: string,
 * type: string, key: string}, {attributeName: string, label: string, type: string, key: string}, {attributeName:
 * string, label: string, type: string, key: string}, {attributeName: string, label: string, type: string, key: string},
 * null, null, null, null, null]|*}
 */
type ExcludeFiltersType = (...keys: Array<string>) => Array<Filter>;

const excludeFilters: ExcludeFiltersType = (...keys: Array<string>) => {
  if (_.isEmpty(keys)) {
    return Filters;
  }

  return _.filter(Filters, (filter) => !_.contains(keys, filter.key));
};

export default {
  excludeFilters,
  getFilters
};
