// @flow

import BaseService from './BaseService';
import StylePeriod from '../transforms/StylePeriod';

/**
 * Service class for all sites_style_period based API calls.
 */
class StylePeriods extends BaseService {
  /**
   * Returns the style_periods base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/style_periods';
  }

  /**
   * Returns the sites_style_periods transform.
   *
   * @returns {StylePeriod}
   */
  getTransform(): typeof StylePeriod {
    return StylePeriod;
  }
}

export default (new StylePeriods(): StylePeriods);
