// @flow

import i18n from '../i18n/I18n';
import LanguagesService from '../services/Languages';
import { ActionTypes } from './Actions';

import type { Language } from '@archnet/shared';

const Languages = {
  associationColumn: 'language_id',
  collectionName: 'languages',
  key: 'languages',
  multiple: true,
  onLoad: (params: any) => LanguagesService.fetchAll(params),
  renderOption: (language: Language) => language.name,
  text: i18n.t('Common.actions.languages'),
  type: ActionTypes.relationship,
  value: 'languages'
};

export default Languages;
