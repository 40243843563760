// @flow

import type { Authority, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import './AgentModal.css';

type Props = Translateable & {
  ...EditContainerProps,
  item: Authority
};

const AgentRoleModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='agent-role-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('AgentRoleModal.title.edit')
        : props.t('AgentRoleModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        error={props.isError('agentRole')}
        label={props.t('AgentRoleModal.labels.agentRole')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('agent')}
        value={props.item.name || ''}
        width={10}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default AgentRoleModal;
