// @flow

import type { Authority as AuthorityType, Translateable } from '@archnet/shared';
import { BooleanIcon, EmbeddedList } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Header, Icon, Segment } from 'semantic-ui-react';
import _ from 'underscore';
import i18n from '../i18n/I18n';
import { AuthorityUtils } from '@archnet/shared';
import AgentModal, { NameTypes } from '../components/AgentModal';
import AssociatedAuthorities from '../components/AssociatedAuthorities';
import AssociatedCollections from '../components/AssociatedCollections';
import AssociatedMedia from '../components/AssociatedMedia';
import AssociatedPublications from '../components/AssociatedPublications';
import AssociatedSites from '../components/AssociatedSites';
import Authorities from '../services/Authorities';
import AuthoritySourceModal from '../components/AuthoritySourceModal';
import PlainText from '../components/PlainText';
import PrimaryImage from '../components/PrimaryImage';
import QuillEditor from '../components/QuillEditor';
import {
  onMultiAddChildren,
  onMultiAddParents,
  onMultiAddRelated
} from '../utils/RecordAssociation';
import EmptyTableRow from '../components/EmptyTableRow';
import RecordAssociations from '../services/RecordAssociations';
import RecordHeader from '../components/RecordHeader';
import SimpleEditPage from './SimpleEditPage';
import './Authority.css';

type Props = Translateable & {
  ...EditContainerProps,
  item: AuthorityType
};

const ERROR_MULTIPLE_PRIMARY_NAMES = 'cannot have multiple primary names';
const ERROR_NO_PRIMARY_NAME = 'must have at least one primary name';

const Tabs = {
  details: 'details',
  media: 'media',
  publications: 'publications',
  sites: 'sites',
  collections: 'collections',
  relatedAuthorities: 'relatedAuthorities'
};

const Authority: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
    className='authority'
  >
    <SimpleEditPage.Header>
      <RecordHeader
        {...props}
        header={AuthorityUtils.formatPrimaryName(props.item)}
        item={props.item}
        meta={props.item.record_id}
        renderImage={() => (
          <PrimaryImage
            item={props.item}
          />
        )}
      />
    </SimpleEditPage.Header>
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <Segment>
        <Header
          content={props.t('Authority.title.names')}
        />
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'copy'
          }, {
            name: 'delete'
          }]}
          columns={[{
            className: 'drag-drop-icon',
            name: 'move',
            label: '',
            render: () => <Icon name='bars' />,
            sortable: false
          }, {
            name: 'primary',
            label: props.t('Authority.labels.primary'),
            render: (an) => <BooleanIcon value={an.primary} />
          }, {
            name: 'qualify',
            label: props.t('Authority.labels.qualify'),
            resolve: (an) => an.name_type && an.name_type.name
          }, {
            name: 'first_name',
            label: props.t('Authority.labels.firstName')
          }, {
            name: 'last_name',
            label: props.t('Authority.labels.lastName')
          }, {
            name: 'country',
            label: props.t('Authority.labels.country'),
            resolve: (an) => an.country && an.country.name
          }, {
            name: 'year_started',
            label: props.t('Authority.labels.yearStart'),
            hidden: true
          }, {
            name: 'year_ended',
            label: props.t('Authority.labels.yearEnd'),
            hidden: true
          }, {
            name: 'year_description',
            label: props.t('Authority.labels.yearDescription'),
            hidden: true
          }]}
          items={props.item.authority_names}
          modal={{
            component: AgentModal,
            props: {
              defaults: {
                nameType: NameTypes.individual
              }
            }
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authority_names')}
          onDrag={(dragIndex, hoverIndex) => {
            const items = [...props.item.authority_names];
            const item = items[dragIndex];

            items.splice(dragIndex, 1);
            items.splice(hoverIndex, 0, item);

            props.onSetState({ authority_names: items });
          }}
          onSave={props.onSaveChildAssociation.bind(this, 'authority_names')}
          renderEmptyRow={() => <EmptyTableRow />}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Authority.title.sources')}
        />
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'copy'
          }, {
            name: 'delete'
          }]}
          columns={[{
            className: 'drag-drop-icon',
            name: 'move',
            label: '',
            render: () => <Icon name='bars' />,
            sortable: false
          }, {
            name: 'id',
            label: props.t('Authority.labels.sourceId'),
            resolve: (source) => source.id
          }, {
            name: 'text',
            label: props.t('Authority.labels.sourceText'),
            render: (source) => <PlainText string={source.text} />
          }]}
          items={props.item.authority_sources}
          modal={{
            component: AuthoritySourceModal,
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authority_sources')}
          onDrag={(dragIndex, hoverIndex) => {
            const items = [...props.item.authority_sources];
            const item = items[dragIndex];

            items.splice(dragIndex, 1);
            items.splice(hoverIndex, 0, item);

            props.onSetState({ authority_sources: items });
          }}
          onSave={props.onSaveChildAssociation.bind(this, 'authority_sources')}
          renderEmptyRow={() => <EmptyTableRow />}
        />
      </Segment>
      <Segment>
        <Header
          content={props.t('Authority.labels.biography')}
        />
        <QuillEditor
          onChange={(value) => props.onTextInputChange('biography', null, { value })}
          value={props.item.biography || ''}
        />
      </Segment>
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.media}
      name={props.t('Common.tabs.media')}
      count={props.item.associated_child_media_count}
    >
      <AssociatedMedia
        items={props.item.media_record_associations}
        modal={{
          props: {
            tabs: ['sites', 'collections', 'donations']
          }
        }}
        onDataLoaded={(items) => props.onUpdateState({
          media_record_associations: [
            ...props.item.media_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'media_record_associations')}
        onEdit={(item, media) => props.onSaveChildAssociation('media_record_associations', {
          ...item,
          child: media
        })}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Authority',
          child_type: 'MediaContent'
        })}
        onSave={(media) => {
          _.each(media, (m) => {
            props.onSaveChildAssociation('media_record_associations', {
              uid: uuid(),
              child_id: m.id,
              child_type: 'MediaContent',
              child: m
            });
          });
        }}
        onSaveMultiple={onMultiAddChildren.bind(this, props, 'media_record_associations', 'MediaContent')}
        onSelectPrimary={(item) => props.onSetState({
          media_record_associations: _.map(
            props.item.media_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ media_record_associations: items })}
        resolveMedia={(item) => item.child}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.publications}
      name={props.t('Common.tabs.publications')}
      count={props.item.associated_child_publications_count}
    >
      <AssociatedPublications
        items={props.item.publication_record_associations}
        modal={{
          props: {
            tabs: ['sites', 'collections', 'donations']
          }
        }}
        onDataLoaded={(items) => props.onUpdateState({
          publication_record_associations: [
            ...props.item.publication_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'publication_record_associations')}
        onEdit={(item, publication) => props.onSaveChildAssociation('publication_record_associations', {
          ...item,
          child: publication
        })}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Authority',
          child_type: 'Publication'
        })}
        onSave={(publications) => {
          _.each(publications, (p) => {
            props.onSaveChildAssociation('publication_record_associations', {
              uid: uuid(),
              child_id: p.id,
              child_type: 'Publication',
              child: p
            });
          });
        }}
        onSaveMultiple={onMultiAddChildren.bind(this, props, 'publication_record_associations', 'Publication')}
        onSelectPrimary={(item) => props.onSetState({
          publication_record_associations: _.map(
            props.item.publication_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ publication_record_associations: items })}
        resolvePublication={(item) => item.child}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.sites}
      name={props.t('Common.tabs.sites')}
      count={props.item.associated_child_sites_count}
    >
      <AssociatedSites
        addButton={props.t('Authority.buttons.addSites')}
        items={props.item.site_record_associations}
        onDataLoaded={(items) => props.onUpdateState({
          site_record_associations: [
            ...props.item.site_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'site_record_associations')}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Authority',
          child_type: 'Site'
        })}
        onSave={onMultiAddChildren.bind(this, props, 'site_record_associations', 'Site')}
        onUpdate={(items) => props.onSetState({ site_record_associations: items })}
        resolveSite={(item) => item.child}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.collections}
      name={props.t('Common.tabs.collections')}
      count={props.item.associated_parent_collections_count}
    >
      <AssociatedCollections
        items={props.item.collection_record_associations}
        onDataLoaded={(items) => props.onUpdateState({
          collection_record_associations: [
            ...props.item.collection_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'collection_record_associations')}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          child_id: props.item.id,
          child_type: 'Authority',
          parent_type: 'Collection'
        })}
        onSave={onMultiAddParents.bind(this, props, 'collection_record_associations', 'Collection')}
        onUpdate={(items) => props.onSetState({ collection_record_associations: items })}
        resolveCollection={(item) => item.parent}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.relatedAuthorities}
      name={props.t('Authority.tabs.relatedAuthorities')}
      count={props.item.related_child_authorities_count}
    >
      <AssociatedAuthorities
        addButton={props.t('Authority.buttons.addRelated')}
        items={props.item.related_authorities}
        onDelete={props.onDeleteChildAssociation.bind(this, 'related_authorities')}
        onSave={onMultiAddRelated.bind(this, props, 'related_authorities', 'child_authority_id')}
        onUpdate={(items) => props.onSetState({ related_authorities: items })}
        resolveAuthority={(item) => item.child}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: Authority,
  onInitialize: (id: number): Promise<any> => (
    Authorities
      .fetchOne(id)
      .then(({ data }) => data.authority)
  ),
  onSave: (authority: AuthorityType): Promise<any> => (
    Authorities
      .save(authority)
      .then(({ data }) => data.authority)
  ),
  resolveValidationError: ({ error }: { error: string }) => {
    const errors = {};

    // Authorities cannot have more than one primary name
    if (error.includes(ERROR_MULTIPLE_PRIMARY_NAMES)) {
      errors.authority = i18n.t('Authority.errors.multiplePrimaryNames', { error });
    } else if (error.includes(ERROR_NO_PRIMARY_NAME)) {
      errors.authority = i18n.t('Authority.errors.noPrimaryName', { error });
    }

    return errors;
  }
};
