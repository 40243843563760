// @flow

import type { Translateable } from '@archnet/shared';
import { BooleanIcon } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import CustomPagesService from '../services/CustomPages';

const CustomPages: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='custom-pages'
    collectionName='custom_pages'
    columns={[{
      name: 'title',
      label: props.t('CustomPages.columns.title'),
      sortable: true
    }, {
      name: 'url',
      label: props.t('CustomPages.columns.url'),
      sortable: true
    }, {
      name: 'order',
      label: props.t('CustomPages.columns.order'),
      sortable: true
    }, {
      name: 'published',
      label: props.t('CustomPages.columns.published'),
      render: (cp) => <BooleanIcon value={cp.published} />,
      sortable: true
    }, {
      name: 'footer',
      label: props.t('CustomPages.columns.footer'),
      render: (cp) => <BooleanIcon value={cp.footer} />,
      sortable: true
    }]}
    onDelete={(cp) => CustomPagesService.delete(cp)}
    onLoad={(params) => CustomPagesService.fetchAll(params)}
    route='custom_pages'
  />
));

export default CustomPages;
