// @flow

import type { Option } from '../types/Option';
import BaseTransform from './BaseTransform';
import _ from 'underscore';

import type { User as UserType } from '@archnet/shared';

/**
 * Class for handling transforming user records.
 */
class User extends BaseTransform {
  /**
   * Returns the user payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'firstname',
      'lastname',
      'email',
      'login',
      'role',
      'password',
      'password_confirmation'
    ];
  }

  /**
   * Returns the passed user as a dropdown option.
   *
   * @param user
   *
   * @returns {{text: string, value: *, key: *}}
   */
  toDropdown(user: UserType): Option {
    return {
      key: user.id,
      value: user.id,
      text: user.full_name
    };
  }

  /**
   * Returns the user object to be sent to the server on POST/PUT requests.
   *
   * @param user
   *
   * @returns {{user: (*)}}
   */
  toPayload(user: UserType): any {
    return {
      user: _.pick(user, this.getPayloadKeys())
    };
  }
}

export default (new User(): User);
