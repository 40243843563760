// @flow

import type { Translateable, User as UserType } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Message } from 'semantic-ui-react';
import i18n from '../i18n/I18n';
import Roles from '../resources/users/Roles.json';
import SimpleEditPage from './SimpleEditPage';
import Users from '../services/Users';

type Props = EditContainerProps & Translateable & {
  item: UserType
};

const ERROR_PASSWORD_LENGTH = 'is too short';
const ERROR_PASSWORD_MATCH = 'doesn\'t match';

const Tabs = {
  details: 'details'
};

const User: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
    className='user'
  >
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <Form.Input
        error={props.isError('firstname')}
        label={props.t('User.labels.firstName')}
        required={props.isRequired('firstname')}
        onChange={props.onTextInputChange.bind(this, 'firstname')}
        value={props.item.firstname || ''}
      />
      <Form.Input
        error={props.isError('lastname')}
        label={props.t('User.labels.lastName')}
        required={props.isRequired('lastname')}
        onChange={props.onTextInputChange.bind(this, 'lastname')}
        value={props.item.lastname || ''}
      />
      <Form.Input
        error={props.isError('email')}
        label={props.t('User.labels.email')}
        required={props.isRequired('email')}
        onChange={props.onTextInputChange.bind(this, 'email')}
        value={props.item.email || ''}
      />
      <Form.Input
        error={props.isError('login')}
        label={props.t('User.labels.login')}
        required={props.isRequired('login')}
        onChange={props.onTextInputChange.bind(this, 'login')}
        value={props.item.login || ''}
      />
      <Form.Dropdown
        error={props.isError('role')}
        label={props.t('User.labels.role')}
        onChange={props.onTextInputChange.bind(this, 'role')}
        options={Roles}
        required={props.isRequired('role')}
        selection
        value={props.item.role || ''}
      />
      <Message
        content={props.t('User.labels.passwordPolicy.content')}
        header={props.t('User.labels.passwordPolicy.header')}
      />
      <Form.Input
        error={props.isError('password')}
        label={props.t('User.labels.password')}
        required={props.isRequired('password')}
        onChange={props.onTextInputChange.bind(this, 'password')}
        type='password'
        value={props.item.password || ''}
      />
      <Form.Input
        error={props.isError('password_confirmation')}
        label={props.t('User.labels.passwordConfirmation')}
        required={props.isRequired('password_confirmation')}
        onChange={props.onTextInputChange.bind(this, 'password_confirmation')}
        type='password'
        value={props.item.password_confirmation || ''}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: User,
  onInitialize: (id: number): Promise<any> => (
    Users
      .fetchOne(id)
      .then(({ data }) => data.user)
  ),
  onSave: (user: UserType): Promise<any> => (
    Users
      .save(user)
      .then(({ data }) => data.user)
  ),
  resolveValidationError: ({ error }: { error: string }): any => {
    const errors: any = {};

    // Passwords must be a certain length
    if (error.includes(ERROR_PASSWORD_LENGTH)) {
      errors.password = i18n.t('User.errors.password', { error });
    }

    // Confirmation must match password
    if (error.includes(ERROR_PASSWORD_MATCH)) {
      errors.password_confirmation = i18n.t('User.errors.passwordConfirmation', { error });
    }

    return errors;
  }
};
