// @flow

import type { CollectionType as CollectionTypeType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Button, Card, Popup } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedMedia from '../components/AssociatedMedia';
import CollectionTypeForm from '../components/CollectionTypeForm';
import CollectionTypes from '../services/CollectionTypes';
import { onMultiAddChildren } from '../utils/RecordAssociation';
import PrimaryImage from '../components/PrimaryImage';
import RecordAssociations from '../services/RecordAssociations';
import SimpleEditPage from './SimpleEditPage';

type Props = EditContainerProps & Translateable & {
  item: CollectionTypeType
};

const POPUP_DELAY = 1000;

const Tabs = {
  details: 'details',
  media: 'media'
};

const CollectionType: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
    className='collection-type'
  >
    <SimpleEditPage.Header>
      <Card.Content>
        <PrimaryImage
          item={props.item}
        />
        <Card.Header
          content={props.item.name}
        />
      </Card.Content>
      <Card.Content
        extra
      >
        <Popup
          content={props.t('RecordHeader.popups.publish.content')}
          header={props.t('RecordHeader.popups.publish.header')}
          hideOnScroll
          mouseEnterDelay={POPUP_DELAY}
          position='top right'
          trigger={(
            <Button
              basic
              color={props.item.show ? 'green' : undefined}
              icon='send'
              onClick={props.onCheckboxInputChange.bind(this, 'show')}
            />
          )}
        />
        <Popup
          content={props.t('CollectionType.popups.resource.content')}
          header={props.t('CollectionType.popups.resource.header')}
          hideOnScroll
          mouseEnterDelay={POPUP_DELAY}
          position='top right'
          trigger={(
            <Button
              basic
              color={props.item.is_resource ? 'blue' : undefined}
              icon='cogs'
              onClick={props.onCheckboxInputChange.bind(this, 'is_resource')}
            />
          )}
        />
      </Card.Content>
    </SimpleEditPage.Header>
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <CollectionTypeForm
        {...props}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.media}
      name={props.t('Common.tabs.media')}
      count={props.item.associated_child_media_count}
    >
      <AssociatedMedia
        items={props.item.media_record_associations}
        onDataLoaded={(items) => props.onUpdateState({
          media_record_associations: [
            ...props.item.media_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'media_record_associations')}
        onEdit={(item, media) => props.onSaveChildAssociation('media_record_associations', {
          ...item,
          child: media
        })}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'CollectionType',
          child_type: 'MediaContent'
        })}
        onSave={(media) => {
          _.each(media, (m) => {
            props.onSaveChildAssociation('media_record_associations', {
              uid: uuid(),
              child_id: m.id,
              child_type: 'MediaContent',
              child: m
            });
          });
        }}
        onSaveMultiple={onMultiAddChildren.bind(this, props, 'media_record_associations', 'MediaContent')}
        onSelectPrimary={(item) => props.onSetState({
          media_record_associations: _.map(
            props.item.media_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ media_record_associations: items })}
        resolveMedia={(item) => item.child}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: CollectionType,
  onInitialize: (id: number): Promise<any> => (
    CollectionTypes
      .fetchOne(id)
      .then(({ data }) => data.collection_type)
  ),
  onSave: (collectionType: CollectionTypeType): Promise<any> => (
    CollectionTypes
      .save(collectionType)
      .then(({ data }) => data.collection_type)
  )
};
