// @flow

import _ from 'underscore';

import type { AuthorityRole as AuthorityRoleType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming authority role records.
 */
class AuthorityRole {
  PAYLOAD_KEYS: Array<string> = [
    'name'
  ];

  /**
   * Returns the passed authority role as a dropdown option.
   *
   * @param authorityRole
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(authorityRole: AuthorityRoleType): Option {
    return {
      key: authorityRole.id,
      value: authorityRole.id,
      text: authorityRole.name
    };
  }

  /**
   * Returns the authority role object to be sent to the server on POST/PUT requests.
   *
   * @param authorityRole
   *
   * @returns {{authority_role: *}}
   */
  toPayload(authorityRole: AuthorityRoleType): any {
    return {
      authority_role: _.pick(authorityRole, this.PAYLOAD_KEYS)
    };
  }
}

export default (new AuthorityRole(): AuthorityRole);
