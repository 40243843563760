// @flow

import { ListFilters, Selectize } from '@performant-software/semantic-components';
import React, { useState, type Element, type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header, Item } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedRecordsList from './AssociatedRecordsList';
import CollectionFilters from '../filters/CollectionFilters';
import CollectionModal from './CollectionModal';
import Collections from '../services/Collections';
import PrimaryImageView from './PrimaryImageView';
import PublishedLabel from './PublishedLabel';
import SelectizeHeader from './SelectizeHeader';

import type { Collection, RecordAssociation, Translateable } from '@archnet/shared';

type Props = Translateable & {
  addButton?: string,
  buttons?: Array<any>,
  items: Array<RecordAssociation>,
  onDataLoaded?: (items: Array<RecordAssociation>) => void,
  onDelete: (item: RecordAssociation) => void,
  onLoad: (params: any) => Promise<any>,
  onSave: (item: RecordAssociation) => void,
  onUpdate?: (items: Array<RecordAssociation>) => void,
  renderExtra: (item: RecordAssociation) => void,
  renderHeader: (item: RecordAssociation) => Element<any>,
  renderImage: (item: RecordAssociation) => Element<any>,
  renderMeta: (item: RecordAssociation) => Element<any>,
  resolveCollection: (item: RecordAssociation) => Collection
};

const AssociatedCollections = (props: Props) => {
  const [modal, setModal] = useState(false);

  /**
   * Renders the passed collection for the Selectize component.
   *
   * @param collection
   *
   * @returns {*}
   */
  const renderCollection = (collection: Collection) => (
    <Item.Group>
      <Item>
        <Item.Image
          style={{
            width: 'unset'
          }}
        >
          <PrimaryImageView
            dimmable={false}
            item={collection}
            size='tiny'
          />
        </Item.Image>
        <Item.Content>
          <Item.Header
            content={collection.name}
          />
          <Item.Meta
            content={collection.record_id}
          />
          <Item.Extra>
            <PublishedLabel
              icon
              published={collection.published}
            />
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
  );

  /**
   * Renders the extra information for the passed item.
   *
   * @param item
   *
   * @returns {JSX.Element|*}
   */
  const renderExtra = (item: RecordAssociation) => {
    if (props.renderExtra) {
      return props.renderExtra(item);
    }

    const collection = resolveCollection(item);

    return (
      <PublishedLabel
        published={collection.published}
      />
    );
  };

  /**
   * Renders the header for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderHeader = (item: RecordAssociation) => {
    if (props.renderHeader) {
      return props.renderHeader(item);
    }

    const collection = resolveCollection(item);

    return (
      <Link
        to={`/admin/collections/${collection.id}`}
      >
        <Header
          as='h3'
          content={collection.name}
        />
      </Link>
    );
  };

  /**
   * Renders the image for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderImage = (item: RecordAssociation) => {
    if (props.renderImage) {
      return props.renderImage(item);
    }

    const collection = resolveCollection(item);

    return (
      <PrimaryImageView
        item={collection}
      />
    );
  };

  /**
   * Renders the metadata for the passed item.
   *
   * @param item
   *
   * @returns {string|*}
   */
  const renderMeta = (item: RecordAssociation) => {
    if (props.renderMeta) {
      return props.renderMeta(item);
    }

    const collection = resolveCollection(item);
    return collection.record_id;
  };

  /**
   * Returns the related collection for the passed collection record association.
   *
   * @param item
   *
   * @returns {*|{}}
   */
  const resolveCollection = (item: RecordAssociation) => (
    (props.resolveCollection && props.resolveCollection(item)) || {}
  );

  return (
    <>
      <AssociatedRecordsList
        actions={[{
          basic: true,
          color: 'red',
          icon: 'trash',
          label: props.t('AssociatedCollections.buttons.delete'),
          onClick: props.onDelete.bind(this)
        }]}
        buttons={[{
          color: 'green',
          content: props.addButton || props.t('AssociatedCollections.buttons.add'),
          icon: 'plus',
          onClick: () => setModal(true)
        },
        ...(props.buttons || [])
        ]}
        items={props.items}
        onDataLoaded={props.onDataLoaded}
        onLoad={props.onLoad}
        onUpdate={props.onUpdate}
        renderExtra={renderExtra.bind(this)}
        renderHeader={renderHeader.bind(this)}
        renderImage={renderImage.bind(this)}
        renderMeta={renderMeta.bind(this)}
      />
      { modal && (
        <Selectize
          collectionName='collections'
          filters={{
            component: ListFilters,
            props: {
              filters: CollectionFilters
            }
          }}
          modal={{
            component: CollectionModal,
            onSave: (collection) => Collections.save(collection).then(({ data }) => data.collection)
          }}
          onClose={() => setModal(false)}
          onLoad={(params) => Collections.search({ ...params, sort_by: 'name', per_page: 5 })}
          onSave={(collections) => {
            props.onSave(collections);
            setModal(false);
          }}
          renderHeader={(headerProps) => (
            <SelectizeHeader
              {...headerProps}
              type='Collection'
            />
          )}
          renderItem={renderCollection.bind(this)}
          selectedItems={_.map(props.items, resolveCollection)}
          title={props.t('AssociatedCollections.selectize.title')}
          width='60%'
        />
      )}
    </>
  );
};

AssociatedCollections.defaultProps = {
  buttons: []
};

const AssociatedCollectionsComponent: AbstractComponent<any> = withTranslation()(AssociatedCollections);
export default AssociatedCollectionsComponent;
