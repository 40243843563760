// @flow

import type { Translateable } from '@archnet/shared';
import React, { type AbstractComponent, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Form, Grid, GridColumn, GridRow, Modal, Button, ModalActions, Checkbox, Radio, Divider, Select, Input
} from 'semantic-ui-react';
import './MergeKeywordsModal.css';

export type MergeRequest = {
  keywords: Array<number>,
  collections: Array<string>,
  old_or_new: 'old' | 'new',
  value: number
}
type Props = Translateable & {
  open: Boolean,
  keywords: Array<any>,
  processing: boolean,
  onClose(): void,
  onMerge(merge: MergeRequest): void
};

const MergeKeywordsModal: AbstractComponent<any> = withTranslation()((props: Props) => {
  const [selectedCollections, setSelectionCollections] = useState({
    sites: false,
    mediaRecords: false,
    publications: false
  });

  const [useCurrentValue, setUseCurrentValue] = useState(true);
  const [curValue, setCurValue] = useState<string>('');
  const [newValue, setNewValue] = useState<string>('');

  const items = useMemo(() => {
    const countSites = props.keywords.reduce((acc, val) => acc + val.keywords_sites_count, 0);
    const countPubs = props.keywords.reduce((acc, val) => acc + val.keywords_publications_count, 0);
    const countMedia = props.keywords.reduce((acc, val) => acc + val.keywords_media_contents_count, 0);

    const arr = [];
    if (countSites) {
      arr.push({
        collection: props.t('MergeKeywordsModal.collections.sites'),
        key: 'sites',
        count: countSites
      });
    }
    if (countPubs) {
      arr.push({
        collection: props.t('MergeKeywordsModal.collections.publications'),
        key: 'publications',
        count: countPubs
      });
    }
    if (countMedia) {
      arr.push({
        collection: props.t('MergeKeywordsModal.collections.mediaRecords'),
        key: 'mediaRecords',
        count: countMedia
      });
    }

    return arr;
  }, [props.keywords]);

  const handleCollectionToggle = (collection: string, checked: boolean) => {
    const ret = { ...selectedCollections };
    ret[collection] = checked;

    setSelectionCollections(ret);
  };

  const handleToggleTarget = () => {
    setUseCurrentValue(!useCurrentValue);
  };

  const handleMerge = () => {
    const selected = [];
    if (selectedCollections.sites) {
      selected.push('sites');
    }
    if (selectedCollections.publications) {
      selected.push('publications');
    }
    if (selectedCollections.mediaRecords) {
      selected.push('mediaRecords');
    }
    props.onMerge({
      ids: props.keywords.map((k) => k.id),
      collections: selected,
      old_or_new: useCurrentValue ? 'old' : 'new',
      value: useCurrentValue ? curValue : newValue
    });
  };

  const options = useMemo(() => props.keywords.map((k) => ({ value: k.id, text: k.name })), [props.keywords]);

  const enabled = (selectedCollections.sites || selectedCollections.mediaRecords || selectedCollections.publications)
    && ((useCurrentValue && !!curValue) || (!useCurrentValue && !!newValue));
  return (
    <Modal
      as={Form}
      centered={false}
      noValidate
      open={props.open}
      size='large'
    >
      <Modal.Header
        content={props.t('MergeKeywordsModal.title.mergeKeywords')}
      />
      <Modal.Content>
        <Grid columns={3} divided>
          <GridRow>
            <GridColumn>
              <h4>{props.t('MergeKeywordsModal.title.mergeTheseKeywords')}</h4>
              {props.keywords.map((keyword) => <li key={keyword.name}>{keyword.name}</li>)}
            </GridColumn>
            <GridColumn>
              <h4>{props.t('MergeKeywordsModal.title.onTheseRecordTypes')}</h4>
              <div>
                {items.map((i) => (
                  <div className='ui checkbox' key={i.key}>
                    <Checkbox
                      type='checkbox'
                      name={i.collection}
                      disabled={i.count === 0}
                      onChange={(e, data) => handleCollectionToggle(i.key, data.checked)}
                      label={`${i.collection}: ${i.count} ${props.t('MergeKeywordsModal.collections.records')}`}
                    />
                  </div>
                ))}
              </div>
            </GridColumn>
            <GridColumn>
              <h4>{props.t('MergeKeywordsModal.title.toThisValue')}</h4>
              <div className='merge-modal-radio-group'>
                <Radio
                  label={props.t('MergeKeywordsModal.target.mergeCurrentValue')}
                  name='radioGroup'
                  value='current'
                  checked={useCurrentValue}
                  onChange={handleToggleTarget}
                  className='merge-modal-radio'
                />
                <Select
                  value={useCurrentValue ? curValue : ''}
                  placeholder={props.t('MergeKeywordsModal.target.selectAKeyword')}
                  options={options}
                  onChange={(e, data) => {
                    setCurValue(data.value);
                  }}
                  disabled={!useCurrentValue}
                />
              </div>
              <Divider />
              <div className='merge-modal-radio-group'>
                <Radio
                  label={props.t('MergeKeywordsModal.target.mergeNewValue')}
                  name='radioGroup'
                  value='new'
                  checked={!useCurrentValue}
                  onChange={handleToggleTarget}
                  className='merge-modal-radio'
                />
                <Input
                  value={newValue}
                  placeholder={props.t('MergeKeywordsModal.target.enterNewKeyword')}
                  onChange={(e, data) => setNewValue(data.value)}
                />
              </div>
            </GridColumn>
          </GridRow>
        </Grid>
      </Modal.Content>
      <ModalActions>
        <Button color='black' onClick={props.onClose} content={props.t('MergeKeywordsModal.buttons.cancel')} />
        <Button
          type='button'
          content={props.t('MergeKeywordsModal.buttons.merge')}
          labelPosition='right'
          icon='fork'
          onClick={handleMerge}
          positive
          disabled={!enabled}
          loading={props.processing}
        />
      </ModalActions>
    </Modal>
  );
});

export default MergeKeywordsModal;
