// @flow

import type { Translateable } from '@archnet/shared';
import { ListFilters } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import { ActionTypes, getActions } from '../actions/Actions';
import AdminItemList from '../components/AdminItemList';
import DonationFilters from '../filters/DonationFilters';
import Donations from '../services/Donations';
import DonorFilters from '../filters/DonorFilters';
import DonorsService from '../services/Donors';
import PrimaryImageView from '../components/PrimaryImageView';
import './Donors.css';

const Donors: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminItemList
    className='donors'
    collectionName='donors'
    editActions={[{
      collectionName: 'donations',
      filters: DonationFilters,
      key: 'donations',
      multiple: true,
      onLoad: (params: any) => Donations.fetchAll({ ...params, per_page: 10 }),
      renderOption: (donation) => donation.name,
      text: props.t('Donors.actions.donations'),
      type: ActionTypes.relationship,
      value: 'donations'
    }, ...getActions({
      exclude: ['published', 'date_published', 'locked']
    })]}
    filters={{
      component: ListFilters,
      props: {
        filters: DonorFilters
      }
    }}
    onBatchDelete={(ids) => DonorsService.batchDelete(ids)}
    onBatchUpdate={(ids, params) => DonorsService.batchUpdate(ids, params)}
    onDelete={(donation) => DonorsService.delete(donation)}
    onLoad={(params) => DonorsService.search(params)}
    renderHeader={(item) => <Header content={item.name} />}
    renderImage={(item) => <PrimaryImageView item={item} />}
    renderMeta={(item) => item.record_id}
    route='donors'
    sort={[{
      key: 'name',
      value: 'name',
      text: props.t('Common.sort.name')
    }, {
      key: 'record_id',
      value: 'record_id',
      text: props.t('Common.sort.recordId')
    }, {
      key: 'created_at',
      value: 'created_at',
      text: props.t('Common.sort.createdAt')
    }, {
      key: 'updated_at',
      value: 'updated_at',
      text: props.t('Common.sort.updatedAt')
    }]}
  />
));

export default Donors;
