// @flow

import type { Translateable } from '@archnet/shared';
import { ListFilters } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Header, Icon, Label } from 'semantic-ui-react';
import { SiteUtils } from '@archnet/shared';
import { ActionTypes, getActions } from '../actions/Actions';
import AdminItemList from '../components/AdminItemList';
import Authorities from '../actions/Authorities';
import BuildingUsages from '../actions/BuildingUsages';
import Collections from '../actions/Collections';
import Countries from '../services/Countries';
import Country from '../transforms/Country';
import Keywords from '../actions/Keywords';
import Materials from '../actions/Materials';
import PrimaryImageView from '../components/PrimaryImageView';
import Publications from '../actions/Publications';
import PublishedLabel from '../components/PublishedLabel';
import SiteFilters from '../filters/SiteFilters';
import SitesService from '../services/Sites';
import StylePeriods from '../actions/StylePeriods';
import './Sites.css';

const Sites: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminItemList
    className='sites'
    collectionName='sites'
    editActions={[{
      attributeName: 'dimensions',
      key: 'dimensions',
      text: props.t('Common.actions.dimensions'),
      type: ActionTypes.string,
      value: 'dimensions'
    }, {
      attributeName: 'country_id',
      collectionName: 'countries',
      key: 'country',
      onLoad: (params) => Countries.fetchAll({ ...params, per_page: 0 }),
      renderOption: (country) => Country.toDropdown(country),
      text: props.t('Common.actions.country'),
      type: ActionTypes.relationship,
      value: 'country'
    }, {
      ...Keywords,
      associationName: 'keywords_sites'
    }, {
      ...Materials,
      associationName: 'materials_sites'
    }, {
      ...StylePeriods,
      associationName: 'sites_style_periods'
    }, {
      ...BuildingUsages,
      associationName: 'building_usages_sites'
    }, {
      ...Authorities,
      parentType: 'Authority'
    }, {
      ...Collections,
      parentType: 'Collection'
    }, {
      ...Publications,
      childType: 'Publication'
    }, ...getActions()]}
    filters={{
      component: ListFilters,
      props: {
        filters: SiteFilters
      }
    }}
    onBatchDelete={(ids) => SitesService.batchDelete(ids)}
    onBatchUpdate={(ids, params) => SitesService.batchUpdate(ids, params)}
    onDelete={(site) => SitesService.delete(site)}
    onLoad={(params) => SitesService.search(params)}
    renderDescription={(item) => item.record_id}
    renderExtra={(item) => (
      <Label.Group>
        <PublishedLabel
          icon
          published={item.published}
        />
        { item.hcp_item && (
          <Label>
            <Icon
              name='hcp'
            />
            { props.t('Common.labels.hcpItem') }
          </Label>
        )}
        { item.award_winner && (
          <Label>
            <Icon
              name='award'
            />
            { props.t('Common.labels.awardWinner') }
          </Label>
        )}
      </Label.Group>
    )}
    renderHeader={(item) => <Header content={item.name} />}
    renderImage={(item) => <PrimaryImageView item={item} />}
    renderMeta={(item) => SiteUtils.formatLocation(item)}
    route='sites'
    sort={[{
      key: 'name',
      value: 'site_names.name',
      text: props.t('Common.sort.name')
    }, {
      key: 'record_id',
      value: 'record_id',
      text: props.t('Common.sort.recordId')
    }, {
      key: 'published',
      value: 'published',
      text: props.t('Common.sort.published')
    }, {
      key: 'created_at',
      value: 'created_at',
      text: props.t('Common.sort.createdAt')
    }, {
      key: 'updated_at',
      value: 'updated_at',
      text: props.t('Common.sort.updatedAt')
    }, {
      key: 'date_published',
      value: 'date_published',
      text: props.t('Common.sort.datePublished')
    }]}
  />
));

export default Sites;
