// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming nested related site records.
 */
class RelatedSites extends NestedAttributes {
  PARAM_NAME: string = 'related_sites';

  /**
   * Returns the related sites payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'child_site_id',
      '_destroy'
    ];
  }

  /**
   * Overrides the appendFormData function and defaults the collection name.
   *
   * @param formData
   * @param prefix
   * @param record
   * @param collection
   */
  appendFormData(formData: FormData, prefix: string, record: *, collection: string = this.PARAM_NAME) {
    super.appendFormData(formData, prefix, record, collection);
  }

  /**
   * Overrides the toPayload function and defaults the collection name.
   *
   * @param record
   * @param collection
   *
   * @returns {{}}
   */
  toPayload(record: *, collection: string = this.PARAM_NAME): any {
    return super.toPayload(record, collection);
  }
}

export default (new RelatedSites(): RelatedSites);
