// @flow

import type { Translateable } from '@archnet/shared';
import { LoginModal, Toaster } from '@performant-software/semantic-components';
import { AxiosResponse } from 'axios';
import React, {
  useCallback,
  useEffect,
  useState,
  type AbstractComponent
} from 'react';
import { withTranslation } from 'react-i18next';
import { Location, RouterHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Message,
  Segment
} from 'semantic-ui-react';
import { Banner, Logo } from '@archnet/shared';
import Authentication from '../services/Authentication';
import Session from '../services/Session';
import './Login.css';

type Props = Translateable & {
  history: typeof RouterHistory,
  location: typeof Location
};

const Login: AbstractComponent<any> = withTranslation()((props: Props) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loginModal, setLoginModal] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);

  /**
   * Called on login success.
   *
   * @type {(function(*): void)|*}
   */
  const onLogin = useCallback((response: typeof AxiosResponse) => {
    // Create the session
    Session.create(response);

    // Navigate to the index URL or provided state URL
    if (redirectUrl) {
      props.history.push(redirectUrl);
    } else {
      props.history.push('/admin');
    }
  }, [redirectUrl, props.history]);

  /**
   * Set the redirect URL and expiration indicator from the router state.
   */
  useEffect(() => {
    const { state } = props.location;

    if (state) {
      setRedirectUrl(state.url);
      setSessionExpired(state.expired);
      props.history.replace({ state: {} });
    }
  }, []);

  /**
   * Sets the loginError and destroys the session on failed login.
   *
   * @type {(function(*): void)|*}
   */
  const onLoginError = useCallback(() => {
    setLoginFailed(true);
    Session.destroy();
  }, []);

  return (
    <>
      <Segment.Group
        className='login'
      >
        <Segment
          className='logo-container'
          inverted
          textAlign='center'
        >
          <Logo />
          <Header>
            <Banner
              content={process.env.REACT_APP_ENV_NAME}
            />
          </Header>
        </Segment>
        <Segment
          inverted
          padded='very'
          placeholder
        >
          <Grid
            columns={2}
            stackable
            textAlign='center'
          >
            <Divider
              inverted
              vertical
            >
              { props.t('Login.labels.or') }
            </Divider>
            <Grid.Row
              verticalAlign='middle'
            >
              <Grid.Column>
                <Header
                  icon
                  inverted
                >
                  <Icon
                    name='database'
                  />
                  { props.t('Login.labels.administrator') }
                </Header>
                <Button
                  as='a'
                  content={props.t('Login.buttons.login')}
                  onClick={() => setLoginModal(true)}
                  primary
                />
              </Grid.Column>
              <Grid.Column>
                <Header
                  icon
                  inverted
                >
                  <Icon
                    name='world'
                  />
                  { props.t('Login.labels.explore') }
                </Header>
                <Button
                  as='a'
                  content={props.t('Login.buttons.go')}
                  href={process.env.REACT_APP_WEB_URL}
                  primary
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
      <LoginModal
        disabled={!(username && password)}
        loginFailed={loginFailed}
        onClose={() => setLoginModal(false)}
        onLogin={() => (
          Authentication
            .login(username, password)
            .then(onLogin)
            .catch(onLoginError)
        )}
        onPasswordChange={(event) => setPassword(event.target.value)}
        onUsernameChange={(event) => setUsername(event.target.value)}
        open={loginModal}
        placeholder={props.t('LoginModal.labels.login')}
      />
      { sessionExpired && (
        <Toaster
          onDismiss={() => setSessionExpired(false)}
          timeout={0}
          type={Toaster.MessageTypes.negative}
        >
          <Message.Header
            content={props.t('LoginModal.messages.sessionExpiration.header')}
          />
          <Message.Content
            content={props.t('LoginModal.messages.sessionExpiration.content')}
          />
        </Toaster>
      )}
    </>
  );
});

export default Login;
