// @flow

import type { Translateable } from '@archnet/shared';
import React, { useState, type AbstractComponent, type Element } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Icon,
  Label,
  Segment
} from 'semantic-ui-react';
import _ from 'underscore';
import './SimpleTagsList.css';

type Props = Translateable & {
  items: Array<any>,
  onDelete: (item: any) => void,
  renderDetail: (item: any) => string,
  renderIcon: (item: any) => Element<any>,
  renderItem: (item: any) => Element<any>,
  renderModal?: ({ onClose: () => void }) => Element<any>
};

const SimpleTagsList = (props: Props) => {
  const [modal, setModal] = useState(false);

  return (
    <div
      className='simple-tags-list'
    >
      <Button
        basic
        content={props.t('Common.buttons.add')}
        icon='plus'
        onClick={() => setModal(true)}
        type='button'
      />
      <Segment>
        { !_.isEmpty(props.items) && _.map(props.items, (item, index) => (
          <Label
            key={index}
          >
            { props.renderIcon && props.renderIcon(item) }
            { props.renderItem(item) }
            { props.renderDetail && (
              <Label.Detail>
                { props.renderDetail(item) }
              </Label.Detail>
            )}
            { props.onDelete && (
              <Icon
                name='delete'
                onClick={props.onDelete.bind(this, item)}
              />
            )}
          </Label>
        ))}
        { _.isEmpty(props.items) && (
          <div>{ props.t('SimpleTagsList.empty') }</div>
        )}
      </Segment>
      { modal && props.renderModal && props.renderModal({
        onClose: () => setModal(false)
      })}
    </div>
  );
};

SimpleTagsList.defaultProps = {
  renderModal: undefined
};

export default (withTranslation()(SimpleTagsList): AbstractComponent<any>);
