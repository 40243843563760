// @flow

import BaseService from './BaseService';
import Language from '../transforms/Language';

/**
 * Service class for all language based API calls.
 */
class Languages extends BaseService {
  /**
   * Returns the languages base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/languages';
  }

  /**
   * Returns the languages transform.
   *
   * @returns {Language}
   */
  getTransform(): typeof Language {
    return Language;
  }
}

export default (new Languages(): Languages);
