// @flow

import _ from 'underscore';

import type { EventType as EventTypeType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming nested event type records.
 */
class EventType {
  PAYLOAD_KEYS: Array<string> = [
    'id',
    'title',
    '_destroy'
  ];

  /**
   * Returns the passed event type as a dropdown option.
   *
   * @param eventType
   *
   * @returns {{text: string, value: string, key: number}}
   */
  toDropdown(eventType: EventTypeType): Option {
    return {
      key: eventType.id,
      value: eventType.id,
      text: eventType.title
    };
  }

  /**
 * Returns the event_type for the passed site to be sent to the server on POST/PUT requests.
 *
 * @param eventType
 *
 * @returns {{event_type: (*)}}
 */
  toPayload(eventType: EventType): any {
    return {
      event_type: _.pick(eventType, this.PAYLOAD_KEYS)
    };
  }
}

export default (new EventType(): EventType);
