// @flow

import type {Translateable} from '@archnet/shared';
import React, {type AbstractComponent} from 'react';
import {withTranslation} from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import StylePeriodsService from '../services/StylePeriods';
import AssociatedRecordCountCell from '../components/AssociatedRecordCountCell';

const StylePeriods: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='style-periods'
    collectionName='style_periods'
    searchable
    columns={[{
      name: 'name',
      label: props.t('StylePeriods.columns.name'),
      sortable: true
    }, {
      name: 'start_year',
      label: props.t('StylePeriods.columns.startYear'),
      sortable: true
    }, {
      name: 'end_year',
      label: props.t('StylePeriods.columns.endYear'),
      sortable: true
    }, {
      name: 'sites_style_periods_count',
      label: props.t('StylePeriods.columns.associatedSites'),
      sortable: true,
      render: (item) => (
        <AssociatedRecordCountCell
          item={item}
          name='sites_style_periods_count'
          recordType='site'
          recordTypeLabel={props.t('AssociatedRecordsButton.labels.sites')}
          associationName='sites_style_periods'
          filterType='style_period'
          filterTypeLabel={props.t('AssociatedRecordsButton.labels.stylePeriod')}
        />
      )
    }, {
      name: 'media_contents_style_periods_count',
      label: props.t('StylePeriods.columns.associatedMediaRecords'),
      sortable: true,
      render: (item) => (
        <AssociatedRecordCountCell
          item={item}
          name='media_contents_style_periods_count'
          recordType='media_content'
          recordTypeLabel={props.t('AssociatedRecordsButton.labels.mediaContent')}
          associationName='media_contents_style_periods'
          filterType='style_period'
          filterTypeLabel={props.t('AssociatedRecordsButton.labels.stylePeriod')}
        />
      )
    }]}
    onDelete={(stylePeriod) => StylePeriodsService.delete(stylePeriod)}
    onLoad={(params) => StylePeriodsService.fetchAll(params)}
    route='style_periods'
  />
));

export default StylePeriods;
