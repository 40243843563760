// @flow

import axios from 'axios';
import _ from 'underscore';
import BaseService from './BaseService';

const UpdateParameters = {
  associationColumn: 'association_column',
  associationName: 'association_name',
  attributeName: 'attribute_name',
  childType: 'child_type',
  multiple: 'multiple',
  operator: 'operator',
  parentType: 'parent_type',
  customAttributes: 'custom_attributes',
  type: 'type',
  value: 'value'
};

class BatchUpdateable extends BaseService {
  /**
   * Calls the POST /api/<resource>/delete_all API end point.
   *
   * @param ids
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  batchDelete(ids: Array<number>): Promise<any> {
    return axios.post(`${this.getBaseUrl()}/delete_all`, { ids });
  }

  /**
   * Calls the PATCH api/<resource> API end point.
   *
   * @param ids
   * @param params
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  batchUpdate(ids: Array<number>, params: any): Promise<any> {
    return axios.patch(this.getBaseUrl(), { ids, ...this.prepareParameters(params) });
  }

  /**
   * Returns the passed params object with keys converted to proper case.
   *
   * @param params
   *
   * @returns {{}}
   */
  prepareParameters(params: any): any {
    const newParams = {};

    _.each(_.keys(UpdateParameters), (key) => {
      newParams[UpdateParameters[key]] = params[key];
    });

    return newParams;
  }
}

export default BatchUpdateable;
