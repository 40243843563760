// @flow

import BaseService from './BaseService';
import MediaType from '../transforms/MediaType';

/**
 * Service class for all media_types based API calls.
 */
class MediaTypes extends BaseService {
  /**
   * Returns the media_types base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/media_types';
  }

  /**
   * Returns the media_types transform.
   *
   * @returns {MediaContent}
   */
  getTransform(): typeof MediaType {
    return MediaType;
  }
}

export default (new MediaTypes(): MediaTypes);
