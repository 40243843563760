// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './config/Api';
import './config/Geocoder';
import './i18n/I18n';

import '@archnet/shared/styles.css';
import '@performant-software/shared-components/build/main.css';
import '@performant-software/semantic-components/build/main.css';
import '@performant-software/semantic-components/build/semantic-ui.css';
import './index.css';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
