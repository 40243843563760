// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming nested record association records.
 */
class RecordAssociations extends NestedAttributes {
  /**
   * Returns the record associations payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'child_id',
      'child_type',
      'parent_id',
      'parent_type',
      'child_id',
      'child_type',
      'data',
      'primary',
      'parent_primary',
      '_destroy'
    ];
  }
}

export default (new RecordAssociations(): RecordAssociations);
