// @flow

import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import i18n from '../i18n/I18n';
import AdminItemList from '../components/AdminItemList';
import CollectionTypesService from '../services/CollectionTypes';
import PrimaryImageView from '../components/PrimaryImageView';
import PublishedLabel from '../components/PublishedLabel';

const CollectionTypes: AbstractComponent<any> = withTranslation()(() => (
  <AdminItemList
    className='collection-types'
    collectionName='collection_types'
    onDelete={(collectionType) => CollectionTypesService.delete(collectionType)}
    onLoad={(params) => CollectionTypesService.fetchAll(params)}
    renderHeader={(item) => <Header content={item.name} />}
    renderImage={(item) => <PrimaryImageView item={item} />}
    renderExtra={(item) => <PublishedLabel icon published={item.show} />}
    route='collection_types'
    sort={[{
      key: 'name',
      value: 'name',
      text: i18n.t('Common.sort.name')
    }, {
      key: 'order',
      value: 'order',
      text: i18n.t('Common.sort.order')
    }]}
  />
));

export default CollectionTypes;
