// @flow

import cx from 'classnames';
import React, {
  useEffect,
  useRef,
  useState,
  type Node
} from 'react';
import { Button, Icon } from 'semantic-ui-react';
import i18n from '../i18n/I18n';
import ModalPage from './ModalPage';
import styles from './RichText.module.css';

type Props = {
  content: string,
  maxHeight?: string,
  showButton?: boolean,
  size?: string
};

const RichText = (props: Props): Node => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [showMore, setShowMore] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (ref.current && props.maxHeight) {
      setShowMore(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }, []);

  return (
    <div
      className={cx('rich-text', styles.richText)}
    >
      <div
        className={showMore ? styles.gradient : undefined}
        dangerouslySetInnerHTML={{ __html: props.content }}
        ref={ref}
        style={{
          maxHeight: props.maxHeight,
          overflow: props.maxHeight ? 'hidden' : undefined
        }}
      />
      { props.showButton && showMore && (
        <div
          className={styles.linkContainer}
        >
          <Button
            basic
            onClick={() => setModal(true)}
          >
            { i18n.t('RichText.labels.readMore') }
            <Icon
              name='angle right'
            />
          </Button>
        </div>
      )}
      { modal && (
        <ModalPage
          className={cx('rich-text-modal', styles.richTextModal)}
          onClose={() => setModal(false)}
          open
          size={props.size}
        >
          <div
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </ModalPage>
      )}
    </div>
  );
};

RichText.defaultProps = {
  maxHeight: undefined,
  showButton: true,
  size: undefined
};

export default RichText;
