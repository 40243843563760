// @flow

import { type StylePeriod } from '@archnet/shared';
import { type Action } from '../types/Action';
import i18n from '../i18n/I18n';
import StylePeriodsService from '../services/StylePeriods';

const StylePeriods: Action = {
  associationColumn: 'style_period_id',
  collectionName: 'style_periods',
  onLoad: (params: any) => StylePeriodsService.fetchAll(params),
  renderOption: (stylePeriod: StylePeriod) => stylePeriod.name,
  key: 'stylePeriods',
  multiple: true,
  text: i18n.t('Common.actions.stylePeriods'),
  type: 'relationship',
  value: 'stylePeriods'
};

export default StylePeriods;
