// @flow

import type { Translateable } from '@archnet/shared';
import { ListFilters } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import i18n from '../i18n/I18n';
import { ActionTypes, getActions } from '../actions/Actions';
import AdminItemList from '../components/AdminItemList';
import Authorities from '../actions/Authorities';
import CollectionFilters from '../filters/CollectionFilters';
import CollectionTypes from '../services/CollectionTypes';
import CollectionsService from '../services/Collections';
import Keywords from '../actions/Keywords';
import PrimaryImageView from '../components/PrimaryImageView';
import PublishedLabel from '../components/PublishedLabel';
import Sites from '../actions/Sites';
import Templates from '../resources/collections/Templates.json';
import './Collections.css';

const Collections: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminItemList
    className='collections'
    collectionName='collections'
    editActions={[{
      associationColumn: 'collection_type_id',
      associationName: 'collection_descriptions',
      collectionName: 'collection_types',
      key: 'collection_types',
      multiple: true,
      onLoad: (params: any) => CollectionTypes.fetchAll(params),
      renderOption: (collectionType) => collectionType.name,
      text: i18n.t('Collections.actions.categories'),
      type: ActionTypes.relationship,
      value: 'collection_types'
    }, {
      attributeName: 'template',
      key: 'template',
      options: Templates,
      text: props.t('Collections.actions.template'),
      type: ActionTypes.select,
      value: 'template'
    }, {
      ...Keywords,
      associationName: 'collections_keywords'
    }, {
      ...Authorities,
      childType: 'Authority'
    }, {
      ...Sites,
      childType: 'Site'
    }, ...getActions({
      exclude: ['award_winner', 'hcp_item']
    })]}
    filters={{
      component: ListFilters,
      props: {
        filters: CollectionFilters
      }
    }}
    onBatchDelete={(ids) => CollectionsService.batchDelete(ids)}
    onBatchUpdate={(ids, params) => CollectionsService.batchUpdate(ids, params)}
    onDelete={(collection) => CollectionsService.delete(collection)}
    onLoad={(params) => CollectionsService.search(params)}
    renderExtra={(item) => <PublishedLabel icon published={item.published} />}
    renderHeader={(item) => <Header content={item.display_name || item.name} />}
    renderImage={(item) => <PrimaryImageView item={item} />}
    renderMeta={(item) => item.record_id}
    route='collections'
    sort={[{
      key: 'name',
      value: 'name',
      text: i18n.t('Common.sort.name')
    }, {
      key: 'record_id',
      value: 'record_id',
      text: i18n.t('Common.sort.recordId')
    }, {
      key: 'created_at',
      value: 'created_at',
      text: props.t('Common.sort.createdAt')
    }, {
      key: 'updated_at',
      value: 'updated_at',
      text: props.t('Common.sort.updatedAt')
    }, {
      key: 'date_published',
      value: 'date_published',
      text: props.t('Common.sort.datePublished')
    }]}
  />
));

export default Collections;
