// @flow

import type { MediaContent, Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Header,
  Modal,
  Segment
} from 'semantic-ui-react';
import MediaContentActions from './MediaContentActions';
import MediaTypes from '../services/MediaTypes';
import MediaType from '../transforms/MediaType';
import MediaTypeModal from './MediaTypeModal';
import RichTextArea from './RichTextArea';
import './MediaContentModal.css';

type Props = Translateable & {
  ...EditContainerProps,
  item: MediaContent
};

const URL_PARAM_VALUE = 'v';
const URL_PROTOCOL = 'http';

const MediaContentModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='media-content-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header>
      <Header>
        <Header.Content
          content={props.item.id
            ? props.t('MediaContentModal.headers.edit')
            : props.t('MediaContentModal.headers.add')}
        />
        <Button
          basic
          color={props.item.published ? 'green' : undefined}
          content={props.item.published ? 'Published' : 'Unpublished'}
          floated='right'
          icon='send'
          onClick={props.onCheckboxInputChange.bind(this, 'published')}
        />
      </Header>
    </Modal.Header>
    <Modal.Content>
      <div
        className='image-container'
      >
        <Segment
          compact
        >
          <MediaContentActions
            item={props.item}
            onChange={props.onSetState}
          />
        </Segment>
      </div>
      <Form.Input
        error={props.isError('name')}
        label={props.t('MediaContentModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('caption')}
        label={props.t('MediaContentModal.labels.caption')}
        required={props.isRequired('caption')}
      >
        <RichTextArea
          onChange={(value) => props.onTextInputChange('caption', null, { value })}
          value={props.item.caption || ''}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('media_type_id')}
        label={props.t('MediaContentModal.labels.type')}
        required={props.isRequired('media_type_id')}
      >
        <AssociatedDropdown
          collectionName='media_types'
          modal={{
            component: MediaTypeModal,
            props: {
              required: ['name', 'kind']
            },
            onSave: (mediaType) => MediaTypes.save(mediaType).then(({ data }) => data.media_type)
          }}
          onSearch={(search) => MediaTypes.fetchAll({ per_page: 0, search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'media_type_id', 'media_type')}
          renderOption={(mediaType) => MediaType.toDropdown(mediaType)}
          searchQuery={props.item.media_type && props.item.media_type.name}
          value={props.item.media_type_id}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('copyright')}
        label={props.t('MediaContentModal.labels.copyright')}
        onChange={props.onTextInputChange.bind(this, 'copyright')}
        required={props.isRequired('copyright')}
        value={props.item.copyright || ''}
      />
      <Form.Input
        error={props.isError('year')}
        label={props.t('MediaContentModal.labels.year')}
        onChange={props.onTextInputChange.bind(this, 'year')}
        required={props.isRequired('year')}
        value={props.item.year || ''}
      />
      <Form.Dropdown
        clearable
        error={props.isError('external_source')}
        label={props.t('MediaContent.labels.externalSource')}
        onChange={props.onTextInputChange.bind(this, 'external_source')}
        options={[{
          key: 'youtube',
          value: 'youtube',
          text: 'YouTube'
        }]}
        required={props.isRequired('external_source')}
        selectOnBlur={false}
        selection
        value={props.item.external_source || ''}
      />
      <Form.Input
        error={props.isError('external_id')}
        label={props.t('MediaContent.labels.externalId')}
        onChange={(e, { value }) => {
          let id = value;

          if (value && value.startsWith(URL_PROTOCOL)) {
            const url = new URL(value);
            const { pathname, searchParams } = url;

            if (searchParams.has(URL_PARAM_VALUE)) {
              id = searchParams.get(URL_PARAM_VALUE);
            } else if (pathname) {
              id = pathname.substring(1);
            }
          }

          props.onTextInputChange('external_id', e, { value: id });
        }}
        required={props.isRequired('external_id')}
        value={props.item.external_id || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default MediaContentModal;
