// @flow

import BaseService from './BaseService';
import BuildingUsage from '../transforms/BuildingUsage';

/**
 * Service class for all building_usage based API calls.
 */
class BuildingUsages extends BaseService {
  /**
   * Returns the building_usages base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/building_usages';
  }

  /**
   * Returns the building_usages transform.
   *
   * @returns {BuildingUsage}
   */
  getTransform(): typeof BuildingUsage {
    return BuildingUsage;
  }
}

export default (new BuildingUsages(): BuildingUsages);
