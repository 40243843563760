// @flow

import BaseService from './BaseService';
import CustomPage from '../transforms/CustomPage';

/**
 * Service class for all custom page based API calls.
 */
class CustomPages extends BaseService {
  /**
   * Returns the custom pages base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/custom_pages';
  }

  /**
   * Returns the custom page transform.
   *
   * @returns {CustomPage}
   */
  getTransform(): typeof CustomPage {
    return CustomPage;
  }
}

export default (new CustomPages(): CustomPages);
