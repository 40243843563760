// @flow

import { ItemUtils, type Authority as AuthorityType } from '@archnet/shared';
import React, { type Element } from 'react';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import { Item } from 'semantic-ui-react';
import _ from 'underscore';
import { type Action } from '../types/Action';
import AuthoritiesService from '../services/Authorities';
import AuthorityFilters from '../filters/AuthorityFilters';
import AuthorityRole from '../transforms/AuthorityRole';
import AuthorityRoles from '../services/AuthorityRoles';
import i18n from '../i18n/I18n';
import PrimaryImageView from '../components/PrimaryImageView';
import SelectizeHeader from '../components/SelectizeHeader';

const Authorities: Action = {
  collectionName: 'authorities',
  filters: AuthorityFilters,
  key: 'authorities',
  multiple: true,
  onLoad: (params: any): Promise<any> => AuthoritiesService.fetchAll({ ...params, per_page: 5 }),
  renderHeader: (props: any): Element<any> => (
    <SelectizeHeader
      {...props}
      type='Authority'
    />
  ),
  renderOption: (authority: AuthorityType): Element<any> => {
    const attributes = ItemUtils.getViewAttributes(authority, 'Authority');

    return (
      <Item.Group>
        <Item>
          <Item.Image
            style={{
              width: 'unset'
            }}
          >
            <PrimaryImageView
              dimmable={false}
              item={authority}
              size='tiny'
            />
          </Item.Image>
          <Item.Content>
            <Item.Header
              content={attributes.header}
            />
            <Item.Meta
              content={attributes.meta}
            />
          </Item.Content>
        </Item>
      </Item.Group>
    );
  },
  renderSelected: (items: Array<AuthorityType>, state: any, setState: (props: any) => void): Element<any> => (
    <SelectizeHeader
      collapsable={false}
      perPage={3}
      renderExtra={(item) => (
        <AssociatedDropdown
          collectionName='authority_roles'
          onSearch={(search) => AuthorityRoles.fetchAll({ per_page: 0, search, sort_by: 'name' })}
          onSelection={(role) => setState({
            roles: {
              ...(state.roles || {}),
              [item.id]: role
            },
            customAttributes: {
              roles: {
                ...(_.get(state, ['customAttributes', 'roles']) || {}),
                [item.id]: role.id
              }
            }
          })}
          renderOption={(authorityRole) => AuthorityRole.toDropdown(authorityRole)}
          searchQuery={state[item.id] && state[item.id].role && state[item.id].role.name}
          value={(state[item.id] && state[item.id].role && state[item.id].role.id) || ''}
        />
      )}
      selectedItems={items}
      type='Authority'
    />
  ),
  text: i18n.t('Common.actions.authorities'),
  type: 'relationship',
  value: 'authorities'
};

export default Authorities;
