// @flow

import React from 'react';
import ViewAssociatedRecordButton from './ViewAssociatedRecordsButton';

type AssociatedRecordCountCellProps = {
  item: any,
  name: string,
  recordType: string,
  recordTypeLabel: string,
  associationName: string,
  filterType: string,
  filterTypeLabel: string
};

const AssociatedRecordCountCell = (props: AssociatedRecordCountCellProps) => (
  <div
    style={
      {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        width: '50%'
      }
    }
  >
    <span
      style={
        {
          paddingRight: '18px'
        }
      }
    >
      {props.item[props.name]}
    </span>
    {props.item[props.name] > 0 && (
      <ViewAssociatedRecordButton
        recordType={props.recordType}
        recordTypeLabel={props.recordTypeLabel}
        filterID={props.item.id}
        filterName={props.item.name}
        associationName={props.associationName}
        filterType={props.filterType}
        filterTypeLabel={props.filterTypeLabel}
      />
    )}
  </div>
);

export default AssociatedRecordCountCell;
