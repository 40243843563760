// @flow

import type { EventItem, Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import EventType from '../transforms/EventType';
import EventTypes from '../services/EventTypes';
import './EventItemModal.css';

type Props = Translateable & {
  ...EditContainerProps,
  item: EventItem & {
    event_type: string
  },
};

const EventItemModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='event-item-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('EventItemModal.title.edit')
        : props.t('EventItemModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('event_type')}
        label={props.t('EventItemModal.labels.eventType')}
        required={props.isRequired('event_type')}
      >
        <AssociatedDropdown
          collectionName='event_types'
          className='event-type-dropdown'
          onSearch={(search) => EventTypes.fetchAll({ per_page: 0, search, sort_by: 'title' })}
          onSelection={props.onAssociationInputChange.bind(this, 'event_type_id', 'event_type')}
          renderOption={(et) => EventType.toDropdown(et)}
          searchQuery={props.item.event_type ? props.item.event_type.title : ''}
          value={props.item.event_type ? props.item.event_type.id : ''}
        />
      </Form.Input>
      <Form.Group widths='equal'>
        <Form.Input
          label={props.t('EventItemModal.labels.startYear')}
          onChange={props.onTextInputChange.bind(this, 'start_year')}
          value={props.item.start_year || ''}
        />
        <Form.Input
          label={props.t('EventItemModal.labels.endYear')}
          onChange={props.onTextInputChange.bind(this, 'end_year')}
          value={props.item.end_year || ''}
        />
      </Form.Group>
      <Form.TextArea
        label={props.t('EventItemModal.labels.description')}
        onChange={props.onTextInputChange.bind(this, 'description')}
        value={props.item.description || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default EventItemModal;
