// @flow

import React, { type Node } from 'react';
import { Table } from 'semantic-ui-react';

const EmptyTableRow = (): Node => (
  <Table.Row>
    <Table.Cell />
  </Table.Row>
);

export default EmptyTableRow;
