// @flow

import cx from 'classnames';
import React, {
  useEffect,
  useRef,
  useState,
  type Node
} from 'react';
import { Modal, Ref } from 'semantic-ui-react';
import _ from 'underscore';
import BackButton from './BackButton';
import styles from './ModalPage.module.css';

type Props = {
  children: Node,
  className?: string,
  contentRef?: {
    current: ?HTMLElement
  },
  onClose: () => void,
  open: boolean,
  size?: string
};

const CLASS_FULLSCREEN = 'fullscreen';
const SIZE_FULLSCREEN = 'fullscreen';
const OFFSET = 50;

const ModalPage = (props: Props): Node => {
  const headerRef = useRef();
  const [maxHeight, setMaxHeight] = useState<string>('');
  const [classNames, setClassNames] = useState<Array<string>>([]);

  useEffect(() => {
    /*
     * Sets the height of the modal header on the state. The height of the content will be calculated based on the
     * header height.
     */
    const container = document.querySelector('.ui.page.modals.dimmer');

    if (container && props.size === SIZE_FULLSCREEN) {
      container.classList.add(CLASS_FULLSCREEN);
    }

    if (headerRef && headerRef.current) {
      const { clientHeight } = headerRef.current;
      setMaxHeight(`calc(100vh - ${clientHeight + OFFSET}px)`);
    }

    /*
     * Sets the class names to set on the root Modal component.
     */
    const names = [
      props.className,
      styles.modalPage,
      styles.ui,
      styles.modal
    ];

    if (props.size === SIZE_FULLSCREEN) {
      names.push(styles.fullscreen);
    }

    setClassNames(_.compact(names));
  }, [props.className, props.open, props.size]);

  return (
    <Modal
      centered={false}
      className={cx(classNames)}
      open={props.open}
      size={props.size}
    >
      <Ref
        innerRef={headerRef}
      >
        <Modal.Header>
          <BackButton
            onClick={props.onClose}
          />
        </Modal.Header>
      </Ref>
      <Ref
        innerRef={props.contentRef}
      >
        <Modal.Content
          className={styles.content}
          scrolling
          style={{
            maxHeight
          }}
        >
          { props.children }
        </Modal.Content>
      </Ref>
    </Modal>
  );
};

ModalPage.defaultProps = {
  className: '',
  contentRef: undefined,
  size: undefined
};

export default ModalPage;
