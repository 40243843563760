// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming authority name records.
 */
class AuthorityNames extends NestedAttributes {
  PARAM_NAME: string = 'authority_names';

  /**
   * Overrides the appendFormData function and defaults the collection name.
   *
   * @param formData
   * @param prefix
   * @param record
   * @param collection
   */
  appendFormData(formData: FormData, prefix: string, record: any, collection?: string = this.PARAM_NAME) {
    super.appendFormData(formData, prefix, record, collection);
  }

  /**
   * Returns the authority name payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'name_type_id',
      'country_id',
      'first_name',
      'last_name',
      'year_started',
      'year_ended',
      'year_description',
      'primary',
      '_destroy'
    ];
  }

  /**
   * Overrides the toPayload function and defaults the collection name.
   *
   * @param record
   * @param collection
   *
   * @returns {{[p: string]: *}}
   */
  toPayload(record: any, collection?: string = this.PARAM_NAME): any {
    return super.toPayload(record, collection);
  }
}

export default (new AuthorityNames(): AuthorityNames);
