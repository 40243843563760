// @flow

import React, { type AbstractComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';

type Props = typeof WithTranslation & {
  basic: boolean,
  published: boolean
};

const PublishedLabel: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Label
    basic={props.basic}
    className='published-label'
    content={props.published
      ? props.t('PublishedLabel.published')
      : props.t('PublishedLabel.unpublished')}
    color={props.published ? 'green' : undefined}
    icon={props.icon ? 'send' : undefined}
  />
));

export default PublishedLabel;
