// @flow

import type { StylePeriod as StylePeriodType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type Node } from 'react';
import { Form } from 'semantic-ui-react';
import RichTextArea from './RichTextArea';

type Props = EditContainerProps & Translateable & {
  item: StylePeriodType
};

const StylePeriodForm = (props: Props): Node => (
  <>
    <Form.Input
      error={props.isError('name')}
      label={props.t('StylePeriod.labels.name')}
      onChange={props.onTextInputChange.bind(this, 'name')}
      required={props.isRequired('name')}
      value={props.item.name || ''}
    />
    <Form.Input
      error={props.isError('start_year')}
      label={props.t('StylePeriod.labels.startYear')}
      onChange={props.onTextInputChange.bind(this, 'start_year')}
      required={props.isRequired('start_year')}
      value={props.item.start_year || ''}
    />
    <Form.Input
      error={props.isError('end_year')}
      label={props.t('StylePeriod.labels.endYear')}
      onChange={props.onTextInputChange.bind(this, 'end_year')}
      required={props.isRequired('end_year')}
      value={props.item.end_year || ''}
    />
    <Form.Input
      error={props.isError('description')}
      label={props.t('StylePeriod.labels.description')}
      required={props.isRequired('description')}
    >
      <RichTextArea
        onChange={(text) => props.onTextInputChange('description', null, { value: text })}
        value={props.item.description || ''}
      />
    </Form.Input>
  </>
);

export default StylePeriodForm;
