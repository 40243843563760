// @flow

import type { Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, RouterHistory } from 'react-router-dom';
import { Button, Container, Header } from 'semantic-ui-react';
import './NotFound404.css';

type Props = Translateable & {
  history: typeof RouterHistory
};

const NotFound404: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Container
    className='not-found'
    text
  >
    <Header
      size='huge'
    >
      <Header.Content>
        { props.t('NotFound404.header') }
        <Header.Subheader
          content={props.t('NotFound404.subHeader')}
        />
        <Link
          to='/admin'
        >
          <Button
            content={props.t('NotFound404.buttons.homepage')}
            primary
          />
        </Link>
        <Button
          content={props.t('NotFound404.buttons.back')}
          inverted
          onClick={() => props.history.goBack()}
          primary
        />
      </Header.Content>
    </Header>
  </Container>
));

export default NotFound404;
