// @flow

import type {
  Authority as AuthorityType,
  AuthorityRole as AuthorityRoleType,
  CollectionType as CollectionTypeType,
  Keyword as KeywordType,
  Site as SiteType
} from '@archnet/shared';
import { FilterTypes } from '@performant-software/semantic-components';
import i18n from '../i18n/I18n';
import Authorities from '../services/Authorities';
import Authority from '../transforms/Authority';
import AuthorityRoles from '../services/AuthorityRoles';
import AuthorityRole from '../transforms/AuthorityRole';
import { type Filter } from '../types/Filter';
import Filters from './Filters';
import CollectionType from '../transforms/CollectionType';
import CollectionTypes from '../services/CollectionTypes';
import Keyword from '../transforms/Keyword';
import Keywords from '../services/Keywords';
import Templates from '../resources/collections/Templates.json';
import Site from '../transforms/Site';
import Sites from '../services/Sites';

const CollectionFilters: Array<Filter> = [{
  attributeName: 'name',
  key: 'name',
  label: i18n.t('Collections.filters.name'),
  type: FilterTypes.string
}, {
  attributeName: 'display_name',
  key: 'display_name',
  label: i18n.t('Collections.filters.displayName'),
  type: FilterTypes.string
}, {
  associationColumn: 'collection_id',
  associationName: 'collection_descriptions',
  attributeName: 'collection_descriptions.collection_type_id',
  collectionName: 'collection_types',
  key: 'collection_descriptions.collection_type_id',
  label: i18n.t('Collections.filters.type'),
  objectName: 'collection_type',
  onSearch: (search: string) => CollectionTypes.fetchAll({ search, sort_by: 'name' }),
  renderOption: (collectionType) => CollectionType.toDropdown(collectionType),
  renderSearchQuery: (collectionType: CollectionTypeType) => collectionType.name,
  type: FilterTypes.relationship
}, {
  attributeName: 'template',
  key: 'template',
  label: i18n.t('Collections.filters.template'),
  options: Templates,
  type: FilterTypes.select
}, {
  associationColumn: 'collection_id',
  associationName: 'collections_keywords',
  attributeName: 'collections_keywords.keyword_id',
  collectionName: 'keywords',
  key: 'collections_keywords.keyword_id',
  label: i18n.t('Collections.filters.keyword'),
  objectName: 'keyword',
  onSearch: (search: string) => Keywords.fetchAll({ search, sort_by: 'name' }),
  renderOption: (keyword) => Keyword.toDropdown(keyword),
  renderSearchQuery: (keyword: KeywordType) => keyword.name,
  type: FilterTypes.relationship
}, {
  attributeName: 'description',
  key: 'description',
  label: i18n.t('Collections.filters.description'),
  type: FilterTypes.text
}, {
  attributeName: 'caption',
  key: 'caption',
  label: i18n.t('Collections.filters.caption'),
  type: FilterTypes.text
}, {
  attributeName: 'abstract',
  key: 'abstract',
  label: i18n.t('Collections.filters.abstract'),
  type: FilterTypes.text
}, {
  baseClass: 'Authority',
  associationColumn: 'parent_id',
  associationName: 'collection_record_associations',
  attributeName: 'authorities.id',
  collectionName: 'authorities',
  key: 'authority',
  label: i18n.t('Collections.filters.authority'),
  objectName: 'authority',
  onSearch: (search: string) => Authorities.fetchAll({ search, sort_by: ['first_name', 'last_name'] }),
  renderOption: (authority) => Authority.toDropdown(authority),
  renderSearchQuery: (authority: AuthorityType) => authority.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Site',
  associationColumn: 'parent_id',
  associationName: 'collection_record_associations',
  attributeName: 'sites.id',
  collectionName: 'sites',
  key: 'site',
  label: i18n.t('Collections.filters.site'),
  objectName: 'site',
  onSearch: (search: string) => Sites.fetchAll({ search, sort_by: 'site_names.name' }),
  renderOption: (site) => Site.toDropdown(site),
  renderSearchQuery: (site: SiteType) => site.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Authority',
  associationColumn: 'parent_id',
  associationName: 'collection_record_associations',
  attributeName: 'record_associations.data',
  collectionName: 'authority_roles',
  key: 'authority_roles',
  label: i18n.t('Common.filters.authorityRole'),
  objectName: 'authority_role',
  onSearch: (search: string) => AuthorityRoles.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (authorityRole) => AuthorityRole.toDropdown(authorityRole),
  renderSearchQuery: (authorityRole: AuthorityRoleType) => authorityRole.name,
  type: FilterTypes.relationship
}, ...Filters.getFilters()];

export default CollectionFilters;
