// @flow

import type { RecordAssociation, Translateable } from '@archnet/shared';
import { ItemCollection, type ListProps } from '@performant-software/semantic-components';
import React, {
  useCallback,
  useMemo,
  useState,
  type ComponentType
} from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import ScrollViewContext from '../contexts/ScrollViewContext';
import './AssociatedRecordsList.css';

type Props = ListProps & Translateable & {
  className?: string,
  draggable?: boolean,
  items: Array<RecordAssociation>,
  moveable?: boolean,
  onDataLoaded?: (items: Array<RecordAssociation>) => void,
  onLoad?: (params: any) => Promise<any>,
  onUpdate: (items: Array<RecordAssociation>) => void,
  perPage?: number
};

const AssociatedRecordsList = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<number | null>(null);

  /**
   * Sets the combined class name.
   *
   * @type {string}
   */
  const className = useMemo(() => {
    const classNames = ['associated-records-list'];

    if (props.className) {
      classNames.push(props.className);
    }

    return classNames.join(' ');
  }, [props.className]);

  /**
   * Sets the collection of non-deleted items.
   *
   * @returns {*}
   */
  const items = useMemo(() => _.filter(props.items, (item) => !item._destroy), [props.items]);

  /**
   * Calls the onLoad prop, sets the pages on the state, and calls the onDataLoaded prop.
   *
   * @type {(function(*): void)|*}
   */
  const onBottomReached = useCallback((page: number) => {
    // Return if the onLoad or onDataLoaded props are not provided
    if (!(props.onLoad && props.onDataLoaded)) {
      return;
    }

    // Return if we've reached the last possible page
    if (pages && page > pages) {
      return;
    }

    // Set the loading indicator
    setLoading(true);

    // Load the next page
    if (props.onLoad) {
      props.onLoad({ page })
        .then(({ data }) => {
          setPages(data.list.pages);

          if (props.onDataLoaded) {
            props.onDataLoaded(data.record_associations);
          }
        })
        .catch(() => setPages(1))
        .finally(() => setLoading(false));
    }
  }, [pages, props.onLoad, props.onDataLoaded]);

  /**
   * Drags the item at the passed dragIndex to the passed hoverIndex.
   *
   * @type {(function(*=, *=): void)|*}
   */
  const onDrag = useCallback((dragIndex: number, hoverIndex: number) => {
    if (props.draggable && props.onUpdate) {
      const newItems = [...items];
      const item = newItems[dragIndex];

      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, item);

      props.onUpdate(newItems);
    }
  }, [items, props.draggable, props.onUpdate]);

  /**
   * Moves the passed item to the bottom of the collection.
   *
   * @type {(function(*): void)|*}
   */
  const onMoveToBottom = useCallback((item: any) => {
    if (props.onUpdate) {
      props.onUpdate([
        ..._.reject(items, (i) => i === item),
        item
      ]);
    }
  }, [items, props.onUpdate]);

  /**
   * Moves the passed item to the top of the collection.
   *
   * @type {(function(*): void)|*}
   */
  const onMoveToTop = useCallback((item: any) => {
    if (props.onUpdate) {
      props.onUpdate([
        item,
        ..._.reject(items, (i) => i === item)
      ]);
    }
  }, [items, props.onUpdate]);

  return (
    <ScrollViewContext.Consumer>
      {({ containerRef }) => (
        <ItemCollection
          {...props}
          actions={[...props.actions, {
            accept: () => props.moveable && props.onUpdate,
            basic: true,
            icon: 'arrow up',
            label: props.t('AssociatedRecordsList.buttons.moveTop'),
            onClick: onMoveToTop
          }, {
            accept: () => props.moveable && props.onUpdate,
            basic: true,
            icon: 'arrow down',
            label: props.t('AssociatedRecordsList.buttons.moveBottom'),
            onClick: onMoveToBottom
          }]}
          className={className}
          context={containerRef}
          items={items}
          loading={loading}
          onBottomReached={onBottomReached}
          onDrag={props.draggable && props.onUpdate ? onDrag : undefined}
          renderEmptyList={() => null}
          scrollOffset={200}
        />
      )}
    </ScrollViewContext.Consumer>
  );
};

AssociatedRecordsList.defaultProps = {
  className: undefined,
  draggable: true,
  moveable: true,
  onDataLoaded: undefined,
  onLoad: undefined,
  perPage: 10
};

const AssociatedRecordsListComponent: ComponentType<any> = withTranslation()(AssociatedRecordsList);
export default AssociatedRecordsListComponent;
