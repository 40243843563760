// @flow

import type { Translateable } from '@archnet/shared';
import React, { type AbstractComponent } from 'react';
import { Header } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import RichTextArea, { type RichTextAreaProps } from './RichTextArea';
import 'react-quill/dist/quill.snow.css';

type Props = RichTextAreaProps & Translateable & {
  displayWordCount: boolean
};

const QuillEditor = (props: Props) => {
  /**
   * Returns the word count for the passed string.
   *
   * @param string
   *
   * @returns {number}
   */
  const wordCount = (string: ?string) => (
    string && string.trim().length > 0 && string !== '<p><br></p>'
      ? string.split(' ').length : 0
  );

  return (
    <>
      { props.displayWordCount && (
        <Header
          as='h5'
          content={
            props.t('QuillEditor.labels.wordCount', { count: wordCount(props.value) })
          }
        />
      )}
      <RichTextArea
        formats={props.formats}
        modules={props.modules}
        onChange={props.onChange}
        placeholder={props.placeholder || props.t('QuillEditor.messages.placeholder')}
        theme={props.theme}
        value={props.value}
      />
    </>
  );
};

QuillEditor.defaultProps = {
  displayWordCount: true
};

export default (withTranslation()(QuillEditor): AbstractComponent<any>);
