// @flow

import BaseService from './BaseService';
import Material from '../transforms/Material';

/**
 * Service class for all material based API calls.
 */
class Materials extends BaseService {
  /**
   * Returns the materials base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/materials';
  }

  /**
   * Returns the materials transform.
   *
   * @returns {Material}
   */
  getTransform(): typeof Material {
    return Material;
  }
}

export default (new Materials(): Materials);
