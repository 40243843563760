// @flow

import _ from 'underscore';
import type { Option } from '../types/Option';
import AdminNotes from './AdminNotes';
import Attachments from './Attachments';
import FormDataTransform from './FormDataTransform';
import Keywords from './Keywords';
import Languages from './Languages';
import Materials from './Materials';
import { MediaUtils } from '@archnet/shared';
import RecordAssociations from './RecordAssociations';
import StylePeriods from './StylePeriods';
import String from '../utils/String';

import type { MediaContent as MediaContentType } from '@archnet/shared';

type MediaContentUpload = {
  ...MediaContentType,
  files: Array<{ content: File }>
};

/**
 * Class for handling transforming media_content records.
 */
class MediaContent extends FormDataTransform {
  /**
   * Returns the media content parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'media_content';
  }

  /**
   * Returns the media content payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'caption',
      'copyright',
      'media_type_id',
      'published',
      'hcp_item',
      'award_winner',
      'flag_for_review',
      'year',
      'year_description',
      'source_id',
      'source',
      'copyright',
      'contributor',
      'dimensions',
      'caption',
      'description',
      'abstract',
      'locked',
      'external_id',
      'external_source',
      'place_name',
      'latitude',
      'longitude',
      'address_street',
      'address_province',
      'address_country',
      'address_postal_code',
      'address_region_state',
      'country_id'
    ];
  }

  /**
   * Returns the passed media_content item as a dropdown option.
   *
   * @param mediaContent
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(mediaContent: MediaContentType): Option {
    return {
      key: mediaContent.id,
      value: mediaContent.id,
      text: mediaContent.name,
      image: MediaUtils.getImageUrl(mediaContent)
    };
  }

  /**
   * Returns the media_content object to be sent to the server on POST/PUT requests.
   *
   * @param mediaContent
   *
   * @returns {FormData}
   */
  toPayload(mediaContent: MediaContentType): FormData {
    const formData = super.toPayload(mediaContent);

    Attachments.appendFormData(formData, this.getParameterName(), mediaContent, 'content');
    AdminNotes.appendFormData(formData, this.getParameterName(), mediaContent);
    Keywords.appendFormData(formData, this.getParameterName(), mediaContent, 'keywords_media_contents');
    Languages.appendFormData(formData, this.getParameterName(), mediaContent, 'languages_media_contents');
    Materials.appendFormData(formData, this.getParameterName(), mediaContent, 'materials_media_contents');
    RecordAssociations.appendFormData(formData, this.getParameterName(), mediaContent, 'site_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), mediaContent, 'media_record_associations');

    RecordAssociations.appendFormData(
      formData,
      this.getParameterName(),
      mediaContent,
      'collection_record_associations'
    );

    RecordAssociations.appendFormData(
      formData,
      this.getParameterName(),
      mediaContent,
      'publication_record_associations'
    );

    RecordAssociations.appendFormData(formData, this.getParameterName(), mediaContent, 'authority_record_associations');
    StylePeriods.appendFormData(formData, this.getParameterName(), mediaContent, 'media_contents_style_periods');

    return formData;
  }

  /**
   * Returns the form data to be sent to the server on /api/media_content/upload.
   *
   * @param mediaContent
   *
   * @returns {FormData}
   */
  toBulkPayload(mediaContent: MediaContentUpload): FormData {
    const formData = new FormData();

    _.each(mediaContent.files, (file, index) => {
      _.each(this.getPayloadKeys(), (key) => {
        formData.append(`media_contents[${index}][${key}]`, String.toString(mediaContent[key]));
      });

      formData.append(`media_contents[${index}][source_id]`, file.name);
      Attachments.appendFormData(formData, `media_contents[${index}]`, file, 'content');
      Attachments.appendFormData(formData, `media_contents[${index}]`, file, 'name');

      RecordAssociations.appendFormData(
        formData,
        `[media_contents][${index}]`,
        mediaContent,
        'authority_record_associations'
      );

      RecordAssociations.appendFormData(
        formData,
        `[media_contents][${index}]`,
        mediaContent,
        'collection_record_associations'
      );

      RecordAssociations.appendFormData(
        formData,
        `[media_contents][${index}]`,
        mediaContent,
        'donation_record_associations'
      );

      RecordAssociations.appendFormData(
        formData,
        `[media_contents][${index}]`,
        mediaContent,
        'site_record_associations'
      );
    });

    return formData;
  }
}

export default (new MediaContent(): MediaContent);

export type {
  MediaContentUpload
};
