// @flow

import type { Authority, RecordAssociation, Translateable } from '@archnet/shared';
import { AssociatedDropdown, ListFilters, Selectize } from '@performant-software/semantic-components';
import React, {
  useState, type Element, type AbstractComponent, useMemo
} from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, Header, Item } from 'semantic-ui-react';
import _ from 'underscore';
import AgentModal, { NameTypes } from './AgentModal';
import AgentRoleModal from './AgentRoleModal';
import AssociatedRecordsList from './AssociatedRecordsList';
import Authorities from '../services/Authorities';
import AuthorityFilters from '../filters/AuthorityFilters';
import AuthorityRole from '../transforms/AuthorityRole';
import AuthorityRoles from '../services/AuthorityRoles';
import PrimaryImageView from './PrimaryImageView';
import PublishedLabel from './PublishedLabel';
import SelectizeHeader from './SelectizeHeader';
import './AssociatedAuthorities.css';

type Props = Translateable & {
  addButton?: string,
  items: Array<RecordAssociation>,
  onDataLoaded?: (items: Array<RecordAssociation>) => void,
  onDelete: (item: RecordAssociation) => void,
  onLoad: (params: any) => Promise<any>,
  onRoleSelection?: (item: RecordAssociation) => void,
  onSave: (item: RecordAssociation) => void,
  onUpdate?: (Array<any>) => void,
  renderExtra: (item: RecordAssociation) => Element<any>,
  renderHeader: (item: RecordAssociation) => Element<any>,
  renderImage: (item: RecordAssociation) => Element<any>,
  renderMeta: (item: RecordAssociation) => Element<any>,
  resolveAuthority: (item: RecordAssociation) => Authority,
};

const AssociatedAuthorities: AbstractComponent<any> = withTranslation()((props: Props) => {
  const [modal, setModal] = useState(false);

  /**
   * Renders the passed authority for the Selectize component.
   *
   * @param authority
   *
   * @returns {*}
   */
  const renderAuthority = (authority: Authority) => (
    <Item.Group>
      <Item>
        <Item.Image
          style={{
            width: 'unset'
          }}
        >
          <PrimaryImageView
            dimmable={false}
            item={authority}
            size='tiny'
          />
        </Item.Image>
        <Item.Content>
          <Item.Header
            content={authority.name}
          />
          <Item.Meta
            content={authority.record_id}
          />
          <Item.Extra>
            <PublishedLabel
              icon
              published={authority.published}
            />
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
  );

  /**
   * Renders the extra information for the passed item.
   *
   * @param item
   *
   * @returns {JSX.Element|*}
   */
  const renderExtra = (item: RecordAssociation) => {
    if (props.renderExtra) {
      return props.renderExtra(item);
    }

    const authority = resolveAuthority(item);

    return (
      <PublishedLabel
        icon
        published={authority.published}
      />
    );
  };

  /**
   * Renders the header for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderHeader = (item: RecordAssociation) => {
    if (props.renderHeader) {
      return props.renderHeader(item);
    }

    const authority = resolveAuthority(item);

    return (
      <Link
        to={`/admin/authorities/${authority.id}`}
      >
        <Header
          as='h3'
          content={authority.name}
        />
      </Link>
    );
  };

  /**
   * Renders the image for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderImage = (item: RecordAssociation) => {
    if (props.renderImage) {
      return props.renderImage(item);
    }

    const authority = resolveAuthority(item);

    return (
      <PrimaryImageView
        item={authority}
      />
    );
  };

  /**
   * Renders the metadata for the passed item.
   *
   * @param item
   *
   * @returns {string|*}
   */
  const renderMeta = (item: RecordAssociation) => {
    if (props.renderMeta) {
      return props.renderMeta(item);
    }

    const authority = resolveAuthority(item);
    return authority.record_id;
  };

  /**
   * Returns the related authority for the passed authority record association.
   *
   * @param item
   *
   * @returns {*|{}}
   */
  const resolveAuthority = (item: RecordAssociation) => (props.resolveAuthority && props.resolveAuthority(item)) || {};

  /**
   * Renders the authority role dropdown.
   *
   * @param item
   *
   * @returns {JSX.Element}
   */
  const renderAdditionalContent = (item: RecordAssociation) => {
    if (!props.onRoleSelection) {
      return null;
    }

    return (
      <Item.Content>
        <Form.Input
          label={props.t('AssociatedAuthorities.labels.role')}
        >
          <AssociatedDropdown
            collectionName='authority_roles'
            onSearch={(search) => AuthorityRoles.fetchAll({ per_page: 0, search, sort_by: 'name' })}
            onSelection={(role) => {
              if (props.onRoleSelection) {
                props.onRoleSelection({ ...item, role, data: role.id });
              }
            }}
            renderOption={(authorityRole) => AuthorityRole.toDropdown(authorityRole)}
            searchQuery={item.role && item.role.name}
            value={item.data}
            modal={{
              component: AgentRoleModal,
              onSave: (authorityRole) => AuthorityRoles.save(authorityRole).then(({ data }) => data.authority_role)
            }}
          />
        </Form.Input>
      </Item.Content>
    );
  };

  return (
    <>
      <AssociatedRecordsList
        actions={[{
          basic: true,
          color: 'red',
          icon: 'trash',
          label: props.t('AssociatedAuthorities.buttons.delete'),
          onClick: props.onDelete.bind(this)
        }]}
        buttons={[{
          color: 'green',
          content: props.addButton || props.t('AssociatedAuthorities.buttons.add'),
          icon: 'plus',
          onClick: () => setModal(true)
        }]}
        className='associated-authorities'
        items={props.items}
        onDataLoaded={props.onDataLoaded}
        onLoad={props.onLoad}
        onUpdate={props.onUpdate}
        renderAdditionalContent={renderAdditionalContent.bind(this)}
        renderExtra={renderExtra.bind(this)}
        renderHeader={renderHeader.bind(this)}
        renderImage={renderImage.bind(this)}
        renderMeta={renderMeta.bind(this)}
      />
      {modal && (
        <Selectize
          collectionName='authorities'
          filters={{
            component: ListFilters,
            props: {
              filters: AuthorityFilters
            }
          }}
          modal={{
            component: AgentModal,
            onSave: (an) => Authorities.save({ authority_names: [an] }).then(({ data }) => data.authority),
            props: {
              defaults: {
                nameType: NameTypes.individual
              }
            }
          }}
          onClose={() => setModal(false)}
          onLoad={(params) => Authorities.search({
            ...params,
            per_page: 5,
            sort_by: [
              'authority_names.last_name',
              'authority_names.first_name'
            ]
          })}
          onSave={(authorities) => {
            props.onSave(authorities);
            setModal(false);
          }}
          renderHeader={(headerProps) => (
            <SelectizeHeader
              {...headerProps}
              type='Authority'
            />
          )}
          renderItem={renderAuthority.bind(this)}
          selectedItems={_.map(props.items, resolveAuthority)}
          title={props.t('AssociatedAuthorities.selectize.title')}
          width='60%'
        />
      )}
    </>
  );
});

export default AssociatedAuthorities;
