// @flow

import type { Addressable, Translateable } from '@archnet/shared';
import { GoogleScript } from '@performant-software/shared-components';
import {
  AssociatedDropdown,
  GooglePlacesSearch,
  Toaster
} from '@performant-software/semantic-components';
import type { EditPageProps } from '@performant-software/semantic-components/types';
import React, {
  useCallback,
  useMemo,
  useState,
  type AbstractComponent
} from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Grid, Message, } from 'semantic-ui-react';
import _ from 'underscore';
import Countries from '../services/Countries';
import Country from '../transforms/Country';
import GeographyMap from './GeographyMap';
import './Geography.css';

type Props = Translateable & {
  ...EditPageProps,
  item: Addressable
};

const GooglePlacesKeys = {
  city: 'locality',
  country: 'country',
  county: 'administrative_area_level_2',
  postalCode: 'postal_code',
  province: 'administrative_area_level_1',
  street: 'route',
  streetNumber: 'street_number'
};

const Geography: AbstractComponent<any> = withTranslation()((props: Props) => {
  const [geocodeError, setGeocodeError] = useState(false);

  const formattedAddress = useMemo(() => _.compact([
    props.item.address_street,
    props.item.place_name,
    props.item.address_region_state,
    props.item.address_province,
    props.item.country && props.item.country.name,
    props.item.address_postal_code
  ]).join(', '), [props.item]);

  const onLocationSelection = useCallback(({ result, lat, lng }: { result: any, lat: number, lng: number }) => {
    const address = {
      address_street: '',
      place_name: '',
      address_region_state: '',
      address_province: '',
      country: null,
      address_postal_code: ''
    };

    const street = {
      name: '',
      number: ''
    };

    let country;
    let countryCode;

    _.each(result.address_components, (ac) => {
      _.each(ac.types, (type) => {
        switch (type) {
          case GooglePlacesKeys.country:
            countryCode = ac.short_name;
            break;

          case GooglePlacesKeys.city:
            address.place_name = ac.long_name;
            break;

          case GooglePlacesKeys.county:
            address.address_region_state = ac.long_name;
            break;

          case GooglePlacesKeys.postalCode:
            address.address_postal_code = ac.long_name;
            break;

          case GooglePlacesKeys.province:
            address.address_province = ac.long_name;
            break;

          case GooglePlacesKeys.street:
            street.name = ac.long_name;
            break;

          case GooglePlacesKeys.streetNumber:
            street.number = ac.long_name;
            break;

          default:
            // Do nothing
        }
      });
    });

    Countries
      .fetchAll({ code: countryCode })
      .then(({ data }) => {
        country = _.first(data.countries);
      })
      .finally(() => {
        props.onSetState({
          ...address,
          address_street: _.compact([street.number, street.name]).join(' '),
          latitude: lat,
          longitude: lng,
          country,
          country_id: country && country.id
        });
      });
  }, [props.onSetState]);

  return (
    <GoogleScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={['places']}
    >
      <Grid
        className='geography'
        columns={2}
      >
        <Grid.Column>
          <GooglePlacesSearch
            containerElement={<Form.Field />}
            onLocationSelection={onLocationSelection.bind(this)}
          >
            <Form.Input
              autoFocus
              error={props.isError('place_name')}
              label={props.t('Geography.labels.placeName')}
              placeholder=''
              onChange={props.onTextInputChange.bind(this, 'place_name')}
              required={props.isRequired('place_name')}
              value={props.item.place_name || ''}
            />
          </GooglePlacesSearch>
          <Form.TextArea
            error={props.isError('address_street')}
            label={props.t('Geography.labels.street')}
            onChange={props.onTextInputChange.bind(this, 'address_street')}
            required={props.isRequired('address_street')}
            rows={2}
            value={props.item.address_street || ''}
          />
          <Form.Input
            error={props.isError('address_region_state')}
            label={props.t('Geography.labels.regionState')}
            onChange={props.onTextInputChange.bind(this, 'address_region_state')}
            required={props.isRequired('address_region_state')}
            value={props.item.address_region_state || ''}
          />
          <Form.Input
            error={props.isError('address_province')}
            label={props.t('Geography.labels.province')}
            onChange={props.onTextInputChange.bind(this, 'address_province')}
            required={props.isRequired('address_province')}
            value={props.item.address_province || ''}
          />
          <Form.Input
            error={props.isError('country_id')}
            label={props.t('Geography.labels.country')}
            required={props.isRequired('country_id')}
          >
            <AssociatedDropdown
              collectionName='countries'
              onSearch={(search) => Countries.fetchAll({ per_page: 0, search, sort_by: 'name' })}
              onSelection={props.onAssociationInputChange.bind(this, 'country_id', 'country')}
              renderOption={(country) => Country.toDropdown(country)}
              searchQuery={props.item.country && props.item.country.name}
              value={props.item.country_id || ''}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('address_postal_code')}
            label={props.t('Geography.labels.postalCode')}
            onChange={props.onTextInputChange.bind(this, 'address_postal_code')}
            required={props.isRequired('address_postal_code')}
            value={props.item.address_postal_code || ''}
          />
          <Form.Input
            error={props.isError('latitude')}
            label={props.t('Geography.labels.latitude')}
            onChange={props.onTextInputChange.bind(this, 'latitude')}
            required={props.isRequired('latitude')}
            value={props.item.latitude || ''}
          />
          <Form.Input
            error={props.isError('longitude')}
            label={props.t('Geography.labels.longitude')}
            onChange={props.onTextInputChange.bind(this, 'longitude')}
            required={props.isRequired('longitude')}
            value={props.item.longitude || ''}
          />
        </Grid.Column>
        <Grid.Column>
          <GeographyMap
            address={formattedAddress}
            latitude={props.item.latitude}
            longitude={props.item.longitude}
            onAddressSuccess={(response) => {
              const { lat, lng } = response.results[0].geometry.location;
              props.onSetState({ latitude: lat, longitude: lng });
            }}
            onCoordinatesSuccess={(response) => onLocationSelection({
              result: _.first(response.results),
              lat: props.item.latitude,
              lng: props.item.longitude
            })}
            onError={({ message }) => setGeocodeError(message)}
            onMarkerDrag={({ lat, lng }) => props.onSetState({ latitude: lat, longitude: lng })}
          />
          { geocodeError && (
            <Toaster
              onDismiss={() => setGeocodeError(false)}
              timeout={0}
              type={Toaster.MessageTypes.negative}
            >
              <Message.Header
                content={props.t('Geography.errors.geocoder.header')}
              />
              <Message.List
                items={[geocodeError]}
              />
            </Toaster>
          )}
        </Grid.Column>
      </Grid>
    </GoogleScript>
  );
});

export default Geography;
