// @flow

import _ from 'underscore';

import type { Keyword as KeywordType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming Keyword records.
 */
class Keyword {
  PAYLOAD_KEYS: Array<string> = [
    'id',
    'name',
    '_destroy'
  ];

  /**
   * Returns the passed Keyword as a dropdown option.
   *
   * @param keyword
   *
   * @returns {{key: number, value: number, text: string}}
   */
  toDropdown(keyword: KeywordType): Option {
    return {
      key: keyword.id,
      value: keyword.id,
      text: keyword.name
    };
  }

  /**
   * Returns the keyword to be sent to the server on POST/PUT requests.
   *
   * @param keyword
   *
   * @returns {{keyword: (*)}}
   */
  toPayload(keyword: KeywordType): any {
    return {
      keyword: _.pick(keyword, this.PAYLOAD_KEYS)
    };
  }
}

export default (new Keyword(): Keyword);
