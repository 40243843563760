// @flow

import { AssociatedDropdown } from '@performant-software/semantic-components';
import React, { type AbstractComponent, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Form,
  Modal,
  Radio
} from 'semantic-ui-react';
import AuthorityNameType from '../transforms/AuthorityNameType';
import AuthorityNameTypes from '../services/AuthorityNameTypes';
import Countries from '../services/Countries';
import Country from '../transforms/Country';
import './AgentModal.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { AuthorityName, Translateable } from '@archnet/shared';

type Props = Translateable & {
  ...EditContainerProps,
  item: AuthorityName & {
    nameType: string
  }
};

const NameTypes = {
  individual: 'individual',
  institution: 'institution'
};

const AgentModal: AbstractComponent<any> = withTranslation()((props: Props) => {
  /**
   * Calls the `/api/countries` API endpoint.
   *
   * @type {function(*): Promise<*>}
   */
  const onCountrySearch = useCallback((search) => Countries.fetchAll({ per_page: 0, search, sort_by: ['name'] }), []);

  /**
   * Calls the `/api/authority_name_types` API endpoint.
   *
   * @type {function(*): Promise<*>}
   */
  const onNameTypeSearch = useCallback((search) => AuthorityNameTypes.fetchAll({ search, sort_by: 'name' }), []);

  return (
    <Modal
      as={Form}
      centered={false}
      className='agent-modal'
      noValidate
      open
      size='small'
    >
      <Modal.Header
        content={props.item.id
          ? props.t('AgentModal.title.edit')
          : props.t('AgentModal.title.add')}
      />
      <Modal.Content>
        <Form.Group>
          <Form.Field>
            <Radio
              label={props.t('AgentModal.labels.individual')}
              name='nameRadioGroup'
              value={NameTypes.individual}
              checked={props.item.nameType === NameTypes.individual}
              onChange={props.onTextInputChange.bind(this, 'nameType')}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={props.t('AgentModal.labels.institution')}
              name='nameRadioGroup'
              value={NameTypes.institution}
              checked={props.item.nameType === NameTypes.institution}
              onChange={props.onTextInputChange.bind(this, 'nameType')}
            />
          </Form.Field>
        </Form.Group>
        { props.item.nameType === NameTypes.institution && (
          <Form.Input
            autoFocus
            error={props.isError('agent')}
            label={props.t('AgentModal.labels.institutionName')}
            onChange={props.onTextInputChange.bind(this, 'first_name')}
            required={props.isRequired('agent')}
            value={props.item.first_name || ''}
          />
        )}
        { props.item.nameType !== NameTypes.institution && (
          <Form.Group widths='equal'>
            <Form.Input
              autoFocus
              error={props.isError('agent')}
              label={props.t('AgentModal.labels.firstName')}
              onChange={props.onTextInputChange.bind(this, 'first_name')}
              required={props.isRequired('agent')}
              value={props.item.first_name || ''}
            />
            <Form.Input
              error={props.isError('agent')}
              label={props.t('AgentModal.labels.lastName')}
              onChange={props.onTextInputChange.bind(this, 'last_name')}
              required={props.isRequired('agent')}
              value={props.item.last_name || ''}
            />
          </Form.Group>
        )}
        <Form.Group widths='equal'>
          <Form.Input
            error={props.isError('authority_name_type_id')}
            label={props.t('AgentModal.labels.qualify')}
            required={props.isRequired('authority_name_type_id')}
          >
            <AssociatedDropdown
              collectionName='authority_name_types'
              onSearch={onNameTypeSearch}
              onSelection={props.onAssociationInputChange.bind(this, 'name_type_id', 'name_type')}
              renderOption={(nameType) => AuthorityNameType.toDropdown(nameType)}
              searchQuery={props.item.name_type && props.item.name_type.name}
              value={props.item.name_type_id}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('country')}
            label={props.t('AgentModal.labels.country')}
            required={props.isRequired('country')}
          >
            <AssociatedDropdown
              collectionName='countries'
              onSearch={onCountrySearch}
              onSelection={props.onAssociationInputChange.bind(this, 'country_id', 'country')}
              renderOption={(country) => Country.toDropdown(country)}
              searchQuery={props.item.country && props.item.country.name}
              value={props.item.country_id}
            />
          </Form.Input>
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={props.isError('agent')}
            label={props.t('AgentModal.labels.yearStarted')}
            onChange={props.onTextInputChange.bind(this, 'year_started')}
            required={props.isRequired('yearStarted')}
            value={props.item.year_started || ''}
            width={4}
          />
          <Form.Input
            error={props.isError('agent')}
            label={props.t('AgentModal.labels.yearEnded')}
            onChange={props.onTextInputChange.bind(this, 'year_ended')}
            required={props.isRequired('yearEnded')}
            value={props.item.year_ended || ''}
            width={4}
          />
          <Form.Input
            error={props.isError('agent')}
            label={props.t('AgentModal.labels.yearDescription')}
            onChange={props.onTextInputChange.bind(this, 'year_description')}
            required={props.isRequired('yearDescription')}
            value={props.item.year_description || ''}
            width={8}
          />
        </Form.Group>
        <Form.Checkbox
          checked={!!props.item.primary}
          label={props.t('AgentModal.labels.primary')}
          onChange={props.onCheckboxInputChange.bind(this, 'primary')}
        />
      </Modal.Content>
      { props.children }
    </Modal>
  );
});

export default AgentModal;

export { NameTypes };
