// @flow

import React, { type Node } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import './RichTextArea.css';
import 'react-quill/dist/quill.snow.css';

type Props = {
  formats?: any,
  modules?: any,
  onChange: (value: string) => void,
  placeholder?: string,
  theme?: string,
  value: ?string
};

const SEARCH_EMPTY = '<p><br></p>';
const REPLACE_EMPTY = '';

Quill.register('modules/imageResize', ImageResize);

const RichTextArea = (props: Props): Node => (
  <ReactQuill
    className='rich-text-area'
    formats={props.formats}
    modules={props.modules}
    onChange={(value) => {
      let newValue = value;

      if (value === SEARCH_EMPTY) {
        newValue = REPLACE_EMPTY;
      }

      props.onChange(newValue);
    }}
    placeholder={props.placeholder}
    theme={props.theme}
    value={props.value}
  />
);

RichTextArea.defaultProps = {
  formats: [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'script',
    'link',
    'image',
    'video'
  ],
  modules: {
    imageResize: {
      // $FlowIgnore
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    },
    toolbar: [[{
      header: '1'
    }, {
      header: '2'
    }, {
      // $FlowIgnore
      font: []
    }], [{
      // $FlowIgnore
      size: []
    }], [
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote'
    ], [{
      script: 'super'
    }, {
      script: 'sub'
    }], [{
      list: 'ordered'
    }, {
      list: 'bullet'
    }, {
      indent: '-1'
    }, {
      indent: '+1'
    }], [
      'link',
      'image'
    ], [
      'clean'
    ]],
    clipboard: {
      matchVisual: false
    }
  },
  placeholder: undefined,
  theme: 'snow'
};

export default RichTextArea;
export type { Props as RichTextAreaProps };
