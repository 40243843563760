// @flow

import type { Translateable } from '@archnet/shared';
import { ListFilters, Selectize } from '@performant-software/semantic-components';
import React, {
  useCallback,
  useEffect,
  useState,
  type AbstractComponent,
  type Element
} from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Radio,
  Segment
} from 'semantic-ui-react';
import _ from 'underscore';
import type { Filter } from '../types/Filter';
import './BatchUpdateSelectize.css';

type Props = {
  collectionName?: string,
  filters?: Array<Filter>,
  onLoad: (params: any) => Promise<any>,
  onOperatorChange: (operator: string) => void,
  onSelection: (items: Array<any>) => void,
  renderHeader: (item: any) => Element<any>,
  renderOption: (item: any) => Element<any>,
  renderSelected?: (items: Array<any>, state: any, setState: (props: any) => void) => Element<any>,
  state: any,
  setState: (state: any) => void,
  title: string
};

const Operators = {
  add: 'add',
  remove: 'remove'
};

const BatchUpdateSelectize: AbstractComponent<any> = withTranslation()((props: Props & Translateable) => {
  const [modal, setModal] = useState(false);
  const [operator, setOperator] = useState(Operators.add);
  const [selectedItems, setSelectedItems] = useState<Array<any>>([]);

  /**
   * Renders the list of selected items.
   *
   * @type {(function(): (*))|*}
   */
  const renderSelected = useCallback(() => {
    if (props.renderSelected) {
      return props.renderSelected(selectedItems, props.state, props.setState);
    }

    return (
      <Segment>
        { _.map(selectedItems, (item) => (
          <Label
            content={props.renderOption(item)}
            onRemove={() => setSelectedItems((prevItems) => _.filter(prevItems, (i) => i !== item))}
          />
        ))}
      </Segment>
    );
  }, [selectedItems, props.renderOption, props.renderSelected, props.state]);

  /**
   * Calls the onOperatorChange prop with the current operator value.
   */
  useEffect(() => {
    props.onOperatorChange(operator);
  }, [operator]);

  /**
   * Calls the onSelection prop with the current selected items array.
   */
  useEffect(() => {
    props.onSelection(selectedItems);
  }, [selectedItems]);

  return (
    <div
      className='batch-update-selectize'
    >
      <Button
        className='select'
        color='green'
        content={props.t('BatchUpdateSelectize.buttons.select', { title: props.title })}
        icon='checkmark'
        onClick={() => setModal(true)}
      />
      <Radio
        label={props.t('BatchUpdateSelectize.labels.add')}
        name='operator'
        value={Operators.add}
        checked={operator === Operators.add}
        onChange={(e, data) => setOperator(data.value)}
      />
      <Radio
        label={props.t('BatchUpdateSelectize.labels.remove')}
        name='operator'
        value={Operators.remove}
        checked={operator === Operators.remove}
        onChange={(e, data) => setOperator(data.value)}
      />
      { renderSelected() }
      { modal && (
        <Selectize
          collectionName={props.collectionName}
          filters={{
            component: ListFilters,
            props: {
              filters: props.filters
            }
          }}
          onClose={() => setModal(false)}
          onLoad={props.onLoad}
          onSave={(items) => {
            setSelectedItems(items);
            setModal(false);
          }}
          renderHeader={props.renderHeader}
          renderItem={props.renderOption}
          selectedItems={selectedItems}
          title={props.t('BatchUpdateSelectize.title', { title: props.title })}
          width='60%'
        />
      )}
    </div>
  );
});

export default BatchUpdateSelectize;

export {
  Operators
};
