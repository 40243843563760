// @flow

import BatchUpdateable from './BatchUpdateable';
import Donor from '../transforms/Donor';

/**
 * Service class for all donor based API calls.
 */
class Donors extends BatchUpdateable {
  /**
   * Returns the donors base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/donors';
  }

  /**
   * Returns the donors transform.
   *
   * @returns {Donor}
   */
  getTransform(): typeof Donor {
    return Donor;
  }
}

export default (new Donors(): Donors);
