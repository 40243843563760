// @flow

import type { Material, Translateable } from '@archnet/shared';
import { Selectize } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import _ from 'underscore';
import MaterialModal from './MaterialModal';
import Materials from '../services/Materials';
import SimpleTagsList from './SimpleTagsList';

type Materialable = {
  id: number,
  material_id: number,
  material: Material
};

type Props = Translateable & {
  items: Array<Materialable>,
  onDelete: (item: Materialable) => void,
  onSave: (items: Array<Materialable>) => void
};

const MaterialsList: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleTagsList
    items={_.filter(props.items, (item) => !item._destroy)}
    onDelete={props.onDelete.bind(this)}
    renderItem={(item) => item.material.name}
    renderModal={({ onClose }) => (
      <Selectize
        collectionName='materials'
        modal={{
          component: MaterialModal,
          onSave: (material) => Materials.save(material).then(({ data }) => data.material)
        }}
        onClose={onClose}
        onLoad={(params) => Materials.fetchAll({ ...params, sort_by: 'name' })}
        onSave={(materials) => {
          const findMaterial = (item: Materialable) => _.find(props.items, (i) => i.material_id === item.id);
          const createMaterial = (item: Materialable) => ({ uid: uuid(), material_id: item.id, material: item });

          // Create or find items
          const items = _.map(materials, (item) => findMaterial(item) || createMaterial(item));

          // Save selected materials
          props.onSave(items);

          // Close the modal
          onClose();
        }}
        renderItem={(material) => material.name}
        selectedItems={_.map(props.items, (item) => item.material)}
        title={props.t('MaterialsList.title')}
      />
    )}
  />
));

export default MaterialsList;
