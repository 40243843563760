// @flow

import type { User } from '@archnet/shared';
import { Toaster } from '@performant-software/semantic-components';
import { Element } from '@performant-software/shared-components';
import React, { Component, createRef, type Node } from 'react';
import { withRouter, RouterHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  Dimmer,
  Form,
  Grid,
  Loader,
  Menu,
  Message,
  Ref,
  Sticky,
  Label
} from 'semantic-ui-react';
import _ from 'underscore';
import i18n from '../i18n/I18n';
import ScrollViewContext from '../contexts/ScrollViewContext';
import './SimpleEditPage.css';

type Props = {
  children: Component<{}>,
  className: string,
  dirty: boolean,
  errors: Array<string>,
  history: typeof RouterHistory,
  item: {
    disabled: boolean,
    locked_user: User
  },
  loading: boolean,
  onSave: (item: any) => Promise<any>,
  saving: boolean,
  showLoading: boolean,
  stickyMenu: boolean
};

type State = {
  saved: boolean,
  showToaster: boolean,
  showUnsavedChanges: boolean,
  tab: ?string
};

class SimpleEditPage extends Component<Props, State> {
  static defaultProps: any;
  static Header: (p: any) => any;
  static Tab: (p: any) => any;

  contextRef: any;

  /**
   * Constructs a new SimpleEditPage component.
   *
   * @param props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      showToaster: false,
      showUnsavedChanges: true,
      tab: null,
      saved: false,
    };

    this.contextRef = createRef();
  }

  /**
   * Sets "saved" state from the passed state on the history object, then selects the first tab.
   */
  componentDidMount() {
    this.setState({ saved: this.props.history?.location?.state?.saved || false });
    this.onTabClick(_.first(Element.findByType(this.props.children, SimpleEditPage.Tab)));
  }

  /**
   * Displays toaster if new errors are added or record was saved.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: Props, prevState: State) {
    if ((!_.isEmpty(this.props.errors) && prevProps.errors !== this.props.errors)
      || (prevState.saved !== this.state.saved && this.state.saved === true)) {
      this.setState({ showToaster: true });
    }
  }

  /**
   * Returns the space-delimited class name(s).
   *
   * @returns {string}
   */
  getClassName(): string {
    const classNames = ['simple-edit-page'];

    if (this.props.className) {
      classNames.push(this.props.className);
    }

    return classNames.join(' ');
  }

  /**
   * Sets the current tab on the state.
   *
   * @param tab
   */
  onTabClick(tab: { key: string }) {
    this.setState({ tab: tab.key });
  }

  /**
   * Renders the SimpleEditPage component.
   *
   * @returns {*}
   */
  render(): Node {
    return (
      <Container
        as={Form}
        className={this.getClassName()}
        fluid
      >
        {this.renderLoading()}
        {this.renderSaving()}
        {this.renderErrorToaster()}
        {this.renderSavedToaster()}
        {this.renderPage()}
      </Container>
    );
  }

  /**
   * Renders the disabled message.
   *
   * @returns {null|*}
   */
  renderDisabledMessage(): Node {
    if (!this.props.item.disabled) {
      return null;
    }

    return (
      <Message
        content={i18n.t('SimpleEditPage.messages.locked.content', {
          name: this.props.item.locked_user && this.props.item.locked_user.full_name
        })}
        header={i18n.t('SimpleEditPage.messages.locked.header')}
        icon='lock'
        visible
        warning
      />
    );
  }

  /**
   * Renders the loading indicator.
   *
   * @returns {null|*}
   */
  renderLoading(): Node {
    if (!(this.props.showLoading && this.props.loading)) {
      return null;
    }

    return (
      <Dimmer
        active={this.props.loading}
        inverted
        verticalAlign='middle'
      >
        <Loader
          content={i18n.t('Common.messages.loading')}
        />
      </Dimmer>
    );
  }

  /**
   * Renders the menu for the passed collection of tabs.
   *
   * @param tabs
   *
   * @returns {null|*}
   */
  renderMenu(tabs: Array<{ key: string }>): Node {
    if (!tabs) {
      return null;
    }

    const menu = (
      <div
        className='menu-container'
      >
        {this.props.dirty && this.state.showUnsavedChanges && (
          <Message
            content={i18n.t('SimpleEditPage.messages.unsaved.content')}
            error
            header={i18n.t('SimpleEditPage.messages.unsaved.header')}
            icon='exclamation triangle'
            onDismiss={() => this.setState({ showUnsavedChanges: false })}
            visible
          />
        )}
        <ScrollViewContext.Consumer>
          {({ scrollToTop }) => (
            <Menu
              className='tabs-menu'
              pointing
              secondary
            >
              {_.map(tabs, (item) => (
                <Menu.Item
                  active={item.key === this.state.tab}
                  key={item.key}
                  name={item.props.name}
                  onClick={() => {
                    this.onTabClick(item);
                    scrollToTop();
                  }}
                  className='tab-container'
                >
                  {item.props.name}
                  {item.props.count && item.props.count > 0 ? (<Label color='blue'>{item.props.count}</Label>) : <div />}
                </Menu.Item>
              ))}
            </Menu>
          )}
        </ScrollViewContext.Consumer>
      </div>
    );

    if (this.props.stickyMenu) {
      return (
        <Sticky
          ref={this.contextRef}
        >
          {menu}
        </Sticky>
      );
    }

    return menu;
  }

  /**
   * Renders the current page.
   *
   * @returns {null|*}
   */
  renderPage(): Node {
    if (this.props.showLoading && this.props.loading) {
      return null;
    }

    const header = _.first(Element.findByType(this.props.children, SimpleEditPage.Header));
    const tabs = Element.findByType(this.props.children, SimpleEditPage.Tab);
    const tab = _.find(tabs, (t) => t.key === this.state.tab);

    return (
      <Ref
        innerRef={this.contextRef}
      >
        <Grid
          columns={2}
        >
          <Grid.Column
            style={{
              flexGrow: '1'
            }}
          >
            {this.renderDisabledMessage()}
            {this.renderMenu(tabs)}
            {tab && tab.props.children}
          </Grid.Column>
          <Grid.Column
            className='five wide computer four wide large screen four wide widescreen column'
          >
            <Sticky
              context={this.contextRef}
              offset={20}
            >
              <Card>
                {header && header.props && header.props.children}
                <Card.Content
                  extra
                >
                  <div className='ui two buttons'>
                    <Button
                      content={i18n.t('Common.buttons.save')}
                      disabled={this.props.saving || this.props.item.disabled}
                      onClick={this.props.onSave.bind(this)}
                      primary
                    />
                    <Button
                      content={i18n.t('Common.buttons.cancel')}
                      inverted
                      onClick={() => this.props.history.goBack()}
                      primary
                    />
                  </div>
                </Card.Content>
              </Card>
            </Sticky>
          </Grid.Column>
        </Grid>
      </Ref>
    );
  }

  /**
   * Renders the saving indicator.
   *
   * @returns {null|*}
   */
  renderSaving(): Node {
    if (!this.props.saving) {
      return null;
    }

    return (
      <Dimmer
        active={this.props.saving}
        inverted
        verticalAlign='middle'
      >
        <Loader
          content={i18n.t('Common.messages.saving')}
        />
      </Dimmer>
    );
  }

  /**
   * Renders the error toaster.
   *
   * @returns {null|*}
   */
  renderErrorToaster(): Node {
    if (!this.state.showToaster || !this.props.errors?.length) {
      return null;
    }

    return (
      <Toaster
        onDismiss={() => this.setState({ showToaster: false })}
        timeout={0}
        type={Toaster.MessageTypes.negative}
      >
        <Message.Header
          content={i18n.t('Common.messages.error.header')}
        />
        <Message.List
          items={this.props.errors}
        />
      </Toaster>
    );
  }

  /**
   * Renders the saved toaster.
   *
   * @returns {null|*}
   */
  renderSavedToaster(): Node {
    if (!this.state.showToaster || !this.state.saved) {
      return null;
    }

    return (
      <Toaster
        onDismiss={() => this.setState({
          showToaster: false,
          saved: false,
        })}
        type={Toaster.MessageTypes.positive}
      >
        <Message.Header
          content={i18n.t('Common.messages.save.header')}
        />
        <Message.Content
          content={i18n.t('Common.messages.save.content')}
        />
      </Toaster>
    );
  }
}

const Header = (props: any) => props.children;
Header.displayName = 'Header';

const Tab = (props: any) => props.children;
Tab.displayName = 'Tab';

// SimpleEditPage.Header = Header;
// SimpleEditPage.Tab = Tab;

const defaultProps = {
  className: undefined,
  showLoading: true,
  stickyMenu: true
};

// $FlowIgnore
const SimpleEditPageStatic = withRouter(Object.assign(SimpleEditPage, { defaultProps, Header, Tab }));
export default SimpleEditPageStatic;
