// @flow

import type { CollectionType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import CollectionTypeForm from './CollectionTypeForm';

type Props = EditContainerProps & Translateable & {
  item: CollectionType
};

const CollectionTypeModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='collection-type-modal'
    noValidate
    open
  >
    <Modal.Header
      content={props.item.id
        ? props.t('CollectionTypeModal.title.edit')
        : props.t('CollectionTypeModal.title.add')}
    />
    <Modal.Content>
      <CollectionTypeForm
        {...props}
        includeResource
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default CollectionTypeModal;
