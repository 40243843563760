// @flow

type Props = {
  string: string
};

const PlainText = (props: Props): string => {
  if (!props.string) return '';

  const container = document.createElement('span');
  container.innerHTML = props.string;
  return container.textContent;
};

export default PlainText;
