// @flow

import React, { type Node } from 'react';
import { Button } from 'semantic-ui-react';

type Props = {
  content: ?string,
  color?: string,
  fluid?: boolean
};

const Banner = (props: Props): Node => {
  if (!props.content) {
    return null;
  }

  return (
    <Button
      {...props}
    />
  );
};

Banner.defaultProps = {
  color: 'red',
  fluid: false
};

export default Banner;
