// @flow

import type { Option } from '../types/Option';
import CollectionDescriptions from './CollectionDescriptions';
import FormDataTransform from './FormDataTransform';
import Keywords from './Keywords';
import RecordAssociations from './RecordAssociations';
import RelatedCollections from './RelatedCollections';

import type { Collection as CollectionType } from '@archnet/shared';

/**
 * Class for handling transforming collection records.
 */
class Collection extends FormDataTransform {
  /**
   * Returns the collection parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'collection';
  }

  /**
   * Returns the collection payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'display_name',
      'flag_for_review',
      'published',
      'template',
      'description',
      'caption',
      'abstract',
      'locked'
    ];
  }

  /**
   * Returns the passed collection as a dropdown option.
   *
   * @param collection
   *
   * @returns {{text: *, value: *, key: *}}
   */
  toDropdown(collection: CollectionType): Option {
    return {
      key: collection.id,
      value: collection.id,
      text: collection.name
    };
  }

  /**
   * Returns the collection object to be sent to the server on POST/PUT requests.
   *
   * @param collection
   *
   * @returns {FormData}
   */
  toPayload(collection: CollectionType): any {
    const formData = super.toPayload(collection);

    CollectionDescriptions.appendFormData(formData, this.getParameterName(), collection);
    Keywords.appendFormData(formData, this.getParameterName(), collection, 'collections_keywords');
    RecordAssociations.appendFormData(formData, this.getParameterName(), collection, 'authority_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), collection, 'media_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), collection, 'publication_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), collection, 'site_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), collection, 'parent_collection_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), collection, 'child_collection_record_associations');
    RelatedCollections.appendFormData(formData, this.getParameterName(), collection, 'related_collections');

    return formData;
  }
}

export default (new Collection(): Collection);
