// @flow

import BaseService from './BaseService';
import CollectionType from '../transforms/CollectionType';

/**
 * Service class for all collection type based API calls.
 */
class CollectionTypes extends BaseService {
  /**
   * Returns the collection types base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/collection_types';
  }

  /**
   * Returns the collection types transform.
   *
   * @returns {CollectionType}
   */
  getTransform(): typeof CollectionType {
    return CollectionType;
  }
}

export default (new CollectionTypes(): CollectionTypes);
