// @flow

import type { Translateable } from '@archnet/shared';
import { LazyDocument, ListFilters, ViewPDFButton } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, RouterHistory } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import { ActionTypes, getActions } from '../actions/Actions';
import AdminItemList from '../components/AdminItemList';
import Authorities from '../actions/Authorities';
import BuildingUsages from '../actions/BuildingUsages';
import Collections from '../actions/Collections';
import Countries from '../services/Countries';
import Keywords from '../actions/Keywords';
import Languages from '../actions/Languages';
import Materials from '../actions/Materials';
import PrimaryImageView from '../components/PrimaryImageView';
import PublicationFilters from '../filters/PublicationFilters';
import PublicationsService from '../services/Publications';
import PublicationType from '../transforms/PublicationType';
import PublicationTypes from '../services/PublicationTypes';
import PublishedLabel from '../components/PublishedLabel';
import Sites from '../actions/Sites';
import './Publications.css';

type Props = Translateable & {
  history: typeof RouterHistory
};

const Publications: AbstractComponent<any> = withTranslation()(withRouter((props: Props) => (
  <AdminItemList
    className='publications'
    collectionName='publications'
    editActions={[{
      attributeName: 'publication_type_id',
      collectionName: 'publication_types',
      key: 'publication_types',
      onLoad: (params) => PublicationTypes.fetchAll({ ...params, per_page: 0, sort_by: 'name' }),
      renderOption: (publicationType) => PublicationType.toDropdown(publicationType),
      text: props.t('Publications.actions.publicationType'),
      type: ActionTypes.relationship,
      value: 'publication_types'
    }, {
      attributeName: 'year',
      key: 'year',
      text: props.t('Publications.actions.year'),
      type: ActionTypes.string,
      value: 'year'
    }, {
      attributeName: 'source_id',
      key: 'source_id',
      text: props.t('Publications.actions.sourceId'),
      type: ActionTypes.string,
      value: 'source_id'
    }, {
      attributeName: 'source',
      key: 'source',
      text: props.t('Publications.actions.source'),
      type: ActionTypes.text,
      value: 'source'
    }, {
      attributeName: 'copyright',
      key: 'copyright',
      text: props.t('Publications.actions.copyright'),
      type: ActionTypes.text,
      value: 'copyright'
    }, {
      attributeName: 'contributor',
      key: 'contributor',
      text: props.t('Publications.actions.contributor'),
      type: ActionTypes.string,
      value: 'contributor'
    }, {
      attributeName: 'dimensions',
      key: 'dimensions',
      text: props.t('Publications.actions.dimensions'),
      type: ActionTypes.string,
      value: 'dimensions'
    }, {
      associationColumn: 'country_id',
      associationName: 'countries_publications',
      collectionName: 'countries',
      key: 'countries',
      multiple: true,
      onLoad: (params) => Countries.fetchAll(params),
      renderOption: (country) => country.name,
      text: props.t('Publications.actions.countries'),
      type: ActionTypes.relationship,
      value: 'countries'
    }, {
      ...Languages,
      associationName: 'languages_publications',
    }, {
      ...Keywords,
      associationName: 'keywords_publications'
    }, {
      ...Materials,
      associationName: 'materials_publications'
    }, {
      ...BuildingUsages,
      associationName: 'building_usages_publications'
    }, {
      ...Authorities,
      parentType: 'Authority'
    }, {
      ...Collections,
      parentType: 'Collection'
    }, {
      ...Sites,
      parentType: 'Site'
    }, ...getActions()]}
    filters={{
      component: ListFilters,
      props: {
        filters: PublicationFilters
      }
    }}
    onBatchDelete={(ids) => PublicationsService.batchDelete(ids)}
    onBatchUpdate={(ids, params) => PublicationsService.batchUpdate(ids, params)}
    onDelete={(publication) => PublicationsService.delete(publication)}
    onLoad={(params) => PublicationsService.search(params)}
    renderDescription={(item) => item.publication_type && item.publication_type.name}
    renderExtra={(item) => <PublishedLabel icon published={item.published} />}
    renderHeader={(item) => <Header content={item.name} />}
    renderImage={(item) => (
      <PrimaryImageView
        item={item}
      >
        { item.content_type === 'application/pdf' && (
          <ViewPDFButton
            primary
            url={`/admin/display?source=${item.content_url}`}
          />
        )}
      </PrimaryImageView>
    )}
    renderMeta={(item) => item.record_id}
    route='publications'
    sort={
      [{
        key: 'name',
        value: 'name',
        text: props.t('Common.sort.name')
      }, {
        key: 'record_id',
        value: 'record_id',
        text: props.t('Common.sort.recordId')
      }, {
        key: 'created_at',
        value: 'created_at',
        text: props.t('Common.sort.createdAt')
      }, {
        key: 'updated_at',
        value: 'updated_at',
        text: props.t('Common.sort.updatedAt')
      }, {
        key: 'date_published',
        value: 'date_published',
        text: props.t('Common.sort.datePublished')
      }]}
  />
)));

export default Publications;
