// @flow

import BaseService from './BaseService';
import EventType from '../transforms/EventType';

/**
 * Service class for all event_type based API calls.
 */
class EventTypes extends BaseService {
  /**
   * Returns the event types base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/event_types';
  }

  /**
   * Returns the event types transform.
   *
   * @returns {EventType}
   */
  getTransform(): typeof EventType {
    return EventType;
  }
}

export default (new EventTypes(): EventTypes);
