// @flow

import BaseService from './BaseService';
import PublicationType from '../transforms/PublicationType';

/**
 * Service class for all publication types based API calls.
 */
class PublicationTypes extends BaseService {
  /**
   * Returns the publication types base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/publication_types';
  }

  /**
   * Returns the publication types transform.
   *
   * @returns {PublicationType}
   */
  getTransform(): typeof PublicationType {
    return PublicationType;
  }
}

export default (new PublicationTypes(): PublicationTypes);
