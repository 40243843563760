// @flow

import BaseService from './BaseService';
import Keyword from '../transforms/Keyword';
import type { MergeRequest } from '../components/MergeKeywordsModal';
import axios from 'axios';

/**
 * Service class for all keyword based API calls.
 */
class Keywords extends BaseService {
  /**
   * Returns the keywords base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/keywords';
  }

  /**
   * Returns the keywords transform.
   *
   * @returns {Keyword}
   */
  getTransform(): typeof Keyword {
    return Keyword;
  }

  mergeRequest(merge: MergeRequest): Promise<any> {
    return axios.post(
      `${this.getBaseUrl()}/merge`,
      { merge },
      this.getConfig()
    );
  }
}

export default (new Keywords(): Keywords);
