// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming admin_note records.
 */
class AdminNotes extends NestedAttributes {
  PARAM_NAME: string = 'admin_notes';

  /**
   * Returns the admin notes payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'title',
      'note',
      '_destroy'
    ];
  }

  /**
   * Overrides the appendFormData function and defaults the collection name.
   *
   * @param formData
   * @param prefix
   * @param record
   * @param collection
   */
  appendFormData(formData: FormData, prefix: string, record: any, collection: string = this.PARAM_NAME): void {
    super.appendFormData(formData, prefix, record, collection);
  }

  /**
   * Overrides the toPayload function and defaults the collection name.
   *
   * @param record
   * @param collection
   * @returns {{[p: string]: *}}
   */
  toPayload(record: any, collection: string = this.PARAM_NAME): any {
    return super.toPayload(record, collection);
  }
}

export default (new AdminNotes(): AdminNotes);
