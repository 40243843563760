// @flow

import { DropdownButton, ItemList, Toaster } from '@performant-software/semantic-components';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type AbstractComponent,
  type ComponentType,
  type Element
} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, Location, RouterHistory } from 'react-router-dom';
import {
  Button,
  Confirm,
  Header,
  Icon,
  Message
} from 'semantic-ui-react';
import _ from 'underscore';
import BatchUpdateModal from './BatchUpdateModal';
import ScrollViewContext from '../contexts/ScrollViewContext';
import Session from '../services/Session';
import './AdminItemList.css';

import type { Translateable } from '@archnet/shared';
import type { Filter } from '../types/Filter';

type EditAction = {
  associationColumn?: string,
  associationName: string,
  collectionName?: string,
  onLoad?: (params: any) => Promise<any>,
  renderOption: (item: any) => Element<any>,
  key: string,
  value: string,
  text: string,
  type: string,
  multiple?: boolean
};

type Item = {
  id: number
};

type ListButton = {
  ...typeof Button,
  accept?: () => boolean
};

type Props = Translateable & {
  addButton?: {
    color?: string,
    location?: string
  },
  buttons?: Array<ListButton>,
  className?: string,
  collectionName: string,
  editActions?: Array<EditAction>,
  filters?: {
    component: ComponentType<any>,
    props: {
      filters: Array<Filter>
    }
  },
  location: typeof Location,
  history: typeof RouterHistory,
  onBatchDelete: (ids: Array<number>) => Promise<any>,
  onBatchUpdate: (ids: Array<number>, params: any) => Promise<any>,
  onLoad: (params: any) => Promise<any>,
  route: string
};

const AdminItemList = (props: Props) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [editAction, setEditAction] = useState(null);
  const [error, setError] = useState(false);
  const [nbHits, setNbHits] = useState(0);
  const [saving, setSaving] = useState(false);
  const [selectable, setSelectable] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Array<any>>([]);

  const { saved } = props.history.location.state || false;

  /*
   * Reset the location state so we don't continue to get the "saved" message when coming back to the list.
   */
  useEffect(() => {
    props.history.replace({ state: _.without(props.location.state, 'saved') });
  }, [selectable]);

  /**
   * Returns the class names for the AdminItemList component.
   *
   * @returns {string}
   */
  const className = useMemo(() => {
    const classNames = ['admin-item-list'];

    if (props.className) {
      classNames.push(props.className);
    }

    return classNames.join(' ');
  }, [props.className]);

  /**
   * Returns the list of sorted actions.
   *
   * @type {*|any[]}
   */
  const editActions = useMemo(() => _.sortBy(props.editActions, (action) => action.text), [props.editActions]);

  /**
   * Returns true if the passed item is contained in the collection of selected items.
   *
   * @type {function(*): *}
   */
  const isRowSelected = useCallback((item: Item) => _.contains(selectedItems, item.id), [selectedItems]);

  /**
   * Sets the saving state to "true" and calls the onBatchDelete prop.
   *
   * @type {function(): *}
   */
  const onDelete = useCallback(() => {
    setSaving(true);

    return props
      .onBatchDelete(selectedItems)
      .catch(() => setError(true))
      .finally(() => onReset());
  }, [selectedItems]);

  /**
   * Resets the component state.
   *
   * @type {(function(): void)|*}
   */
  const onReset = useCallback(() => {
    setConfirmDelete(false);
    setEditAction(null);
    setSaving(false);
    setSelectable(false);
    setSelectedItems([]);

    props.history.replace({ state: { saved: true } });
  }, []);

  /**
   * Toggles the passed row as selected.
   *
   * @type {(function(*=): void)|*}
   */
  const onRowSelect = useCallback((item: Item) => {
    if (isRowSelected(item)) {
      setSelectedItems((prevItems) => _.filter(prevItems, (id) => id !== item.id));
    } else {
      setSelectedItems((prevItems) => [...prevItems, item.id]);
    }
  }, [isRowSelected]);

  /**
   * Sets the saving state to "true" and calls the onBatchUpdate prop.
   *
   * @type {function(*=): *}
   */
  const onSave = useCallback((params: any) => {
    setSaving(true);

    return props
      .onBatchUpdate(selectedItems, {
        ...editAction,
        ...params
      })
      .catch(() => setError(true))
      .finally(() => onReset());
  }, [editAction, onReset, selectedItems, props.onBatchUpdate]);

  /**
   * Renders the edit button actions.
   *
   * @type {(function(): (null|*))|*}
   */
  const renderActionsButton = useCallback(() => {
    if (!(selectable && props.editActions && props.onBatchUpdate)) {
      return null;
    }

    return (
      <DropdownButton
        color='blue'
        direction='right'
        disabled={_.isEmpty(selectedItems)}
        icon='edit outline'
        onChange={(e, { value }) => setEditAction(_.findWhere(props.editActions, { value }))}
        options={editActions}
        scrolling
        text={props.t('Common.buttons.edit')}
        value={editAction}
      />
    );
  }, [editActions, selectable, selectedItems]);

  /**
   * Renders the total number of results.
   */
  const renderResultsCount = useCallback(() => (
    <p>{ props.t('AdminItemList.results.itemsFound', { count: nbHits.toLocaleString() }) }</p>
  ), [nbHits]);

  return (
    <ScrollViewContext.Consumer>
      { ({ scrollToTop }) => (
        <>
          <ItemList
            {...props}
            className={className}
            actions={[{
              name: 'edit',
              onClick: (item) => props.history.push(`/admin/${props.route}/${item.id}`)
            }, {
              name: 'delete'
            }]}
            addButton={_.defaults(props.addButton, {
              basic: false,
              color: 'green',
              location: 'top',
              onClick: () => props.history.push(`/admin/${props.route}/new`)
            })}
            buttons={[...props.buttons || [], {
              accept: () => !selectable && props.editActions,
              content: props.t('Common.buttons.select'),
              icon: 'tasks',
              primary: true,
              onClick: () => setSelectable(true)
            }, {
              render: renderActionsButton
            }, {
              accept: () => selectable && props.editActions && props.onBatchDelete,
              color: 'red',
              content: props.t('Common.buttons.delete'),
              disabled: _.isEmpty(selectedItems),
              icon: 'trash',
              onClick: () => setConfirmDelete(true)
            }, {
              accept: () => selectable && props.editActions,
              color: 'red',
              content: props.t('Common.buttons.cancel'),
              icon: 'times circle outline',
              inverted: true,
              onClick: () => {
                setSelectable(false);
                setSelectedItems([]);
              }
            }, {
              render: renderResultsCount
            }]}
            filters={props.filters}
            isRowSelected={isRowSelected}
            onLoad={(params) => {
              scrollToTop();

              const results = props
                .onLoad(params)
                .then((response) => {
                  setNbHits(response.data.list.count);
                  return response;
                });

              return results;
            }}
            onRowSelect={onRowSelect}
            perPageOptions={[10, 25, 50, 100]}
            renderEmptyList={() => null}
            saved={saved}
            selectable={selectable}
            session={{
              key: props.collectionName,
              storage: Session.storage
            }}
          />
          { editAction && (
            <BatchUpdateModal
              action={editAction}
              onClose={() => setEditAction(null)}
              onSave={onSave}
              recordCount={_.size(selectedItems)}
              saving={saving}
            />
          )}
          { confirmDelete && (
            <Confirm
              cancelButton={props.t('Common.buttons.no')}
              centered={false}
              content={props.t('AdminItemList.batchDelete.content', { count: selectedItems.length })}
              confirmButton={props.t('Common.buttons.yes')}
              header={(
                <Header
                  as='h2'
                >
                  <Icon
                    name='trash alternate outline'
                  />
                  <Header.Content>
                    { props.t('AdminItemList.batchDelete.header') }
                  </Header.Content>
                </Header>
              )}
              onCancel={() => setConfirmDelete(false)}
              onConfirm={onDelete}
              open
            />
          )}
          { error && (
            <Toaster
              onDismiss={() => setError(false)}
              timeout={0}
              type={Toaster.MessageTypes.negative}
            >
              <Message.Header
                content={props.t('Common.messages.error.header')}
              />
              <Message.Content
                content={props.t('Common.messages.error.batchUpdate')}
              />
            </Toaster>
          )}
        </>
      )}
    </ScrollViewContext.Consumer>
  );
};

AdminItemList.defaultProps = {
  addButton: undefined,
  className: undefined,
  filters: undefined
};

export default (withTranslation()(withRouter(AdminItemList)): AbstractComponent<any>);
