// @flow

import type { CollectionType, Translateable } from '@archnet/shared';
import { ColorButton, ColorPickerModal } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { useState, type Node } from 'react';
import { Form } from 'semantic-ui-react';

type Props = EditContainerProps & Translateable & {
  includeResource?: boolean,
  item: CollectionType
};

const CollectionTypeForm = (props: Props): Node => {
  const [modalColor, setModalColor] = useState(false);
  const [modalTitleColor, setModalTitleColor] = useState(false);

  return (
    <>
      <Form.Input
        error={props.isError('name')}
        label={props.t('CollectionType.labels.name')}
        required={props.isRequired('name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('order')}
        label={props.t('CollectionType.labels.order')}
        required={props.isRequired('order')}
        onChange={props.onTextInputChange.bind(this, 'order')}
        value={props.item.order || ''}
      />
      { props.includeResource && (
        <Form.Checkbox
          checked={props.item.is_resource}
          error={props.isError('is_resource')}
          label={props.t('CollectionType.labels.resource')}
          onChange={props.onCheckboxInputChange.bind(this, 'is_resource')}
          required={props.isRequired('is_resource')}
        />
      )}
      <Form.Input
        error={props.isError('color')}
        label={props.t('CollectionType.labels.color')}
        required={props.isRequired('color')}
      >
        <ColorButton
          color={props.item.color}
          onClick={() => setModalColor(true)}
        />
        <ColorPickerModal
          color={props.item.color}
          onClose={() => setModalColor(false)}
          onSave={({ hex }) => {
            props.onSetState({ color: hex });
            setModalColor(false);
          }}
          open={modalColor}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('title_color')}
        label={props.t('CollectionType.labels.titleColor')}
        required={props.isRequired('title_color')}
      >
        <ColorButton
          color={props.item.title_color}
          onClick={() => setModalTitleColor(true)}
        />
        <ColorPickerModal
          color={props.item.title_color}
          onClose={() => setModalTitleColor(false)}
          onSave={({ hex }) => {
            props.onSetState({ title_color: hex });
            setModalTitleColor(false);
          }}
          open={modalTitleColor}
        />
      </Form.Input>
    </>
  );
};

CollectionTypeForm.defaultProps = {
  includeResource: false
};

export default CollectionTypeForm;
