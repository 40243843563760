// @flow

import type { CollectionType as CollectionTypeType } from '@archnet/shared';
import FormDataTransform from './FormDataTransform';
import type { Option } from '../types/Option';
import RecordAssociations from './RecordAssociations';

/**
 * Class for handling transforming collection type records.
 */
class CollectionType extends FormDataTransform {
  /**
   * Returns the collection type parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'collection_type';
  }

  /**
   * Returns the array of collection type payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'show',
      'is_resource',
      'color',
      'title_color'
    ];
  }

  /**
   * Returns the passed collection type as a dropdown option.
   *
   * @param collectionType
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(collectionType: CollectionTypeType): Option {
    return {
      key: collectionType.id,
      value: collectionType.id,
      text: collectionType.name
    };
  }

  /**
   * Returns the collection type object to be sent to the server on POST/PUT requests.
   *
   * @param collectionType
   *
   * @returns {FormData}
   */
  toPayload(collectionType: CollectionTypeType): any {
    const formData = super.toPayload(collectionType);

    RecordAssociations.appendFormData(formData, this.getParameterName(), collectionType, 'media_record_associations');

    return formData;
  }
}

export default (new CollectionType(): CollectionType);
