// @flow

import AuthorityNameType from '../transforms/AuthorityNameType';
import BaseService from './BaseService';

/**
 * Service class for all authority_name_type based API calls.
 */
class AuthorityNameTypes extends BaseService {
  /**
   * Returns the authority_name_types base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/authority_name_types';
  }

  /**
   * Returns the authority_name_types transform.
   *
   * @returns {AuthorityNameType}
   */
  getTransform(): typeof AuthorityNameType {
    return AuthorityNameType;
  }
}

export default (new AuthorityNameTypes(): AuthorityNameTypes);
