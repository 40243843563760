// @flow

import _ from 'underscore';
import type { Site } from '../types/Site';

/**
 * Codes for countries that use the Imperial system of measurement.
 *
 * @type {string[]}
 */
const COUNTRY_CODES = [
  'LR',
  'MM',
  'US'
];

const MILES_TO_KILOMETERS = 1.609344;

const UNIT_MILES = 'mi';
const UNIT_KILOMETERS = 'km';

/**
 * Returns the formatted address string for the passed site.
 *
 * @param site
 *
 * @returns {string}
 */
const formatAddress = (site: Site): string => {
  const address = [];

  if (site.address_street) {
    address.push(site.address_street);
  }

  const location = formatLocation(site);

  if (location) {
    address.push(location);
  }

  return address.join(', ');
};

/**
 * Formats the site distance based on the passed locale. We assume that the distance will always be stored in miles,
 * so that will be the default unit if no locale is passed.
 *
 * @param site
 * @param locale
 *
 * @returns {string|null}
 */
const formatDistance = (site: Site, locale: string): ?string => {
  if (!site.distance) {
    return null;
  }

  let { distance } = site;
  let units = UNIT_MILES;

  const countryCode = getCountryCode(locale);
  if (countryCode && !_.contains(COUNTRY_CODES, countryCode)) {
    distance *= MILES_TO_KILOMETERS;
    units = UNIT_KILOMETERS;
  }

  return `${distance.toFixed(1)} ${units}`;
};

/**
 * Returns the formatted location for the passed site.
 *
 * @param site
 *
 * @returns {string}
 */
const formatLocation = (site: any): string => {
  const location = [];

  if (site.place_name) {
    location.push(site.place_name);
  }

  if (site.country && site.country.name) {
    location.push(site.country.name);
  }

  return location.join(', ');
};

/**
 * Returns the country code for the passed locale string.
 *
 * @param locale
 *
 * @returns {*}
 */
const getCountryCode = (locale: string): ?string => {
  let countryCode;

  if (!_.isEmpty(locale)) {
    const locales = locale.split('-');
    if (locales && locales.length === 2) {
      [, countryCode] = locales;
    }
  }

  return countryCode;
};

const getGoogleMapsUrl = (site: Site): string => (
  `https://www.google.com/maps/search/?api=1&query=${site.latitude},${site.longitude}`
);

/**
 * Returns the primary name for the passed site.
 *
 * @param site
 *
 * @returns {*}
 */
const getPrimaryName = (site: any): string => {
  const primary = _.find(site.site_names, (sn) => sn.primary && !sn._destroy);
  return primary && primary.name;
};

export default {
  formatAddress,
  formatDistance,
  formatLocation,
  getGoogleMapsUrl,
  getPrimaryName,
};
