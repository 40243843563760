// @flow

import type { Option } from '../types/Option';
import AdminNotes from './AdminNotes';
import AuthorityNames from './AuthorityNames';
import AuthoritySources from './AuthoritySources';
import FormDataTransform from './FormDataTransform';
import RecordAssociations from './RecordAssociations';
import RelatedAuthorities from './RelatedAuthorities';

import type { Authority as AuthorityType } from '@archnet/shared';

/**
 * Class for handling transforming authority records.
 */
class Authority extends FormDataTransform {
  /**
   * Returns the authority parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'authority';
  }

  /**
   * Returns the authority payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'biography',
      'published',
      'hcp_item',
      'award_winner',
      'flag_for_review',
      'locked'
    ];
  }

  /**
   * Returns the passed authority as a dropdown option.
   *
   * @param authority
   *
   * @returns {{description: string, text: string, value: number, key: number}}
   */
  toDropdown(authority: AuthorityType): Option {
    return {
      key: authority.id,
      value: authority.id,
      text: authority.name,
      description: authority.record_id
    };
  }

  /**
   * Returns the authority object to be sent to the server on POST/PUT requests.
   *
   * @param authority
   *
   * @returns {*}
   */
  toPayload(authority: AuthorityType): any {
    const formData = super.toPayload(authority);

    AdminNotes.appendFormData(formData, this.getParameterName(), authority);
    AuthorityNames.appendFormData(formData, this.getParameterName(), authority);
    AuthoritySources.appendFormData(formData, this.getParameterName(), authority);
    RecordAssociations.appendFormData(formData, this.getParameterName(), authority, 'collection_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), authority, 'media_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), authority, 'publication_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), authority, 'site_record_associations');
    RelatedAuthorities.appendFormData(formData, this.getParameterName(), authority);

    return formData;
  }
}

export default (new Authority(): Authority);
