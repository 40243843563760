// @flow

import { ListTable } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withRouter, Location, RouterHistory } from 'react-router-dom';
import ScrollViewContext from '../contexts/ScrollViewContext';
import Session from '../services/Session';

type Props = {
  collectionName: string,
  location: typeof Location,
  history: typeof RouterHistory,
  onLoad: (params: any) => Promise<any>,
  route: string,
};

const AdminListTable: AbstractComponent<any> = withRouter((props: Props) => {
  const { saved } = props.location.state || false;

  return (
    <ScrollViewContext.Consumer>
      { ({ scrollToTop }) => (
        <ListTable
          {...props}
          actions={[{
            name: 'edit',
            onClick: (item) => props.history.push(`/admin/${props.route}/${item.id}`)
          }, {
            name: 'copy',
            onClick: (item) => props.history.push(`/admin/${props.route}/${item.id}`)
          }, {
            name: 'delete'
          }]}
          addButton={{
            color: 'green',
            location: 'top',
            onClick: () => props.history.push(`/admin/${props.route}/new`)
          }}
          onLoad={(params) => {
            scrollToTop();
            return props.onLoad(params);
          }}
          saved={saved}
          session={{
            key: props.collectionName,
            storage: Session.storage
          }}
        />
      )}
    </ScrollViewContext.Consumer>
  );
});

export default AdminListTable;
