// @flow

import { type Keyword } from '@archnet/shared';
import i18n from '../i18n/I18n';
import { type Action } from '../types/Action';
import KeywordsService from '../services/Keywords';

const Keywords: Action = {
  associationColumn: 'keyword_id',
  collectionName: 'keywords',
  key: 'keywords',
  multiple: true,
  onLoad: (params: any) => KeywordsService.fetchAll(params),
  renderOption: (keyword: Keyword) => keyword.name,
  text: i18n.t('Common.actions.keywords'),
  type: 'relationship',
  value: 'keywords'
};

export default Keywords;
