// @flow

import BaseService from './BaseService';
import RecordAssociationsTransform from '../transforms/RecordAssociations';
import type { RecordAssociation } from '@archnet/shared';
import axios from 'axios';

/**
 * Service class for all record association based API calls.
 */
class RecordAssociations extends BaseService {
  /**
   * Returns the record associations base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/record_associations';
  }

  /**
   * Returns the record associations transform.
   *
   * @returns {{}}
   */
  getTransform(): any {
    return {};
  }
}

export default (new RecordAssociations(): RecordAssociations);
