// @flow

import type { CollectionDescription, CollectionType, Translateable } from '@archnet/shared';
import { Selectize } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Item } from 'semantic-ui-react';
import _ from 'underscore';
import CollectionTypeModal from './CollectionTypeModal';
import CollectionTypes from '../services/CollectionTypes';
import PrimaryImageView from './PrimaryImageView';
import SimpleTagsList from './SimpleTagsList';

type Props = Translateable & {
  items: Array<CollectionDescription>,
  onDelete: (item: CollectionDescription) => void,
  onSave: (items: Array<CollectionDescription>) => void
};

const CollectionTypesList: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleTagsList
    items={_.filter(props.items, (item) => !item._destroy)}
    onDelete={props.onDelete.bind(this)}
    renderItem={(item) => item.collection_type.name}
    renderModal={({ onClose }) => (
      <Selectize
        collectionName='collection_types'
        modal={{
          component: CollectionTypeModal,
          onSave: (collectionType) => (
            CollectionTypes
              .save(collectionType)
              .then(({ data }) => data.collection_type)
          )
        }}
        onClose={onClose}
        onLoad={(params) => CollectionTypes.fetchAll({ ...params, sort_by: 'name', per_page: 5 })}
        onSave={(collectionTypes) => {
          const findCollectionType = (item: CollectionType) => (
            _.findWhere(props.items, { collection_type_id: item.id })
          );

          const createCollectionType = (item: CollectionType) => ({
            uid: uuid(),
            collection_type_id: item.id,
            collection_type: item
          });

          // Create or find items
          const items = _.map(collectionTypes, (keyword) => (
            findCollectionType(keyword) || createCollectionType(keyword)
          ));

          // Save selected collection types
          props.onSave(items);

          // Close the modal
          onClose();
        }}
        renderItem={(collectionType) => (
          <Item.Group>
            <Item>
              <PrimaryImageView
                dimmable={false}
                item={collectionType}
                size='tiny'
              />
              <Item.Content
                header={collectionType.name}
              />
            </Item>
          </Item.Group>
        )}
        selectedItems={_.map(props.items, (item) => item.collection_type)}
        title={props.t('CollectionTypesList.title')}
      />
    )}
  />
));

export default CollectionTypesList;
