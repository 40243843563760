// @flow

import BaseService from './BaseService';
import BuildingType from '../transforms/BuildingType';

/**
 * Service class for all building_type based API calls.
 */
class BuildingTypes extends BaseService {
  /**
   * Returns the building_types base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/building_types';
  }

  /**
   * Returns the building_types transform.
   *
   * @returns {BuildingType}
   */
  getTransform(): typeof BuildingType {
    return BuildingType;
  }
}

export default (new BuildingTypes(): BuildingTypes);
