// @flow

import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import './DisplayDocument.css'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DisplayDocument = () => {
  const query = useQuery();
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);

  const current = `${page}`;

  const handlePDFLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <div class="button-bar">
        <div class="ui buttons">
          <button class="ui button page-button" disabled={page === 1} onClick={() => setPage(page - 1)}>previous</button>
          <div class="or" data-text={current}></div>
          <button class="ui positive button page-button" disabled={page === numPages} onClick={() => setPage(page + 1)}>next</button>
        </div>
      </div>
      <Document
        file={query.get('source')}
        onLoadError={(e) => console.log(e.message)}
        onLoadSuccess={handlePDFLoadSuccess}
      >
        <Page
          pageNumber={page}
          renderAnnotationLayer={false}
        />
      </Document>
    </div >
  )
}

export default DisplayDocument;