// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming nested keywords records.
 */
class Keywords extends NestedAttributes {
  /**
   * Returns the keywords payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'keyword_id',
      '_destroy'
    ];
  }
}

export default (new Keywords(): Keywords);
