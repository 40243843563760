// @flow

import type { Donor } from '@archnet/shared';
import React, { type Node } from 'react';
import ImageLabel from './ImageLabel';

type Props = {
  color?: string,
  donor: Donor
};

const DonorLabel = (props: Props): Node => (
  <ImageLabel
    color={props.color}
    icon='user'
    url={`/admin/donors/${props.donor.id}`}
    value={props.donor.name}
  />
);

DonorLabel.defaultProps = {
  color: 'blue'
};

export default DonorLabel;
