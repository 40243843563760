// @flow

import { ItemUtils, type Collection as CollectionType } from '@archnet/shared';
import React from 'react';
import { Item } from 'semantic-ui-react';
import { type Action } from '../types/Action';
import CollectionFilters from '../filters/CollectionFilters';
import CollectionsService from '../services/Collections';
import i18n from '../i18n/I18n';
import PrimaryImageView from '../components/PrimaryImageView';
import SelectizeHeader from '../components/SelectizeHeader';

const Collections: Action = {
  collectionName: 'collections',
  filters: CollectionFilters,
  key: 'collections',
  multiple: true,
  onLoad: (params: any) => CollectionsService.fetchAll({ ...params, per_page: 5 }),
  renderHeader: (props: any) => (
    <SelectizeHeader
      {...props}
      type='Collection'
    />
  ),
  renderOption: (collection: CollectionType) => {
    const attributes = ItemUtils.getViewAttributes(collection, 'Collection');

    return (
      <Item.Group>
        <Item>
          <Item.Image
            style={{
              width: 'unset'
            }}
          >
            <PrimaryImageView
              dimmable={false}
              item={collection}
              size='tiny'
            />
          </Item.Image>
          <Item.Content>
            <Item.Header
              content={attributes.header}
            />
            <Item.Meta
              content={attributes.meta}
            />
          </Item.Content>
        </Item>
      </Item.Group>
    );
  },
  renderSelected: (items: Array<CollectionType>) => (
    <SelectizeHeader
      collapsable={false}
      perPage={3}
      selectedItems={items}
      type='Collection'
    />
  ),
  text: i18n.t('Common.actions.collections'),
  type: 'relationship',
  value: 'collections'
};

export default Collections;
