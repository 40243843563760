// @flow

import { useDragDrop as createDragDrop } from '@performant-software/shared-components';
import React, { type ComponentType } from 'react';
import { Router, Switch } from 'react-router-dom';
import Admin from './pages/Admin';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import history from './config/History';
import Login from './pages/Login';
import MediaContext from './components/MediaContext';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import './App.css';

const App = () => (
  <Router
    history={history}
  >
    <MediaContext>
      <Switch>
        <AuthenticatedRoute
          path='/admin'
          component={Admin}
        />
        <UnauthenticatedRoute
          path='/'
          component={Login}
        />
      </Switch>
    </MediaContext>
  </Router>
);

export default (createDragDrop(App): ComponentType<any>);
