// @flow

import type { Collection, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import CollectionTypesList from './CollectionTypesList';

type Props = Translateable & {
  ...EditContainerProps,
  item: Collection
};

const CollectionModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='collection-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('CollectionModal.title.edit')
        : props.t('CollectionModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('name')}
        label={props.t('CollectionModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('display_name')}
        label={props.t('CollectionModal.labels.displayName')}
        onChange={props.onTextInputChange.bind(this, 'display_name')}
        required={props.isRequired('display_name')}
        value={props.item.display_name || ''}
      />
      <Form.Input
        label={props.t('CollectionModal.labels.categories')}
      >
        <CollectionTypesList
          items={props.item.collection_descriptions}
          onDelete={props.onDeleteChildAssociation.bind(this, 'collection_descriptions')}
          onSave={props.onMultiAddChildAssociations.bind(this, 'collection_descriptions')}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
));

export default CollectionModal;
