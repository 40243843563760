// @flow

import BaseService from './BaseService';
import Country from '../transforms/Country';

/**
 * Service class for all country based API calls.
 */
class Countries extends BaseService {
  /**
   * Returns the countries base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/countries';
  }

  /**
   * Returns the countries transform.
   *
   * @returns {Country}
   */
  getTransform(): typeof Country {
    return Country;
  }
}

export default (new Countries(): Countries);
