// @flow

import _ from 'underscore';
import BaseTransform from './BaseTransform';

import type { CustomPage as CustomPageType } from '@archnet/shared';

/**
 * Class for handling custom page records.
 */
class CustomPage extends BaseTransform {
  /**
   * Returns the custom page payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'title',
      'url',
      'content',
      'published',
      'order',
      'footer'
    ];
  }

  /**
   * Returns the passed custom page for POST/PUT requests.
   *
   * @param customPage
   *
   * @returns {{custom_page}}
   */
  toPayload(customPage: CustomPageType): any {
    return {
      custom_page: _.pick(customPage, this.getPayloadKeys())
    };
  }
}

export default (new CustomPage(): CustomPage);
