// @flow

import type { Country as CountryType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import _ from 'underscore';
import Countries from '../services/Countries';
import GeographyMap from '../components/GeographyMap';
import SimpleEditPage from './SimpleEditPage';

type Props = EditContainerProps & Translateable & {
  item: CountryType
};

const Tabs = {
  details: 'details'
};

const Country: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
  >
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <Grid
        columns={2}
      >
        <Grid.Column>
          <Form.Input
            error={props.isError('name')}
            label={props.t('Country.labels.name')}
            onChange={props.onTextInputChange.bind(this, 'name')}
            required={props.isRequired('name')}
            value={props.item.name || ''}
          />
          <Form.Input
            error={props.isError('un_code')}
            label={props.t('Country.labels.code')}
            onChange={props.onTextInputChange.bind(this, 'un_code')}
            required={props.isRequired('un_code')}
            value={props.item.un_code || ''}
          />
          <Form.Input
            error={props.isError('un_code_long')}
            label={props.t('Country.labels.codeLong')}
            onChange={props.onTextInputChange.bind(this, 'un_code_long')}
            required={props.isRequired('un_code_long')}
            value={props.item.un_code_long || ''}
          />
          <Form.Input
            error={props.isError('region')}
            label={props.t('Country.labels.region')}
            onChange={props.onTextInputChange.bind(this, 'region')}
            required={props.isRequired('region')}
            value={props.item.region || ''}
          />
          <Form.Input
            error={props.isError('continent')}
            label={props.t('Country.labels.continent')}
            onChange={props.onTextInputChange.bind(this, 'continent')}
            required={props.isRequired('continent')}
            value={props.item.continent || ''}
          />
          <Form.Input
            error={props.isError('latitude')}
            label={props.t('Country.labels.latitude')}
            onChange={props.onTextInputChange.bind(this, 'latitude')}
            required={props.isRequired('latitude')}
            value={props.item.latitude || ''}
          />
          <Form.Input
            error={props.isError('longitude')}
            label={props.t('Country.labels.longitude')}
            onChange={props.onTextInputChange.bind(this, 'longitude')}
            required={props.isRequired('longitude')}
            value={props.item.longitude || ''}
          />
        </Grid.Column>
        <Grid.Column>
          <GeographyMap
            address={props.item.name}
            latitude={props.item.latitude}
            longitude={props.item.longitude}
            onAddressSuccess={(response) => {
              const { lat, lng } = response.results[0].geometry.location;
              props.onSetState({ latitude: lat, longitude: lng });
            }}
            onCoordinatesSuccess={(response) => {
              const result = _.first(response.results);
              const country = _.find(result.address_components, (ac) => _.includes(ac.types, 'country'));

              if (country) {
                props.onSetState({ name: country.long_name });
              }
            }}
            onMarkerDrag={({ lat, lng }) => props.onSetState({ latitude: lat, longitude: lng })}
          />
        </Grid.Column>
      </Grid>
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: Country,
  onInitialize: (id: number): Promise<any> => (
    Countries
      .fetchOne(id)
      .then(({ data }) => data.country)
  ),
  onSave: (country: CountryType): Promise<any> => (
    Countries
      .save(country)
      .then(({ data }) => data.country)
  ),
  required: ['name', 'un_code', 'un_code_long']
};
