// @flow

import type { AuthorityNameType as AuthorityNameTypeType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming authority name type records.
 */
class AuthorityNameType {
  PAYLOAD_KEYS: Array<string> = [
    'name'
  ];

  /**
   * Returns the passed authority name type as a dropdown option.
   *
   * @param authorityNameType
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(authorityNameType: AuthorityNameTypeType): Option {
    return {
      key: authorityNameType.id,
      value: authorityNameType.id,
      text: authorityNameType.name
    };
  }
}

export default (new AuthorityNameType(): AuthorityNameType);
