// @flow

import type {Translateable} from '@archnet/shared';
import React, {type AbstractComponent} from 'react';
import {withTranslation} from 'react-i18next';
import _ from 'underscore';
import AdminListTable from '../components/AdminListTable';
import Roles from '../resources/users/Roles.json';
import UsersService from '../services/Users';

const HomePages: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='users'
    collectionName='users'
    searchable
    columns={[{
      name: 'lastname',
      label: props.t('Users.columns.lastName'),
      sortable: true
    }, {
      name: 'firstname',
      label: props.t('Users.columns.firstName'),
      sortable: true
    }, {
      name: 'email',
      label: props.t('Users.columns.email'),
      sortable: true
    }, {
      name: 'login',
      label: props.t('Users.columns.login'),
      sortable: true
    }, {
      name: 'role',
      label: props.t('Users.columns.role'),
      resolve: (user) => (_.findWhere(Roles, {value: user.role}) || {}).text,
      sortable: true
    }]}
    onDelete={(user) => UsersService.delete(user)}
    onLoad={(params) => UsersService.fetchAll(params)}
    route='users'
  />
));

export default HomePages;
