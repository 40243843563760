// @flow

import type {Translateable} from '@archnet/shared';
import React, {type AbstractComponent} from 'react';
import {withTranslation} from 'react-i18next';
import AdminListTable from '../components/AdminListTable';
import EventTypesService from '../services/EventTypes';

const EventTypes: AbstractComponent<any> = withTranslation()((props: Translateable) => (
  <AdminListTable
    className='event-types'
    collectionName='event_types'
    searchable
    columns={[{
      name: 'title',
      label: props.t('EventTypes.columns.title'),
      sortable: true
    }]}
    onDelete={(EventType) => EventTypesService.delete(EventType)}
    onLoad={(params) => EventTypesService.fetchAll(params)}
    route='event_types'
  />
));

export default EventTypes;
