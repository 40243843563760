// @flow

import BatchUpdateable from './BatchUpdateable';
import Collection from '../transforms/Collection';

/**
 * Service class for all collection based API calls.
 */
class Collections extends BatchUpdateable {
  /**
   * Returns the collections base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/collections';
  }

  /**
   * Returns the collections transform.
   *
   * @returns {Collection}
   */
  getTransform(): typeof Collection {
    return Collection;
  }
}

export default (new Collections(): Collections);
