// @flow

import type { AuthoritySource, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import QuillEditor from './QuillEditor';
import './AuthoritySourceModal.css';

type Props = Translateable & {
  ...EditContainerProps,
  item: AuthoritySource
};

const AuthoritySourceModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='style-period-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.t('AuthoritySourceModal.title.add')}
    />
    <Modal.Content>
      <QuillEditor
        onChange={(value) => props.onTextInputChange('text', null, { value })}
        value={props.item.text || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default AuthoritySourceModal;
