// @flow

const MediaTypes = {
  kind: {
    image: 'image',
    video: 'video'
  }
};

export default MediaTypes;
