// @flow

import type { Keyword as KeywordType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import './KeywordModal.css';

type Props = Translateable & {
  ...EditContainerProps,
  item: KeywordType
};

const KeywordModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='keyword-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.t('KeywordModal.title.new')}
    />
    <Modal.Content>
      <Form.Input
        autoFocus
        label={props.t('KeywordModal.labels.keywordName')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
        width={12}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default KeywordModal;
