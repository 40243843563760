// @flow

import type { StylePeriod as StylePeriodType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import SimpleEditPage from './SimpleEditPage';
import StylePeriodForm from '../components/StylePeriodForm';
import StylePeriods from '../services/StylePeriods';

type Props = EditContainerProps & Translateable & {
  item: StylePeriodType
};

const Tabs = {
  details: 'details'
};

const StylePeriod: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
  >
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <StylePeriodForm
        {...props}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: StylePeriod,
  onInitialize: (id: number): Promise<any> => (
    StylePeriods
      .fetchOne(id)
      .then(({ data }) => data.style_period)
  ),
  onSave: (stylePeriod: StylePeriodType): Promise<any> => (
    StylePeriods
      .save(stylePeriod)
      .then(({ data }) => data.style_period)
  )
};
