// @flow

import type {
  Authority as AuthorityType,
  AuthorityRole as AuthorityRoleType,
  BuildingUsage as BuildingUsageType,
  Collection as CollectionType,
  Country as CountryType,
  Donation as DonationType,
  Keyword as KeywordType,
  Language as LanguageType,
  Material as MaterialType,
  PublicationType as PublicationTypeType,
  Site as SiteType
} from '@archnet/shared';
import { FilterTypes } from '@performant-software/semantic-components';
import i18n from '../i18n/I18n';
import Authorities from '../services/Authorities';
import Authority from '../transforms/Authority';
import AuthorityRoles from '../services/AuthorityRoles';
import AuthorityRole from '../transforms/AuthorityRole';
import BuildingUsage from '../transforms/BuildingUsage';
import BuildingUsages from '../services/BuildingUsages';
import Collection from '../transforms/Collection';
import Collections from '../services/Collections';
import Countries from '../services/Countries';
import Country from '../transforms/Country';
import Donation from '../transforms/Donation';
import Donations from '../services/Donations';
import type { Filter } from '../types/Filter';
import Filters from './Filters';
import Keyword from '../transforms/Keyword';
import Keywords from '../services/Keywords';
import Language from '../transforms/Language';
import Languages from '../services/Languages';
import Material from '../transforms/Material';
import Materials from '../services/Materials';
import PublicationType from '../transforms/PublicationType';
import PublicationTypes from '../services/PublicationTypes';
import Site from '../transforms/Site';
import Sites from '../services/Sites';

const PublicationFilters: Array<Filter> = [{
  baseClass: 'Site',
  associationColumn: 'child_id',
  associationName: 'publication_record_associations',
  attributeName: 'sites.id',
  collectionName: 'sites',
  key: 'site',
  label: i18n.t('Publications.filters.site'),
  objectName: 'site',
  onSearch: (search: string) => Sites.fetchAll({ search, sort_by: 'site_names.name' }),
  renderOption: (site) => Site.toDropdown(site),
  renderSearchQuery: (site: SiteType) => site.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Authority',
  associationColumn: 'child_id',
  associationName: 'publication_record_associations',
  attributeName: 'authorities.id',
  collectionName: 'authorities',
  key: 'authority',
  label: i18n.t('Publications.filters.authority'),
  objectName: 'authority',
  onSearch: (search: string) => Authorities.fetchAll({ search, sort_by: ['first_name', 'last_name'] }),
  renderOption: (authority) => Authority.toDropdown(authority),
  renderSearchQuery: (authority: AuthorityType) => authority.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Collection',
  associationColumn: 'child_id',
  associationName: 'publication_record_associations',
  attributeName: 'collections.id',
  collectionName: 'collections',
  key: 'collection',
  label: i18n.t('Publications.filters.collection'),
  objectName: 'collection',
  onSearch: (search: string) => Collections.fetchAll({ search, sort_by: 'name' }),
  renderOption: (collection) => Collection.toDropdown(collection),
  renderSearchQuery: (collection: CollectionType) => collection.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Donation',
  associationColumn: 'child_id',
  associationName: 'publication_record_associations',
  attributeName: 'donations.id',
  collectionName: 'donations',
  key: 'donation',
  label: i18n.t('Publications.filters.donation'),
  objectName: 'donation',
  onSearch: (search: string) => Donations.fetchAll({ search, sort_by: 'name' }),
  renderOption: (donation) => Donation.toDropdown(donation),
  renderSearchQuery: (donation: DonationType) => donation.name,
  type: FilterTypes.relationship
}, {
  attributeName: 'name',
  key: 'name',
  label: i18n.t('Publications.filters.name'),
  type: FilterTypes.string
}, {
  associationColumn: 'publication_type_id',
  associationName: 'publication_type',
  attributeName: 'id',
  collectionName: 'publication_types',
  key: 'publication_type_id',
  label: i18n.t('Publications.filters.publicationType'),
  objectName: 'publication_type',
  onSearch: (search: string) => PublicationTypes.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (publicationType) => PublicationType.toDropdown(publicationType),
  renderSearchQuery: (publicationType: PublicationTypeType) => publicationType.name,
  type: FilterTypes.relationship
}, {
  attributeName: 'year',
  key: 'year',
  label: i18n.t('Publications.filters.year'),
  type: FilterTypes.string
}, {
  attributeName: 'source_id',
  key: 'source_id',
  label: i18n.t('Publications.filters.sourceId'),
  type: FilterTypes.string
}, {
  attributeName: 'source',
  key: 'source',
  label: i18n.t('Publications.filters.source'),
  type: FilterTypes.text
}, {
  attributeName: 'copyright',
  key: 'copyright',
  label: i18n.t('Publications.filters.copyright'),
  type: FilterTypes.text
}, {
  attributeName: 'contributor',
  key: 'contributor',
  label: i18n.t('Publications.filters.contributor'),
  type: FilterTypes.string
}, {
  attributeName: 'dimensions',
  key: 'dimensions',
  label: i18n.t('Publications.filters.dimensions'),
  type: FilterTypes.string
}, {
  attributeName: 'citation',
  key: 'citation',
  label: i18n.t('Publications.filters.citation'),
  type: FilterTypes.text
}, {
  attributeName: 'description',
  key: 'description',
  label: i18n.t('Publications.filters.description'),
  type: FilterTypes.text
}, {
  attributeName: 'abstract',
  key: 'abstract',
  label: i18n.t('Publications.filters.abstract'),
  type: FilterTypes.text
}, {
  associationColumn: 'publication_id',
  associationName: 'countries_publications',
  attributeName: 'countries_publications.country_id',
  collectionName: 'countries',
  key: 'countries_publications.country_id',
  label: i18n.t('Publications.filters.country'),
  objectName: 'country',
  onSearch: (search: string) => Countries.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (country) => Country.toDropdown(country),
  renderSearchQuery: (country: CountryType) => country.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'publication_id',
  associationName: 'languages_publications',
  attributeName: 'languages_publications.language_id',
  collectionName: 'languages',
  key: 'languages_publications.language_id',
  label: i18n.t('Publications.filters.language'),
  objectName: 'language',
  onSearch: (search: string) => Languages.fetchAll({ search, sort_by: 'name' }),
  renderOption: (language) => Language.toDropdown(language),
  renderSearchQuery: (language: LanguageType) => language.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'publication_id',
  associationName: 'keywords_publications',
  attributeName: 'keywords_publications.keyword_id',
  collectionName: 'keywords',
  key: 'keywords_publications.keyword_id',
  label: i18n.t('Publications.filters.keyword'),
  objectName: 'keyword',
  onSearch: (search: string) => Keywords.fetchAll({ search, sort_by: 'name' }),
  renderOption: (keyword) => Keyword.toDropdown(keyword),
  renderSearchQuery: (keyword: KeywordType) => keyword.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'publication_id',
  associationName: 'materials_publications',
  attributeName: 'materials_publications.material_id',
  collectionName: 'materials',
  key: 'materials_publications.material_id',
  label: i18n.t('Publications.filters.material'),
  objectName: 'material',
  onSearch: (search: string) => Materials.fetchAll({ search, sort_by: 'name' }),
  renderOption: (material) => Material.toDropdown(material),
  renderSearchQuery: (material: MaterialType) => material.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'publication_id',
  associationName: 'building_usages_publications',
  attributeName: 'building_usages_publications.building_usage_id',
  collectionName: 'building_usages',
  key: 'building_usages_publications.building_usage_id',
  label: i18n.t('Publications.filters.buildingUsage'),
  objectName: 'building_usage',
  onSearch: (search: string) => BuildingUsages.fetchAll({ search, sort_by: 'name' }),
  renderOption: (buildingUsage) => BuildingUsage.toDropdown(buildingUsage),
  renderSearchQuery: (buildingUsage: BuildingUsageType) => buildingUsage.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Authority',
  associationColumn: 'child_id',
  associationName: 'publication_record_associations',
  attributeName: 'record_associations.data',
  collectionName: 'authority_roles',
  key: 'authority_roles',
  label: i18n.t('Common.filters.authorityRole'),
  objectName: 'authority_role',
  onSearch: (search: string) => AuthorityRoles.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (authorityRole) => AuthorityRole.toDropdown(authorityRole),
  renderSearchQuery: (authorityRole: AuthorityRoleType) => authorityRole.name,
  type: FilterTypes.relationship
}, {
  associationName: 'resource_description',
  key: 'resource_description',
  label: i18n.t('Common.filters.attachment'),
  type: FilterTypes.string
}, ...Filters.getFilters()];

export default PublicationFilters;
