// @flow

import type { Authority } from '@archnet/shared';
import React, { type Node } from 'react';
import ImageLabel from './ImageLabel';

type Props = {
  authority: Authority,
  color?: string
};

const AuthorityLabel = (props: Props): Node => (
  <ImageLabel
    color={props.color}
    icon='user'
    url={`/admin/authorities/${props.authority.id}`}
    value={props.authority.name}
  />
);

AuthorityLabel.defaultProps = {
  color: undefined
};

export default AuthorityLabel;
