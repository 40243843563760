// @flow

import type { Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { useState, type Node } from 'react';
import { Form, Label } from 'semantic-ui-react';
import uuid from 'react-uuid';
import _ from 'underscore';
import AssociatedAuthorities from './AssociatedAuthorities';
import AssociatedCollections from './AssociatedCollections';
import AssociatedDonations from './AssociatedDonations';
import AssociatedSites from './AssociatedSites';
import BuildingUsagesList from './BuildingUsagesList';
import CountriesList from './CountriesList';
import DonorLabel from './DonorLabel';
import KeywordsList from './KeywordsList';
import LanguagesList from './LanguagesList';
import MaterialsList from './MaterialsList';
import PublicationType from '../transforms/PublicationType';
import PublicationTypeModal from './PublicationTypeModal';
import PublicationTypes from '../services/PublicationTypes';
import RichTextArea from './RichTextArea';
import TabsMenu from './TabsMenu';

const Tabs = {
  details: 'details',
  sites: 'sites',
  collections: 'collections',
  publications: 'publications',
  authorities: 'authorities',
  donations: 'donations'
};

type Props = EditContainerProps & Translateable & {
  tabs: Array<string>
};

const PublicationUploadForm = (props: Props): Node => {
  const [tab, setTab] = useState(Tabs.details);

  return (
    <div>
      <TabsMenu
        onTabClick={(t) => setTab(t.key)}
        tabs={[{
          active: tab === Tabs.details,
          key: Tabs.details,
          label: props.t('Common.tabs.details'),
          visible: true
        }, {
          active: tab === Tabs.sites,
          key: Tabs.sites,
          label: props.t('Common.tabs.sites'),
          visible: _.include(props.tabs, Tabs.sites)
        }, {
          active: tab === Tabs.collections,
          key: Tabs.collections,
          label: props.t('Common.tabs.collections'),
          visible: _.include(props.tabs, Tabs.collections)
        }, {
          active: tab === Tabs.authorities,
          key: Tabs.authorities,
          label: props.t('Common.tabs.authorities'),
          visible: _.include(props.tabs, Tabs.authorities)
        }, {
          active: tab === Tabs.donations,
          key: Tabs.donations,
          label: props.t('Common.tabs.donations'),
          visible: _.include(props.tabs, Tabs.donations)
        }]}
      />
      { tab === Tabs.details && (
        <>
          <Form.Input
            error={props.isError('publication_type_id')}
            label={props.t('Publication.labels.type')}
            required={props.isRequired('publication_type_id')}
          >
            <AssociatedDropdown
              collectionName='publication_types'
              modal={{
                component: PublicationTypeModal,
                onSave: (pt) => PublicationTypes.save(pt).then(({ data }) => data.publication_type)
              }}
              onSearch={(search) => PublicationTypes.fetchAll({ per_page: 0, search, sort_by: 'name' })}
              onSelection={props.onAssociationInputChange.bind(this, 'publication_type_id', 'publication_type')}
              renderOption={(publicationType) => PublicationType.toDropdown(publicationType)}
              searchQuery={props.item.publication_type && props.item.publication_type.name}
              value={props.item.publication_type_id}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('year')}
            label={props.t('Publication.labels.year')}
            onChange={props.onTextInputChange.bind(this, 'year')}
            required={props.isRequired('year')}
            value={props.item.year || ''}
          />
          <Form.Input
            error={props.isError('source_id')}
            label={props.t('Publication.labels.sourceId')}
            onChange={props.onTextInputChange.bind(this, 'source_id')}
            required={props.isRequired('source_id')}
            value={props.item.source_id || ''}
          />
          <Form.Input
            error={props.isError('source')}
            label={props.t('Publication.labels.source')}
            required={props.isRequired('source')}
          >
            <RichTextArea
              onChange={(value) => props.onTextInputChange('source', null, { value })}
              value={props.item.source || ''}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('copyright')}
            label={props.t('Publication.labels.copyright')}
            required={props.isRequired('copyright')}
          >
            <RichTextArea
              onChange={(value) => props.onTextInputChange('copyright', null, { value })}
              value={props.item.copyright || ''}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('citation')}
            label={props.t('Publication.labels.citation')}
            required={props.isRequired('citation')}
          >
            <RichTextArea
              onChange={(value) => props.onTextInputChange('citation', null, { value })}
              value={props.item.citation || ''}
            />
          </Form.Input>
          <Form.Input
            error={props.isError('description')}
            label={props.t('Publication.labels.description')}
            required={props.isRequired('description')}
          >
            <RichTextArea
              onChange={(value) => props.onTextInputChange('description', null, { value })}
              value={props.item.description || ''}
            />
          </Form.Input>
          <Form.Input
            label={props.t('Publication.labels.countries')}
          >
            <CountriesList
              items={props.item.countries_publications}
              onDelete={props.onDeleteChildAssociation.bind(this, 'countries_publications')}
              onSave={props.onMultiAddChildAssociations.bind(this, 'countries_publications')}
            />
          </Form.Input>
          <Form.Input
            label={props.t('Publication.labels.languages')}
          >
            <LanguagesList
              items={props.item.languages_publications}
              onDelete={props.onDeleteChildAssociation.bind(this, 'languages_publications')}
              onSave={props.onMultiAddChildAssociations.bind(this, 'languages_publications')}
            />
          </Form.Input>
          <Form.Input
            label={props.t('Publication.labels.keywords')}
          >
            <KeywordsList
              items={props.item.keywords_publications}
              onDelete={props.onDeleteChildAssociation.bind(this, 'keywords_publications')}
              onSave={props.onMultiAddChildAssociations.bind(this, 'keywords_publications')}
            />
          </Form.Input>
          <Form.Input
            label={props.t('Publication.labels.materials')}
          >
            <MaterialsList
              items={props.item.materials_publications}
              onDelete={props.onDeleteChildAssociation.bind(this, 'materials_publications')}
              onSave={props.onMultiAddChildAssociations.bind(this, 'materials_publications')}
            />
          </Form.Input>
          <Form.Input
            label={props.t('Publication.labels.buildingUsages')}
          >
            <BuildingUsagesList
              items={props.item.building_usages_publications}
              onDelete={props.onDeleteChildAssociation.bind(this, 'building_usages_publications')}
              onSave={props.onMultiAddChildAssociations.bind(this, 'building_usages_publications')}
            />
          </Form.Input>
        </>
      )}
      { tab === Tabs.sites && (
        <AssociatedSites
          items={props.item.site_record_associations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'site_record_associations')}
          onSave={(sites) => {
            _.each(sites, (site) => {
              props.onSaveChildAssociation('site_record_associations', {
                uid: uuid(),
                parent_id: site.id,
                parent_type: 'Site',
                parent: site
              });
            });
          }}
          onUpdate={(items) => props.onSetState({ site_record_associations: items })}
          resolveSite={(item) => item.parent}
        />
      )}
      { tab === Tabs.collections && (
        <AssociatedCollections
          items={props.item.collection_record_associations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'collection_record_associations')}
          onSave={(collections) => {
            _.each(collections, (collection) => {
              props.onSaveChildAssociation('collection_record_associations', {
                uid: uuid(),
                parent_id: collection.id,
                parent_type: 'Collection',
                parent: collection
              });
            });
          }}
          onUpdate={(items) => props.onSetState({ collection_record_associations: items })}
          resolveCollection={(item) => item.parent}
        />
      )}
      { tab === Tabs.authorities && (
        <AssociatedAuthorities
          items={props.item.authority_record_associations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authority_record_associations')}
          onRoleSelection={props.onSaveChildAssociation.bind(this, 'authority_record_associations')}
          onSave={(authorities) => {
            _.each(authorities, (authority) => {
              props.onSaveChildAssociation('authority_record_associations', {
                uid: uuid(),
                parent_id: authority.id,
                parent_type: 'Authority',
                parent: authority
              });
            });
          }}
          onUpdate={(items) => props.onSetState({ authority_record_associations: items })}
          resolveAuthority={(item) => item.parent}
        />
      )}
      { tab === Tabs.donations && (
        <AssociatedDonations
          items={props.item.donation_record_associations}
          onDelete={props.onDeleteChildAssociation.bind(this, 'donation_record_associations')}
          onSave={(donations) => {
            _.each(donations, (donation) => {
              props.onSaveChildAssociation('donation_record_associations', {
                uid: uuid(),
                parent_id: donation.id,
                parent_type: 'Donation',
                parent: donation
              });
            });
          }}
          onUpdate={(items) => props.onSetState({ donation_record_associations: items })}
          renderDescription={(item) => (
            <Label.Group>
              { _.map(item.parent.donor_record_associations, (ra) => <DonorLabel donor={ra.parent} />) }
            </Label.Group>
          )}
          resolveDonation={(item) => item.parent}
        />
      )}
    </div>
  );
};

export default PublicationUploadForm;
