// @flow

import axios from 'axios';
import BatchUpdateable from './BatchUpdateable';
import Publication, { type PublicationUpload } from '../transforms/Publication';

import type { Publication as PublicationType } from '@archnet/shared';

/**
 * Service class for all publications based API calls.
 */
class Publications extends BatchUpdateable {
  /**
   * Returns the publications base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/publications';
  }

  /**
   * Returns the publications transform.
   *
   * @returns {Publication}
   */
  getTransform(): typeof Publication {
    return Publication;
  }

  /**
   * Calls the /api/publications/upload API endpoint for multiple files.
   *
   * @param publications
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  upload(publications: PublicationUpload): Promise<any> {
    const url = `${this.getBaseUrl()}/upload`;

    const transform = this.getTransform();
    const payload = transform.toBulkPayload(publications);

    return axios.post(url, payload, this.getConfig());
  }
}

export default (new Publications(): Publications);
