// @flow

import _ from 'underscore';

import type { SiteNameType as SiteNameTypeType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming site name type records.
 */
class SiteNameType {
  PAYLOAD_KEYS: Array<string> = [
    'name'
  ];

  /**
   * Returns the passed site name type as a dropdown option.
   *
   * @param siteNameType
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(siteNameType: SiteNameTypeType): Option {
    return {
      key: siteNameType.id,
      value: siteNameType.id,
      text: siteNameType.name
    };
  }

  /**
   * Returns the site name type to be sent to the server on POST/PUT requests.
   *
   * @param siteNameType
   *
   * @returns {*}
   */
  toPayload(siteNameType: SiteNameTypeType): any {
    return {
      site_name_type: _.pick(siteNameType, this.PAYLOAD_KEYS)
    };
  }
}

export default (new SiteNameType(): SiteNameType);
