// @flow

import _ from 'underscore';
import type { Authority } from '../types/Authority';
import type { AuthorityName } from '../types/AuthorityName';

/**
 * Returns the primary country name for the passed authority.
 *
 * @param authority
 *
 * @returns {""|*|string}
 */
const formatPrimaryCountry = (authority: Authority): string => {
  const primary = getPrimaryName(authority);
  return primary && primary.country && primary.country.name;
};

/**
 * Returns the formatted primary name for the passed authority.
 *
 * @param authority
 *
 * @returns {string}
 */
const formatPrimaryName = (authority: Authority): string => {
  const name = [];

  const primary = getPrimaryName(authority);

  if (primary && primary.first_name) {
    name.push(primary.first_name);
  }

  if (primary && primary.last_name) {
    name.push(primary.last_name);
  }

  return name.join(' ');
};

/**
 * Returns the year description for the primary authority name for the passed authority.
 *
 * @param authority
 *
 * @returns {""|*}
 */
const formatPrimaryYear = (authority: Authority): string => {
  const primary = getPrimaryName(authority);
  return primary && primary.year_description;
};

/**
 * Returns the primary authority name record for the passed authority.
 *
 * @param authority
 *
 * @returns {string}
 */
const getPrimaryName = (authority: Authority): AuthorityName => (
  _.find(authority.authority_names, (an) => an.primary && !an._destroy)
);

export default {
  formatPrimaryCountry,
  formatPrimaryName,
  formatPrimaryYear,
  getPrimaryName
};
