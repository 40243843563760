// @flow

import type { Donor as DonorType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Form } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedDonations from '../components/AssociatedDonations';
import AssociatedMedia from '../components/AssociatedMedia';
import Donors from '../services/Donors';
import { onMultiAddChildren } from '../utils/RecordAssociation';
import RecordAssociations from '../services/RecordAssociations';
import RecordHeader from '../components/RecordHeader';
import PrimaryImage from '../components/PrimaryImage';
import SimpleEditPage from './SimpleEditPage';

type Props = Translateable & {
  ...EditContainerProps,
  item: DonorType
};

const Tabs = {
  details: 'details',
  donations: 'donations',
  media: 'media'
};

const Donor: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
    className='donor'
  >
    <SimpleEditPage.Header>
      <RecordHeader
        {...props}
        header={props.item.name}
        includePublishButton={false}
        meta={props.item.record_id}
        renderImage={() => (
          <PrimaryImage
            item={props.item}
          />
        )}
      />
    </SimpleEditPage.Header>
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <Form.Input
        error={props.isError('name')}
        label={props.t('Donor.labels.name')}
        required={props.isRequired('name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('email')}
        label={props.t('Donor.labels.email')}
        onChange={props.onTextInputChange.bind(this, 'email')}
        required={props.isRequired('email')}
        value={props.item.email || ''}
      />
      <Form.Input
        error={props.isError('phone')}
        label={props.t('Donor.labels.phone')}
        onChange={props.onTextInputChange.bind(this, 'phone')}
        required={props.isRequired('phone')}
        value={props.item.phone || ''}
      />
      <Form.TextArea
        error={props.isError('general_info')}
        label={props.t('Donor.labels.generalInfo')}
        onChange={props.onTextInputChange.bind(this, 'general_info')}
        required={props.isRequired('general_info')}
        value={props.item.general_info || ''}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.donations}
      name={props.t('Common.tabs.donations')}
      count={props.item.associated_child_donations_count}
    >
      <AssociatedDonations
        items={props.item.donation_record_associations}
        onDataLoaded={(items) => props.onUpdateState({
          donation_record_associations: [
            ...props.item.donation_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'donation_record_associations')}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Donor',
          child_type: 'Donation'
        })}
        onSave={onMultiAddChildren.bind(this, props, 'donation_record_associations', 'Donation')}
        onUpdate={(items) => props.onSetState({ donation_record_associations: items })}
        resolveDonation={(item) => item.child}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.media}
      name={props.t('Common.tabs.media')}
      count={props.item.associated_child_media_count}
    >
      <AssociatedMedia
        items={props.item.media_record_associations}
        onDataLoaded={(items) => props.onUpdateState({
          media_record_associations: [
            ...props.item.media_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'media_record_associations')}
        onEdit={(item, media) => props.onSaveChildAssociation('media_record_associations', {
          ...item,
          child: media
        })}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Donor',
          child_type: 'MediaContent'
        })}
        onSave={(media) => {
          _.each(media, (m) => {
            props.onSaveChildAssociation('media_record_associations', {
              uid: uuid(),
              child_id: m.id,
              child_type: 'MediaContent',
              child: m
            });
          });
        }}
        onSaveMultiple={onMultiAddChildren.bind(this, props, 'media_record_associations', 'MediaContent')}
        onSelectPrimary={(item) => props.onSetState({
          media_record_associations: _.map(
            props.item.media_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ media_record_associations: items })}
        resolveMedia={(item) => item.child}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: Donor,
  onInitialize: (id: number): Promise<any> => (
    Donors
      .fetchOne(id)
      .then(({ data }) => data.donor)
  ),
  onSave: (donor: DonorType): Promise<any> => (
    Donors
      .save(donor)
      .then(({ data }) => data.donor)
  )
};
