// @flow

import type {
  Authority as AuthorityType,
  AuthorityRole as AuthorityRoleType,
  Collection as CollectionType,
  Country as CountryType,
  Donation as DonationType,
  Keyword as KeywordType,
  Language as LanguageType,
  Material as MaterialType,
  MediaType as MediaTypeType,
  Site as SiteType,
  StylePeriod as StylePeriodType
} from '@archnet/shared';
import { FilterTypes } from '@performant-software/semantic-components';
import i18n from '../i18n/I18n';
import Authorities from '../services/Authorities';
import Authority from '../transforms/Authority';
import AuthorityRoles from '../services/AuthorityRoles';
import AuthorityRole from '../transforms/AuthorityRole';
import Collection from '../transforms/Collection';
import Collections from '../services/Collections';
import Countries from '../services/Countries';
import Country from '../transforms/Country';
import Donation from '../transforms/Donation';
import Donations from '../services/Donations';
import ExternalSources from '../resources/media_contents/ExternalSources.json';
import Language from '../transforms/Language';
import Languages from '../services/Languages';
import type { Filter } from '../types/Filter';
import Filters from './Filters';
import Keyword from '../transforms/Keyword';
import Keywords from '../services/Keywords';
import Material from '../transforms/Material';
import Materials from '../services/Materials';
import MediaType from '../transforms/MediaType';
import MediaTypes from '../services/MediaTypes';
import Site from '../transforms/Site';
import Sites from '../services/Sites';
import StylePeriod from '../transforms/StylePeriod';
import StylePeriods from '../services/StylePeriods';

const MediaContentFilters: Array<Filter> = [{
  associationName: 'media_type',
  attributeName: 'media_types.kind',
  key: 'media_types.kind',
  label: i18n.t('MediaContents.filters.imageVideo'),
  options: [{
    key: 'image',
    icon: 'photo',
    text: i18n.t('MediaContents.labels.image'),
    value: 'image'
  }, {
    key: 'video',
    icon: 'video',
    text: i18n.t('MediaContents.labels.video'),
    value: 'video'
  }],
  type: FilterTypes.select
}, {
  associationColumn: 'media_type_id',
  associationName: 'media_type',
  attributeName: 'media_type_id',
  collectionName: 'media_types',
  key: 'media_type',
  label: i18n.t('MediaContents.filters.mediaType'),
  objectName: 'media_type',
  onSearch: (search: string) => MediaTypes.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (mediaType) => MediaType.toDropdown(mediaType),
  renderSearchQuery: (mediaType: MediaTypeType) => mediaType.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Site',
  associationColumn: 'child_id',
  associationName: 'media_record_associations',
  attributeName: 'sites.id',
  collectionName: 'sites',
  key: 'site',
  label: i18n.t('MediaContents.filters.site'),
  objectName: 'site',
  onSearch: (search: string) => Sites.fetchAll({ search, sort_by: 'site_names.name' }),
  renderOption: (site) => Site.toDropdown(site),
  renderSearchQuery: (site: SiteType) => site.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Authority',
  associationColumn: 'child_id',
  associationName: 'media_record_associations',
  attributeName: 'authorities.id',
  collectionName: 'authorities',
  key: 'authority',
  label: i18n.t('MediaContents.filters.authority'),
  objectName: 'authority',
  onSearch: (search: string) => Authorities.fetchAll({ search, sort_by: ['first_name', 'last_name'] }),
  renderOption: (authority) => Authority.toDropdown(authority),
  renderSearchQuery: (authority: AuthorityType) => authority.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Collection',
  associationColumn: 'child_id',
  associationName: 'media_record_associations',
  attributeName: 'collections.id',
  collectionName: 'collections',
  key: 'collection',
  label: i18n.t('MediaContents.filters.collection'),
  objectName: 'collection',
  onSearch: (search: string) => Collections.fetchAll({ search, sort_by: 'name' }),
  renderOption: (collection) => Collection.toDropdown(collection),
  renderSearchQuery: (collection: CollectionType) => collection.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Donation',
  associationColumn: 'child_id',
  associationName: 'media_record_associations',
  attributeName: 'donations.id',
  collectionName: 'donations',
  key: 'donation',
  label: i18n.t('MediaContents.filters.donation'),
  objectName: 'donation',
  onSearch: (search: string) => Donations.fetchAll({ search, sort_by: 'name' }),
  renderOption: (donation) => Donation.toDropdown(donation),
  renderSearchQuery: (donation: DonationType) => donation.name,
  type: FilterTypes.relationship
}, {
  attributeName: 'name',
  key: 'name',
  label: i18n.t('MediaContents.filters.name'),
  type: FilterTypes.string
}, {
  attributeName: 'year',
  key: 'year',
  label: i18n.t('MediaContents.filters.year'),
  type: FilterTypes.string
}, {
  attributeName: 'year_description',
  key: 'year_description',
  label: i18n.t('MediaContents.filters.yearDescription'),
  type: FilterTypes.string
}, {
  attributeName: 'caption',
  key: 'caption',
  label: i18n.t('MediaContents.filters.caption'),
  type: FilterTypes.text
}, {
  attributeName: 'external_source',
  key: 'external_source',
  label: i18n.t('MediaContents.filters.externalSource'),
  options: ExternalSources,
  type: FilterTypes.select
}, {
  attributeName: 'external_id',
  key: 'external_id',
  label: i18n.t('MediaContents.filters.externalId'),
  type: FilterTypes.string
}, {
  attributeName: 'source_id',
  key: 'source_id',
  label: i18n.t('MediaContents.filters.sourceId'),
  type: FilterTypes.string
}, {
  attributeName: 'source',
  key: 'source',
  label: i18n.t('MediaContents.filters.source'),
  type: FilterTypes.text
}, {
  attributeName: 'copyright',
  key: 'copyright',
  label: i18n.t('MediaContents.filters.copyright'),
  type: FilterTypes.text
}, {
  attributeName: 'contributor',
  key: 'contributor',
  label: i18n.t('MediaContents.filters.contributor'),
  type: FilterTypes.text
}, {
  attributeName: 'dimensions',
  key: 'dimensions',
  label: i18n.t('MediaContents.filters.dimensions'),
  type: FilterTypes.string
}, {
  attributeName: 'description',
  key: 'description',
  label: i18n.t('MediaContents.filters.description'),
  type: FilterTypes.text
}, {
  attributeName: 'abstract',
  key: 'abstract',
  label: i18n.t('MediaContents.filters.abstract'),
  type: FilterTypes.text
}, {
  associationColumn: 'media_content_id',
  associationName: 'languages_media_contents',
  attributeName: 'languages_media_contents.language_id',
  collectionName: 'languages',
  key: 'languages_media_contents.language_id',
  label: i18n.t('MediaContents.filters.language'),
  objectName: 'language',
  onSearch: (search: string) => Languages.fetchAll({ search, sort_by: 'name' }),
  renderOption: (language) => Language.toDropdown(language),
  renderSearchQuery: (language: LanguageType) => language.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'media_content_id',
  associationName: 'keywords_media_contents',
  attributeName: 'keywords_media_contents.keyword_id',
  collectionName: 'keywords',
  key: 'keywords_media_contents.keyword_id',
  label: i18n.t('MediaContents.filters.keyword'),
  objectName: 'keyword',
  onSearch: (search: string) => Keywords.fetchAll({ search, sort_by: 'name' }),
  renderOption: (keyword) => Keyword.toDropdown(keyword),
  renderSearchQuery: (keyword: KeywordType) => keyword.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'media_content_id',
  associationName: 'materials_media_contents',
  attributeName: 'materials_media_contents.material_id',
  collectionName: 'materials',
  key: 'materials_media_contents.material_id',
  label: i18n.t('MediaContents.filters.material'),
  objectName: 'material',
  onSearch: (search: string) => Materials.fetchAll({ search, sort_by: 'name' }),
  renderOption: (material) => Material.toDropdown(material),
  renderSearchQuery: (material: MaterialType) => material.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'media_content_id',
  associationName: 'media_contents_style_periods',
  attributeName: 'media_contents_style_periods.style_period_id',
  collectionName: 'style_periods',
  key: 'media_contents_style_periods.style_period_id',
  label: i18n.t('MediaContents.filters.stylePeriod'),
  objectName: 'style_period',
  onSearch: (search: string) => StylePeriods.fetchAll({ search, sort_by: 'name' }),
  renderOption: (stylePeriod) => StylePeriod.toDropdown(stylePeriod),
  renderSearchQuery: (stylePeriod: StylePeriodType) => stylePeriod.name,
  type: FilterTypes.relationship
}, {
  associationColumn: 'country_id',
  associationName: 'country',
  attributeName: 'id',
  collectionName: 'countries',
  key: 'country',
  label: i18n.t('MediaContents.filters.country'),
  objectName: 'country',
  onSearch: (search: string) => Countries.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (country) => Country.toDropdown(country),
  renderSearchQuery: (country: CountryType) => country.name,
  type: FilterTypes.relationship
}, {
  baseClass: 'Authority',
  associationColumn: 'child_id',
  associationName: 'media_record_associations',
  attributeName: 'record_associations.data',
  collectionName: 'authority_roles',
  key: 'authority_roles',
  label: i18n.t('Common.filters.authorityRole'),
  objectName: 'authority_role',
  onSearch: (search: string) => AuthorityRoles.fetchAll({ per_page: 0, search, sort_by: 'name' }),
  renderOption: (authorityRole) => AuthorityRole.toDropdown(authorityRole),
  renderSearchQuery: (authorityRole: AuthorityRoleType) => authorityRole.name,
  type: FilterTypes.relationship
}, {
  associationName: 'resource_description',
  key: 'resource_description',
  label: i18n.t('Common.filters.attachment'),
  type: FilterTypes.string
}, ...Filters.getFilters()];

export default MediaContentFilters;
