// @flow

import _ from 'underscore';

import type { PublicationType as PublicationTypeType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming publication type records.
 */
class PublicationType {
  PAYLOAD_KEYS: Array<string> = [
    'name',
    'kind'
  ];

  /**
   * Returns the passed publication type as a dropdown option.
   *
   * @param publicationType
   *
   * @returns {{text: string, value: number, key: number}}
   */
  toDropdown(publicationType: PublicationTypeType): Option {
    return {
      key: publicationType.id,
      value: publicationType.id,
      text: publicationType.name
    };
  }

  /**
   * Returns the publication type object to be sent to the server on POST/PUT requests.
   *
   * @param publicationType
   *
   * @returns {{publication_type: (*)}}
   */
  toPayload(publicationType: PublicationTypeType): any {
    return {
      publication_type: _.pick(publicationType, this.PAYLOAD_KEYS)
    };
  }
}

export default (new PublicationType(): PublicationType);
