// @flow

import type { MediaContent } from '@archnet/shared';
import React, { type Node } from 'react';
import LazyMedia from './LazyMedia';

type Props = {
  item: {
    primary_image: {
      child: MediaContent
    }
  }
};

const PrimaryImageView = ({ item, ...props }: Props): Node => {
  if (!(item.primary_image && item.primary_image.child)) {
    return (
      <LazyMedia
        {...props}
        media={item}
      />
    );
  }

  return (
    <LazyMedia
      {...props}
      media={item.primary_image && item.primary_image.child}
    />
  );
};

export default PrimaryImageView;
