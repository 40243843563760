// @flow

import type { MediaContent, RecordAssociation, Translateable } from '@archnet/shared';
import React, { type AbstractComponent, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import LazyMedia from './LazyMedia';

type Item = {
  media_record_associations: Array<RecordAssociation>,
  primary_image?: {
    id: string,
    child: MediaContent
  }
};

type Props = Translateable & {
  item: Item
};

const PrimaryImage: AbstractComponent<any> = withTranslation()(({ children, item, ...props }: Props) => {
  /**
   * Find the primary image for the passed item.
   *
   * @type {unknown}
   */
  const media = useMemo(() => (
    _.find(item.media_record_associations, (ra) => ra.primary && !ra._destroy) || item.primary_image
  ), [item]);

  if (_.isEmpty(media) && item.content_url) {
    return (
      <LazyMedia
        {...props}
        media={item}
      >
        { children }
      </LazyMedia>
    );
  }

  return (
    <LazyMedia
      {...props}
      media={media?.child}
    >
      { children }
    </LazyMedia>
  );
});

export default PrimaryImage;
