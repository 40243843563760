// @flow

import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, RouterHistory, withRouter } from 'react-router-dom';
import { Header, Menu, Sidebar } from 'semantic-ui-react';
import _ from 'underscore';
import { Banner, Logo } from '@archnet/shared';
import Authentication from '../services/Authentication';
import Session from '../services/Session';
import './AdminSidebar.css';

const MenuItems = {
  librarian: [
    'sites',
    'publications',
    'media_contents',
    'donations',
    'donors',
    'authorities',
    'collections'
  ],
  cms: [
    'users',
    'home_pages',
    'custom_pages',
    'collection_types',
    'countries',
    'event_types',
    'style_periods',
    'keywords'
  ]
};

type Props = {
  history: typeof RouterHistory,
  location: {
    pathname: string
  },
  t: (key: string) => string
};

const AdminSidebar: AbstractComponent<any> = withTranslation()(withRouter((props: Props) => (
  <Sidebar
    as={Menu}
    className='admin-sidebar'
    inverted
    vertical
    visible
  >
    <Menu.Menu>
      <Menu.Item>
        <Logo />

      </Menu.Item>
      <Menu.Item>
        <Banner
          content={process.env.REACT_APP_ENV_NAME}
          fluid
        />
      </Menu.Item>
    </Menu.Menu>
    { _.map(Object.keys(MenuItems), (header) => (
      <Menu.Menu
        key={header}
      >
        <Menu.Item>
          <Header
            content={props.t(`Admin.menu.${header}.header`)}
            inverted
          />
        </Menu.Item>
        { _.map(MenuItems[header], (item) => (
          <Menu.Item
            active={!!props.location.pathname.match(`/admin/${item}`)}
            as={Link}
            key={item}
            to={`/admin/${item}`}
          >
            { props.t(`Admin.menu.${header}.${item}`)}
          </Menu.Item>
        ))}
      </Menu.Menu>
    ))}
    <Menu.Menu>
      { process.env.REACT_APP_WEB_URL && (
        <Menu.Item
          as='a'
          content={props.t('Admin.menu.explore')}
          href={process.env.REACT_APP_WEB_URL}
          icon='world'
          target='_blank'
        />
      )}
      <Menu.Item
        content={props.t('Admin.menu.logout')}
        icon='log out'
        onClick={() => {
          Authentication
            .logout()
            .then(() => {
              Session.destroy();
              props.history.push('/');
            });
        }}
      />
    </Menu.Menu>
  </Sidebar>
)));

export default AdminSidebar;
