// @flow

import type { PublicationType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import Kinds from '../resources/publication_types/Kinds.json';

type Props = Translateable & {
  ...EditContainerProps,
  item: PublicationType
};

const PublicationTypeModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='publication-type-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('PublicationTypeModal.title.edit')
        : props.t('PublicationTypeModal.title.add')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('name')}
        label={props.t('PublicationTypeModal.labels.name')}
        required={props.isRequired('name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
      <Form.Dropdown
        autoFocus
        error={props.isError('kind')}
        fluid
        label={props.t('PublicationTypeModal.labels.kind')}
        onChange={props.onTextInputChange.bind(this, 'kind')}
        options={Kinds}
        required={props.isRequired('kind')}
        selectOnBlur={false}
        selection
        value={props.item.kind || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
));

export default PublicationTypeModal;
