// @flow

import { DropdownButton } from '@performant-software/semantic-components';
import React, {
  useState,
  type ComponentType,
  type Node
} from 'react';
import _ from 'underscore';

type AssociationTypeProps = {
  associationType: string,
  renderAssociationTypeDropdown: () => Node
};

type Props = {
  defaultType?: string,
  icon?: any,
  text?: any
};

const AssociationTypes = {
  child: 'child',
  parent: 'parent',
  related: 'related'
};

const defaultProps = {
  icon: {},
  text: {}
};

const useAssociationType = (props: Props) => {
  const [associationType, setAssociationType] = useState(props.defaultType || AssociationTypes.child);

  // Build the list of options based on the text values provided.
  const options = [];

  _.each(_.keys(AssociationTypes), (type) => {
    if (props.text && props.text[type]) {
      options.push({
        icon: props.icon && props.icon[type],
        key: AssociationTypes[type],
        text: props.text[type],
        value: AssociationTypes[type]
      });
    }
  });

  return {
    associationType,
    renderAssociationTypeDropdown: () => (
      <DropdownButton
        color='teal'
        icon={_.findWhere(options, { value: associationType }).icon}
        options={options}
        onChange={(e, { value }) => setAssociationType(value)}
        text={_.findWhere(options, { value: associationType }).text}
        value={associationType}
      />
    )
  };
};

type WithAssociationType = (args: Props) => (WrappedComponent: ComponentType<any>) => (props: any) => Node;

const withAssociationType: WithAssociationType = (args: Props) => (
  (WrappedComponent: ComponentType<any>) => (props: any) => {
    const associationData = useAssociationType(_.defaults(args, defaultProps));
    return <WrappedComponent {...associationData} {...props} />;
  }
);

export default withAssociationType;

export {
  AssociationTypes
};

export type {
  AssociationTypeProps
};
