// @flow

import React, { type AbstractComponent, type Node } from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import './UnauthenticatedRoute.css';

type Props = {
  component: AbstractComponent<any>,
  path: string
};

const UnauthenticatedRoute = (props: Props): Node => (
  <Container
    className='unauthenticated-route'
  >
    <Route
      path={props.path}
      component={props.component}
    />
  </Container>
);

export default UnauthenticatedRoute;
