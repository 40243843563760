// @flow

import BaseService from './BaseService';
import SiteNameType from '../transforms/SiteNameType';

/**
 * Service class for all site name type based API calls.
 */
class SiteNameTypes extends BaseService {
  /**
   * Returns the sites base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/site_name_types';
  }

  /**
   * Returns the site name types transform.
   *
   * @returns {SiteNameType}
   */
  getTransform(): typeof SiteNameType {
    return SiteNameType;
  }
}

export default (new SiteNameTypes(): SiteNameTypes);
