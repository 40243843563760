// @flow

import _ from 'underscore';

import type { BuildingUsage as BuildingUsageType } from '@archnet/shared';
import type { Option } from '../types/Option';

/**
 * Class for handling transforming building_usage records.
 */
class BuildingUsage {
  PAYLOAD_KEYS: Array<string> = [
    'id',
    'name',
    'building_type_id',
    '_destroy'
  ];

  /**
   * Returns the passed BuildingUsage as a dropdown option.
   *
   * @param buildingUsage
   *
   * @returns {{description: *, text: string, value: number, key: number}}
   */
  toDropdown(buildingUsage: BuildingUsageType): Option {
    return {
      key: buildingUsage.id,
      value: buildingUsage.id,
      text: buildingUsage.name,
      description: buildingUsage.building_type_name
    };
  }

  /**
   * Returns the building_usage to be sent to the server on POST/PUT requests.
   *
   * @param buildingUsage
   *
   * @returns {{building_usage: (*)}}
   */
  toPayload(buildingUsage: BuildingUsageType): any {
    return {
      building_usage: _.pick(buildingUsage, this.PAYLOAD_KEYS)
    };
  }
}

export default (new BuildingUsage(): BuildingUsage);
