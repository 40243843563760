// @flow

/**
 * Service class responsible for all session based functions.
 */
class Session {
  storage: typeof localStorage = localStorage;

  /**
   * Creates a new session based on the passed response.
   *
   * @param response
   */
  create(response: any) {
    const { uid } = response.data.data;

    this.storage.setItem('user', JSON.stringify({
      'access-token': response.headers['access-token'],
      client: response.headers.client,
      uid
    }));
  }

  /**
   * Removes the current session.
   */
  destroy() {
    this.storage.removeItem('user');
  }

  /**
   * Returns true if the user is currently authenticated.
   *
   * @returns {boolean}
   */
  isAuthenticated(): boolean {
    return !!this.storage.getItem('user');
  }
}

export default (new Session(): Session);
