// @flow

import { ListFilters } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import AdminItemList from '../components/AdminItemList';
import AuthoritiesService from '../services/Authorities';
import AuthorityFilters from '../filters/AuthorityFilters';
import Collections from '../actions/Collections';
import { getActions } from '../actions/Actions';
import PrimaryImageView from '../components/PrimaryImageView';
import PublishedLabel from '../components/PublishedLabel';
import Sites from '../actions/Sites';

type Props = {
  t: (key: string) => string
};

const Authorities: AbstractComponent<any> = withTranslation()((props: Props) => (
  <AdminItemList
    className='authorities'
    collectionName='authorities'
    editActions={[{
      ...Collections,
      parentType: 'Collection'
    }, {
      ...Sites,
      childType: 'Site'
    }, ...getActions()]}
    filters={{
      component: ListFilters,
      props: {
        filters: AuthorityFilters
      }
    }}
    onBatchDelete={(ids) => AuthoritiesService.batchDelete(ids)}
    onBatchUpdate={(ids, params) => AuthoritiesService.batchUpdate(ids, params)}
    onDelete={(authority) => AuthoritiesService.delete(authority)}
    onLoad={(params) => AuthoritiesService.search({ ...params, sort_by: params.sort_by.split(',') })}
    renderExtra={(item) => <PublishedLabel icon published={item.published} />}
    renderHeader={(item) => <Header content={item.name} />}
    renderImage={(item) => <PrimaryImageView item={item} />}
    renderMeta={(item) => item.record_id}
    route='authorities'
    sort={[{
      key: 'name',
      value: 'authority_names.last_name,authority_names.first_name',
      text: props.t('Common.sort.name')
    }, {
      key: 'record_id',
      value: 'record_id',
      text: props.t('Common.sort.recordId')
    }, {
      key: 'created_at',
      value: 'created_at',
      text: props.t('Common.sort.createdAt')
    }, {
      key: 'updated_at',
      value: 'updated_at',
      text: props.t('Common.sort.updatedAt')
    }, {
      key: 'date_published',
      value: 'date_published',
      text: props.t('Common.sort.datePublished')
    }]}
  />
));

export default Authorities;
