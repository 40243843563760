// @flow

import _ from 'underscore';
import { type Action } from '../types/Action';
import i18n from '../i18n/I18n';

const ActionTypes = {
  boolean: 'boolean',
  date: 'date',
  relationship: 'relationship',
  select: 'select',
  string: 'string',
  text: 'text'
};

const Actions: Array<Action> = [{
  attributeName: 'published',
  key: 'published',
  text: i18n.t('Common.actions.published'),
  type: ActionTypes.boolean,
  value: 'published'
}, {
  attributeName: 'date_published',
  key: 'date_published',
  text: i18n.t('Common.actions.datePublished'),
  type: ActionTypes.date,
  value: 'date_published'
}, {
  attributeName: 'locked',
  key: 'locked',
  text: i18n.t('Common.actions.locked'),
  type: ActionTypes.boolean,
  value: 'locked'
}, {
  attributeName: 'hcp_item',
  key: 'hcp_item',
  text: i18n.t('Common.actions.hcpItem'),
  type: ActionTypes.boolean,
  value: 'hcp_item'
}, {
  attributeName: 'award_winner',
  key: 'award_winner',
  text: i18n.t('Common.actions.awardWinner'),
  type: ActionTypes.boolean,
  value: 'award_winner'
}, {
  attributeName: 'flag_for_review',
  key: 'flag_for_review',
  text: i18n.t('Common.actions.flagForReview'),
  type: ActionTypes.boolean,
  value: 'flag_for_review'
}];

type Options = {
  exclude?: Array<string>,
  include?: Array<string>,
};

/**
 * Returns the list of actions, optionally including or excluding the specified keys.
 *
 * @param options
 *
 * @returns {[]}
 */
type GetActionsType = (options?: Options) => Array<Action>;

const getActions: GetActionsType = (options: Options = {}) => {
  const actions: Array<Action> = [];

  if (options.include && options.include.length) {
    actions.push(..._.filter(Actions, (action) => _.contains(options.include, action.key)));
  }

  if (options.exclude && options.exclude.length) {
    actions.push(..._.reject(Actions, (action) => _.contains(options.exclude, action.key)));
  }

  if (!(options.include || options.exclude)) {
    actions.push(...Actions);
  }

  return actions;
};

export {
  Actions,
  ActionTypes,
  getActions
};
