// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming nested materials records.
 */
class Materials extends NestedAttributes {
  /**
   * Returns the materials payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'material_id',
      '_destroy'
    ];
  }
}

export default (new Materials(): Materials);
