// @flow

import axios from 'axios';
import BatchUpdateable from './BatchUpdateable';
import MediaContent, { type MediaContentUpload } from '../transforms/MediaContent';

/**
 * Service class for all media_contents based API calls.
 */
class MediaContents extends BatchUpdateable {
  /**
   * Returns the media_contents base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/media_contents';
  }

  /**
   * Returns the media_contents transform.
   *
   * @returns {MediaContent}
   */
  getTransform(): typeof MediaContent {
    return MediaContent;
  }

  /**
   * Calls the /api/media_contents/upload API endpoint for multiple files.
   *
   * @param mediaContents
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  upload(mediaContents: MediaContentUpload): Promise<any> {
    const url = `${this.getBaseUrl()}/upload`;

    const transform = this.getTransform();
    const payload = transform.toBulkPayload(mediaContents);

    return axios.post(url, payload, this.getConfig());
  }
}

export default (new MediaContents(): MediaContents);
