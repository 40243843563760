// @flow

import type { StylePeriod as StylePeriodType, Translateable } from '@archnet/shared';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import StylePeriod from '../transforms/StylePeriod';
import StylePeriodForm from './StylePeriodForm';
import StylePeriods from '../services/StylePeriods';
import './StylePeriodModal.css';

type Props = EditContainerProps & Translateable & {
  primary: boolean,
  style_period: StylePeriodType,
  style_period_id: number
};

const StylePeriodModal: AbstractComponent<any> = withTranslation()((props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='style-period-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.t('StylePeriodModal.title.add')}
    />
    <Modal.Content>
      <Form.Checkbox
        checked={props.item.primary}
        label={props.t('StylePeriodModal.labels.primary')}
        onChange={props.onCheckboxInputChange.bind(this, 'primary')}
      />
      <Form.Input
        label={props.t('StylePeriodModal.labels.name')}
      >
        <AssociatedDropdown
          collectionName='style_periods'
          onSearch={(search) => StylePeriods.fetchAll({ search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'style_period_id', 'style_period')}
          renderOption={(stylePeriod) => StylePeriod.toDropdown(stylePeriod)}
          searchQuery={props.item.style_period && props.item.style_period.name}
          value={props.item.style_period_id}
          modal={{
            component: withTranslation()(AddStylePeriodModal),
            onSave: (stylePeriod) => StylePeriods.save(stylePeriod).then(({ data }) => data.style_period)
          }}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
));

type AddModalProps = EditContainerProps & Translateable & {
  item: StylePeriodType
};

const AddStylePeriodModal = (props: AddModalProps) => (
  <Modal
    as={Form}
    centered={false}
    className='style-period-modal'
    noValidate
    open
    size='small'
  >
    <Modal.Header
      content={props.item.id
        ? props.t('StylePeriodModal.title.edit')
        : props.t('StylePeriodModal.title.add')}
    />
    <Modal.Content>
      <StylePeriodForm
        {...props}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default StylePeriodModal;
