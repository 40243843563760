// @flow

import Authority from '../transforms/Authority';
import BatchUpdateable from './BatchUpdateable';

/**
 * Service class for all authority based API calls.
 */
class Authorities extends BatchUpdateable {
  /**
   * Returns the authorities base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/authorities';
  }

  /**
   * Returns the authorities transform.
   *
   * @returns {Authority}
   */
  getTransform(): typeof Authority {
    return Authority;
  }
}

export default (new Authorities(): Authorities);
