// @flow

import { ItemUtils, type Translateable } from '@archnet/shared';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type AbstractComponent,
  type Element
} from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Header,
  Icon,
  Image,
  Segment,
  Transition
} from 'semantic-ui-react';
import _ from 'underscore';
import './SelectizeHeader.css';

type Props = Translateable & {
  collapsable?: boolean,
  onItemClick: (item: any) => void,
  perPage?: number,
  renderExtra?: (item: any) => Element<any>,
  selectedItem: any,
  selectedItems: Array<any>,
  type: string
};

type CardProps = {
  header?: string,
  image?: string,
  meta?: string,
  onClick: () => void,
  onDelete: () => void,
  renderExtra?: () => Element<any>,
  selected: boolean
};

const DEFAULT_PER_PAGE = 5;

const SelectizeCard = (props: CardProps) => (
  <Card
    raised={props.selected}
    link
    onClick={props.onClick}
  >
    { props.image && (
      <Image
        size='small'
        src={props.image}
      />
    )}
    { !props.image && (
      <Segment
        placeholder
      >
        <Icon
          name='image'
          size='large'
        />
      </Segment>
    )}
    <Card.Content>
      { props.header && (
        <Card.Header>
          <Header
            as='h6'
            content={props.header}
          />
        </Card.Header>
      )}
      { props.meta && (
        <Card.Meta
          content={props.meta}
        />
      )}
    </Card.Content>
    { props.renderExtra && (
      <Card.Content
        extra
      >
        { props.renderExtra() }
      </Card.Content>
    )}
    { props.selected && (
      <Card.Content
        extra
      >
        <Button
          basic
          circular
          compact
          icon='times'
          onClick={props.onDelete}
        />
      </Card.Content>
    )}
  </Card>
);

SelectizeCard.defaultProps = {
  header: undefined,
  image: undefined,
  meta: undefined,
  renderExtra: undefined
};

const SelectizeHeader = (props: Props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  /**
   * Sets the items for the current page.
   *
   * @type {null}
   */
  const items = useMemo(() => {
    let records = null;

    if (page && props.selectedItems && props.selectedItems.length) {
      const startIndex = (page - 1) * (props.perPage || DEFAULT_PER_PAGE);
      const endIndex = startIndex + (props.perPage || DEFAULT_PER_PAGE);

      records = props.selectedItems.slice(startIndex, endIndex);
    }

    return records;
  }, [page, props.perPage, props.selectedItems]);

  /**
   * Sets the pagination label.
   *
   * @type {string}
   */
  const pagination = useMemo(() => {
    const startIndex = (page - 1) * (props.perPage || DEFAULT_PER_PAGE);
    const endIndex = startIndex + (props.perPage || DEFAULT_PER_PAGE);
    const total = props.selectedItems.length;

    return `${startIndex + 1} - ${Math.min(endIndex, total)} of ${total}`;
  }, [page, props.perPage, props.selectedItems]);

  /**
   * Changes the current page number. If the next page is invalid, we move to the first page or last page.
   *
   * @type {(function(*): void)|*}
   */
  const onPageChange = useCallback((increment: number) => {
    let nextPage = page + increment;

    if (nextPage < 1) {
      nextPage = pages;
    } else if (nextPage > pages) {
      nextPage = 1;
    }

    setPage(nextPage);
  }, [page, pages]);

  /**
   * Set the number of pages when the perPage or selectedItems props change.
   */
  useEffect(() => {
    if (props.perPage && props.selectedItems) {
      setPages(Math.ceil(props.selectedItems.length / props.perPage));
    }
  }, [props.perPage, props.selectedItems]);

  /**
   * If we're removing the last item on the page, decrement the page by 1.
   */
  useEffect(() => {
    if (!(items && items.length) && props.selectedItems && props.selectedItems.length && page > 1) {
      setPage(page - 1);
    }
  }, [items, page, props.selectedItems]);

  if (_.isEmpty(items)) {
    return null;
  }

  return (
    <Segment
      className='selectize-header'
    >
      <Transition
        visible={!collapsed}
      >
        <div>
          <div
            className='container'
          >
            <Button
              basic
              circular
              disabled={pages <= 1}
              icon='arrow left'
              onClick={onPageChange.bind(this, -1)}
            />
            <Card.Group
              itemsPerRow={props.perPage}
            >
              { _.map(items, (item) => (
                <SelectizeCard
                  {...ItemUtils.getViewAttributes(item, props.type)}
                  key={item.id}
                  onClick={() => (
                    props.selectedItem === item
                      ? props.onItemClick(null)
                      : props.onItemClick(item)
                  )}
                  onDelete={() => props.onItemClick(item)}
                  renderExtra={props.renderExtra && props.renderExtra.bind(this, item)}
                  selected={item === props.selectedItem}
                />
              ))}
            </Card.Group>
            <Button
              basic
              circular
              disabled={pages <= 1}
              icon='arrow right'
              onClick={onPageChange.bind(this, 1)}
            />
          </div>
        </div>
      </Transition>
      <div
        className='bottom-container'
      >
        <div />
        <div>{ pagination }</div>
        <div>
          { props.collapsable && (
            <Button
              as='a'
              basic
              circular
              className='link'
              compact
              content={collapsed ? props.t('Common.buttons.show') : props.t('Common.buttons.hide')}
              onClick={() => setCollapsed((prevCollapsed) => !prevCollapsed)}
            />
          )}
        </div>
      </div>
    </Segment>
  );
};

SelectizeHeader.defaultProps = {
  collapsable: true,
  onItemClick: () => {},
  perPage: DEFAULT_PER_PAGE,
  renderExtra: undefined
};

export default (withTranslation()(SelectizeHeader): AbstractComponent<any>);
