// @flow

import AuthorityRole from '../transforms/AuthorityRole';
import BaseService from './BaseService';

/**
 * Service class for all authority role based API calls.
 */
class AuthorityRoles extends BaseService {
  /**
   * Returns the authority roles base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/authority_roles';
  }

  /**
   * Returns the authority roles transform.
   *
   * @returns {AuthorityRole}
   */
  getTransform(): typeof AuthorityRole {
    return AuthorityRole;
  }
}

export default (new AuthorityRoles(): AuthorityRoles);
