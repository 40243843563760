// @flow

import { FilterTypes } from '@performant-software/semantic-components';
import i18n from '../i18n/I18n';
import type { Filter } from '../types/Filter';
import Filters from './Filters';

const DonorFilters: Array<Filter> = [{
  attributeName: 'name',
  key: 'name',
  label: i18n.t('Donors.filters.name'),
  type: FilterTypes.string
}, {
  attributeName: 'email',
  key: 'email',
  label: i18n.t('Donors.filters.email'),
  type: FilterTypes.string
}, {
  attributeName: 'phone',
  key: 'phone',
  label: i18n.t('Donors.filters.phone'),
  type: FilterTypes.string
}, {
  attributeName: 'general_info',
  key: 'general_info',
  label: i18n.t('Donors.filters.information'),
  type: FilterTypes.string
}, ...Filters.excludeFilters('published', 'date_published', 'locked')];

export default DonorFilters;
