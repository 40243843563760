// @flow

import type {
  Authority,
  Collection,
  Donation,
  Donor,
  MediaContent,
  Publication,
  Site,
  Translateable
} from '@archnet/shared';
import { Date as DateUtils } from '@performant-software/shared-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { useState, type Node } from 'react';
import {
  Button,
  Card,
  Divider,
  Form,
  List,
  Popup
} from 'semantic-ui-react';
import _ from 'underscore';
import NotesModal from './NotesModal';
import './RecordHeader.css';

type Props = Translateable & {
  ...EditContainerProps,
  description?: string,
  header?: string,
  includeAwardWinner?: boolean,
  includeFlagReview?: boolean,
  includeHcp?: boolean,
  includeLockButton?: boolean,
  includeNotesButton?: boolean,
  includePublishButton?: boolean,
  item: Site | Authority | Collection | Donation | Donor | Publication | MediaContent,
  meta?: string,
  renderContent?: () => Node,
  renderImage?: () => Node
};

const POPUP_DELAY = 1000;

const RecordHeader = (props: Props): Node => {
  const [notesModal, setNotesModal] = useState(false);

  return (
    <Card.Content
      className='record-header'
    >
      { props.renderImage && props.renderImage() }
      { props.header && (
        <Card.Header
          content={props.header}
        />
      )}
      { props.meta && (
        <Card.Meta
          content={props.meta}
        />
      )}
      { props.description && (
        <Card.Description
          content={props.description}
        />
      )}
      { props.renderContent && props.renderContent() }
      <Divider />
      <Card.Content
        extra
      >
        <List
          relaxed='very'
        >
          <List.Item>
            <List.Icon
              name='plus circle'
              verticalAlign='middle'
            />
            <List.Content
              header={props.t('RecordHeader.labels.createdBy', {
                name: (props.item.create_user && props.item.create_user.full_name)
                  || props.t('RecordHeader.labels.adminAccount')
              })}
              description={DateUtils.formatDate(props.item.created_at)}
            />
          </List.Item>
          <List.Item>
            <List.Icon
              name='edit outline'
              verticalAlign='middle'
            />
            <List.Content
              header={props.t('RecordHeader.labels.updatedBy', {
                name: (props.item.update_user && props.item.update_user.full_name)
                  || props.t('RecordHeader.labels.adminAccount')
              })}
              description={DateUtils.formatDate(props.item.updated_at)}
            />
          </List.Item>
          <List.Item>
            <List.Icon
              color={props.item.published ? 'green' : undefined}
              name='send'
              size='large'
              verticalAlign='middle'
            />
            <List.Content
              header={props.item.published
                ? props.t('RecordHeader.labels.published')
                : props.t('RecordHeader.labels.unpublished')}
              description={props.item.date_published ? DateUtils.formatDate(props.item.date_published) : ''}
            />
          </List.Item>
          { props.item.locked_by_id && (
            <List.Item>
              <List.Icon
                color='red'
                name='lock'
                verticalAlign='middle'
              />
              <List.Content
                header={props.t('RecordHeader.labels.lockedBy', {
                  name: (props.item.locked_user
                    && props.item.locked_user.full_name) || props.t('RecordHeader.labels.adminAccount')
                })}
                description={props.item.disabled
                  ? props.t('RecordHeader.labels.disabled') : props.t('RecordHeader.labels.enabled')}
              />
            </List.Item>
          )}
        </List>
      </Card.Content>
      <Divider />
      <Card.Content
        className='checkbox-container'
      >
        { props.includeHcp && (
          <Form.Checkbox
            checked={props.item.hcp_item}
            label={props.t('RecordHeader.labels.hcpItem')}
            onChange={props.onCheckboxInputChange.bind(this, 'hcp_item')}
          />
        )}
        { props.includeAwardWinner && (
          <Form.Checkbox
            checked={props.item.award_winner}
            label={props.t('RecordHeader.labels.awardWinner')}
            onChange={props.onCheckboxInputChange.bind(this, 'award_winner')}
          />
        )}
        { props.includeFlagReview && (
          <Form.Checkbox
            checked={props.item.flag_for_review}
            label={props.t('RecordHeader.labels.flagForReview')}
            onChange={props.onCheckboxInputChange.bind(this, 'flag_for_review')}
          />
        )}
      </Card.Content>
      <Divider />
      <Card.Content
        className='button-container'
        extra
        textAlign='center'
      >
        { props.includePublishButton && (
          <Popup
            content={props.t('RecordHeader.popups.publish.content')}
            header={props.t('RecordHeader.popups.publish.header')}
            hideOnScroll
            mouseEnterDelay={POPUP_DELAY}
            position='top right'
            trigger={(
              <Button
                basic
                color={props.item.published ? 'green' : undefined}
                icon='send'
                onClick={props.onCheckboxInputChange.bind(this, 'published')}
              />
            )}
          />
        )}
        { props.includeLockButton && (
          <Popup
            content={props.t('RecordHeader.popups.lock.content')}
            header={props.t('RecordHeader.popups.lock.header')}
            hideOnScroll
            mouseEnterDelay={POPUP_DELAY}
            position='top right'
            trigger={(
              <Button
                basic
                color={props.item.locked ? 'red' : undefined}
                icon={props.item.locked ? 'lock' : 'unlock'}
                onClick={props.onCheckboxInputChange.bind(this, 'locked')}
              />
            )}
          />
        )}
        { props.includeNotesButton && (
          <>
            <Popup
              content={props.t('RecordHeader.popups.notes.content')}
              header={props.t('RecordHeader.popups.notes.header')}
              hideOnScroll
              mouseEnterDelay={POPUP_DELAY}
              position='top right'
              trigger={(
                <Button
                  basic
                  color={_.isEmpty(props.item.admin_notes) ? undefined : 'orange'}
                  icon='sticky note'
                  onClick={() => setNotesModal(true)}
                />
              )}
            />
            { notesModal && (
              <NotesModal
                notes={props.item.admin_notes}
                onClose={() => setNotesModal(false)}
                onDelete={props.onDeleteChildAssociation.bind(this, 'admin_notes')}
                onSave={(notes) => props.onSetState({ admin_notes: notes })}
              />
            )}
          </>
        )}
      </Card.Content>
    </Card.Content>
  );
};

RecordHeader.defaultProps = {
  description: undefined,
  header: undefined,
  imageUpload: true,
  includeHcp: true,
  includeAwardWinner: true,
  includeFlagReview: true,
  includeLockButton: true,
  includeNotesButton: true,
  includePublishButton: true,
  meta: undefined,
  renderContent: undefined,
  renderImage: undefined
};

export default RecordHeader;
