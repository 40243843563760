// @flow

import {
  SiteUtils,
  type RecordAssociation,
  type Site,
  type Translateable
} from '@archnet/shared';
import { ListFilters, Selectize } from '@performant-software/semantic-components';
import React, { useState, type Element, type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Header, Item } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedRecordsList from './AssociatedRecordsList';
import PublishedLabel from './PublishedLabel';
import PrimaryImageView from './PrimaryImageView';
import SelectizeHeader from './SelectizeHeader';
import SiteFilters from '../filters/SiteFilters';
import SiteNameModal from './SiteNameModal';
import Sites from '../services/Sites';
import './AssociatedSites.css';

type Props = Translateable & {
  addButton: ?string,
  buttons: ?Array<any>,
  items: Array<RecordAssociation>,
  onDataLoaded: (items: Array<RecordAssociation>) => void,
  onDelete: (item: RecordAssociation) => void,
  onLoad: (params: any) => Promise<any>,
  onSave: (item: RecordAssociation) => void,
  onUpdate?: (items: Array<RecordAssociation>) => void,
  renderDescription: (item: RecordAssociation) => Element<any>,
  renderExtra: (item: RecordAssociation) => Element<any>,
  renderHeader: (item: RecordAssociation) => Element<any>,
  renderImage: (item: RecordAssociation) => Element<any>,
  renderMeta: (item: RecordAssociation) => Element<any>,
  resolveSite: (item: RecordAssociation) => Site
};

const AssociatedSites = (props: Props) => {
  const [modal, setModal] = useState(false);

  /**
   * Renders the site item for the Selectize component.
   *
   * @param site
   *
   * @returns {JSX.Element}
   */
  const renderSite = (site: Site) => (
    <Item.Group>
      <Item>
        <Item.Image
          style={{
            width: 'unset'
          }}
        >
          <PrimaryImageView
            dimmable={false}
            item={site}
            size='tiny'
          />
        </Item.Image>
        <Item.Content>
          <Item.Header
            content={site.name}
          />
          <Item.Meta
            content={site.record_id}
          />
          <Item.Description
            content={SiteUtils.formatLocation(site)}
          />
          <Item.Extra>
            <PublishedLabel
              icon
              published={site.published}
            />
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
  );

  /**
   * Renders the description for the passed item.
   *
   * @param item
   *
   * @returns {string|*}
   */
  const renderDescription = (item: RecordAssociation) => {
    if (props.renderDescription) {
      return props.renderDescription(item);
    }

    const site = resolveSite(item);
    return SiteUtils.formatLocation(site);
  };

  /**
   * Renders the extra information for the passed item.
   *
   * @param item
   *
   * @returns {JSX.Element|*}
   */
  const renderExtra = (item: RecordAssociation) => {
    if (props.renderExtra) {
      return props.renderExtra(item);
    }

    const site = resolveSite(item);

    return (
      <PublishedLabel
        icon
        published={site.published}
      />
    );
  };

  /**
   * Renders the header for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderHeader = (item: RecordAssociation) => {
    if (props.renderHeader) {
      return props.renderHeader(item);
    }

    const site = resolveSite(item);

    return (
      <Link
        to={`/admin/sites/${site.id}`}
      >
        <Header
          as='h3'
          content={site.name}
        />
      </Link>
    );
  };

  /**
   * Renders the image for the passed item.
   *
   * @param item
   *
   * @returns {*}
   */
  const renderImage = (item: RecordAssociation) => {
    if (props.renderImage) {
      return props.renderImage(item);
    }

    const site = resolveSite(item);

    return (
      <PrimaryImageView
        item={site}
      />
    );
  };

  /**
   * Renders the metadata for the passed item.
   *
   * @param item
   *
   * @returns {string|*}
   */
  const renderMeta = (item: RecordAssociation) => {
    if (props.renderMeta) {
      return props.renderMeta(item);
    }

    const site = resolveSite(item);
    return site.record_id;
  };

  /**
   * Returns the related site for the passed site record association.
   *
   * @param item
   *
   * @returns {*|{}}
   */
  const resolveSite = (item: RecordAssociation) => (props.resolveSite && props.resolveSite(item)) || {};

  return (
    <>
      <AssociatedRecordsList
        actions={[{
          basic: true,
          color: 'red',
          icon: 'trash',
          label: props.t('AssociatedSites.buttons.delete'),
          onClick: props.onDelete.bind(this)
        }]}
        buttons={[{
          color: 'green',
          content: props.addButton || props.t('AssociatedSites.buttons.add'),
          icon: 'plus',
          onClick: () => setModal(true)
        },
        ...(props.buttons || [])
        ]}
        className='associated-sites'
        items={props.items}
        onDataLoaded={props.onDataLoaded}
        onLoad={props.onLoad}
        onUpdate={props.onUpdate}
        renderDescription={renderDescription.bind(this)}
        renderExtra={renderExtra.bind(this)}
        renderHeader={renderHeader.bind(this)}
        renderImage={renderImage.bind(this)}
        renderMeta={renderMeta.bind(this)}
      />
      { modal && (
        <Selectize
          collectionName='sites'
          filters={{
            component: ListFilters,
            props: {
              filters: SiteFilters
            }
          }}
          modal={{
            component: SiteNameModal,
            onSave: (sn) => Sites.save({ site_names: [sn] }).then(({ data }) => data.site)
          }}
          onClose={() => setModal(false)}
          onLoad={(params) => Sites.search({ ...params, sort_by: 'site_names.name', per_page: 5 })}
          onSave={(sites) => {
            props.onSave(sites);
            setModal(false);
          }}
          renderHeader={(headerProps) => (
            <SelectizeHeader
              {...headerProps}
              type='Site'
            />
          )}
          renderItem={renderSite.bind(this)}
          selectedItems={_.map(props.items, resolveSite)}
          title={props.t('AssociatedSites.selectize.title')}
          width='60%'
        />
      )}
    </>
  );
};

AssociatedSites.defaultProps = {
  buttons: []
};

const AssociatedSitesComponent: AbstractComponent<any> = withTranslation()(withRouter(AssociatedSites));
export default AssociatedSitesComponent;
