// @flow

import { createContext, type Context } from 'react';

type ScrollViewContextType = {
  containerRef: { current: ?HTMLDivElement },
  scrollToTop: () => void | null
};

const ScrollViewContext: Context<ScrollViewContextType> = createContext<ScrollViewContextType>({
  containerRef: { current: null },
  scrollToTop: () => {}
});

export default ScrollViewContext;
