// @flow

import cx from 'classnames';
import React, { type Node } from 'react';
import { Button } from 'semantic-ui-react';
import styles from './PlayButton.module.css';

type Props = {
  onClick?: () => void,
  size?: string,
  style?: any
};

const PlayButton = (props: Props): Node => (
  <Button
    className={cx(styles.playButton, styles.ui, styles.button)}
    color='black'
    icon='play'
    onClick={(e) => {
      if (props.onClick) {
        e.stopPropagation();

        // $FlowIgnore - We're already checking that the prop exists
        props.onClick();
      }
    }}
    size={props.size}
    style={props.style}
  />
);

PlayButton.defaultProps = {
  onClick: undefined,
  size: 'massive',
  style: undefined
};

export default PlayButton;
