// @flow

import React, { type Node } from 'react';
import ImageLabel from './ImageLabel';

type Props = {
  color?: string,
  copyright: string
};

const CopyrightLabel = (props: Props): Node => {
  if (!props.copyright) {
    return null;
  }

  return (
    <ImageLabel
      color={props.color}
      html
      icon='copyright'
      value={props.copyright}
    />
  );
};

CopyrightLabel.defaultProps = {
  color: 'white'
};

export default CopyrightLabel;
