// @flow

import type { Keyword, Translateable } from '@archnet/shared';
import { Selectize } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import _ from 'underscore';
import KeywordModal from './KeywordModal';
import Keywords from '../services/Keywords';
import SimpleTagsList from './SimpleTagsList';

type Keywordable = {
  id: number,
  keyword_id: number,
  keyword: Keyword
};

type Props = Translateable & {
  items: Array<Keywordable>,
  onDelete: (item: Keywordable) => void,
  onSave: (items: Array<Keywordable>) => void
};

const KeywordsList: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleTagsList
    items={_.filter(props.items, (item) => !item._destroy)}
    onDelete={props.onDelete.bind(this)}
    renderItem={(item) => item.keyword.name}
    renderModal={({ onClose }) => (
      <Selectize
        collectionName='keywords'
        modal={{
          component: KeywordModal,
          onSave: (keyword) => Keywords.save(keyword).then(({ data }) => data.keyword)
        }}
        onClose={onClose}
        onLoad={(params) => Keywords.fetchAll({ ...params, sort_by: 'name' })}
        onSave={(keywords) => {
          const findKeyword = (item: Keywordable) => _.findWhere(props.items, { keyword_id: item.id });
          const createKeyword = (item: Keywordable) => ({ uid: uuid(), keyword_id: item.id, keyword: item });

          // Create or find items
          const items = _.map(keywords, (keyword) => findKeyword(keyword) || createKeyword(keyword));

          // Save selected keywords
          props.onSave(items);

          // Close the modal
          onClose();
        }}
        renderItem={(keyword) => keyword.name}
        selectedItems={_.map(props.items, (item) => item.keyword)}
        title={props.t('KeywordsList.title')}
      />
    )}
  />
));

export default KeywordsList;
