// @flow

import { MediaUtils, type MediaContent, type Resourceable, type Translateable } from '@archnet/shared';
import { FileInputButton } from '@performant-software/semantic-components';
import React, { type ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import LazyMedia from './LazyMedia';

type FileProps = Resourceable & {
  content_remove?: boolean
};

type Props = Translateable & {
  item: MediaContent,
  onChange: (props: FileProps) => void
};

const MediaContentActions: ComponentType<any> = withTranslation()((props: Props) => (
  <LazyMedia
    download={props.item.content_download_url}
    media={props.item}
  >
    { !MediaUtils.isExternal(props.item) && (
      <FileInputButton
        color='orange'
        content={props.item.content_url
          ? props.t('Common.buttons.replaceFile')
          : props.t('Common.buttons.upload')}
        icon={props.item.content_url ? 'refresh' : 'cloud upload'}
        onSelection={(files) => props.onChange(MediaUtils.getSelectionState(files))}
      />
    )}
  </LazyMedia>
));

export default MediaContentActions;
