// @flow

import type { CustomPage as CustomPageType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, Form } from 'semantic-ui-react';
import CustomPages from '../services/CustomPages';
import RichTextArea from '../components/RichTextArea';
import SimpleEditPage from './SimpleEditPage';
import './CustomPage.css';

type Props = EditContainerProps & Translateable & {
  item: CustomPageType
};

const Tabs = {
  details: 'details'
};

const CustomPage: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
    className='home-page'
  >
    <SimpleEditPage.Header>
      <Card.Content>
        <Card.Header
          content={props.item.title}
        />
        <Card.Meta
          content={props.item.url}
        />
      </Card.Content>
      <Card.Content>
        <Form.Checkbox 
            error={props.isError('footer')}
            checked={props.item.footer}
            label={props.t('CustomPage.labels.footer')}
            onChange={props.onCheckboxInputChange.bind(this, 'footer')} 
          /> 
      </Card.Content>
      <Card.Content
        extra
      >
        <Button
          basic
          color={props.item.published ? 'green' : undefined}
          icon='send'
          onClick={props.onCheckboxInputChange.bind(this, 'published', !props.item.published)}
        />
      </Card.Content>
    </SimpleEditPage.Header>
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <Form.Input
        error={props.isError('title')}
        label={props.t('CustomPage.labels.title')}
        required={props.isRequired('title')}
        onChange={props.onTextInputChange.bind(this, 'title')}
        value={props.item.title || ''}
      />
      <Form.Input
        error={props.isError('url')}
        label={props.t('CustomPage.labels.url')}
        required={props.isRequired('url')}
        onChange={props.onTextInputChange.bind(this, 'url')}
        value={props.item.url || ''}
      />
      <Form.Input
        error={props.isError('order')}
        label={props.t('CustomPage.labels.order')}
        required={props.isRequired('order')}
        onChange={props.onTextInputChange.bind(this, 'order')}
        value={props.item.order}
      />
      <Form.Input
        error={props.isError('content')}
        label={props.t('CustomPage.labels.content')}
        required={props.isRequired('content')}
      >
        <RichTextArea
          onChange={(value) => props.onTextInputChange('content', null, { value })}
          value={props.item.content || ''}
        />
      </Form.Input>
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: CustomPage,
  onInitialize: (id: number): Promise<any> => (
    CustomPages
      .fetchOne(id)
      .then(({ data }) => data.custom_page)
  ),
  onSave: (customPage: CustomPageType): Promise<any> => (
    CustomPages
      .save(customPage)
      .then(({ data }) => data.custom_page)
  )
};
