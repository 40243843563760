// @flow

import { SiteUtils, type Site } from '@archnet/shared';
import React, { type Node } from 'react';
import ImageLabel from './ImageLabel';

type Props = {
  color?: string,
  site: Site
};

const SiteLabel = (props: Props): Node => (
  <ImageLabel
    color={props.color}
    detail={SiteUtils.formatLocation(props.site)}
    icon='building'
    url={`/admin/sites/${props.site.id}`}
    value={props.site.name}
  />
);

SiteLabel.defaultProps = {
  color: undefined
};

export default SiteLabel;
