// @flow

import React, { type Node } from 'react';
import { Button } from 'semantic-ui-react';
import i18n from '../i18n/I18n';

type Props = {
  onClick: () => void
};

const BackButton = (props: Props): Node => (
  <Button
    basic
    content={i18n.t('BackButton.buttons.back')}
    icon='angle left'
    onClick={props.onClick}
  />
);

export default BackButton;
