// @flow

import type { EventType as EventTypeType, Translateable } from '@archnet/shared';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type Node } from 'react';
import { Form } from 'semantic-ui-react';

type Props = EditContainerProps & Translateable & {
  item: EventTypeType
};

const EventTypeForm = (props: Props): Node => (
  <Form.Input
    error={props.isError('title')}
    label={props.t('EventType.labels.title')}
    onChange={props.onTextInputChange.bind(this, 'title')}
    required={props.isRequired('title')}
    value={props.item.title || ''}
  />
);

export default EventTypeForm;
