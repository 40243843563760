// @flow

import NestedAttributes from './NestedAttributes';

/**
 * Class for handling transforming nested event item records.
 */
class EventItems extends NestedAttributes {
  /**
   * Returns the event items payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'id',
      'event_type_id',
      'start_year',
      'end_year',
      'description',
      '_destroy'
    ];
  }
}

export default (new EventItems(): EventItems);
