// @flow

import type { Language, Translateable } from '@archnet/shared';
import { Selectize } from '@performant-software/semantic-components';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import _ from 'underscore';
import LanguageModal from './LanguageModal';
import Languages from '../services/Languages';
import SimpleTagsList from './SimpleTagsList';

type Languageable = {
  id: number,
  language_id: number,
  language: Language
};

type Props = Translateable & {
  items: Array<Languageable>,
  onDelete: (item: Languageable) => void,
  onSave: (items: Array<Languageable>) => void
};

const LanguagesList: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleTagsList
    items={_.filter(props.items, (item) => !item._destroy)}
    onDelete={props.onDelete.bind(this)}
    renderItem={(item) => item.language.name}
    renderModal={({ onClose }) => (
      <Selectize
        collectionName='languages'
        modal={{
          component: LanguageModal,
          onSave: (language) => Languages.save(language).then(({ data }) => data.language)
        }}
        onClose={onClose}
        onLoad={(params) => Languages.fetchAll({ ...params, sort_by: 'name' })}
        onSave={(languages) => {
          const findLanguage = (item: Languageable) => _.findWhere(props.items, { language_id: item.id });
          const createLanguage = (item: Languageable) => ({ uid: uuid(), language_id: item.id, language: item });

          // Create or find languages
          const items = _.map(languages, (language) => findLanguage(language) || createLanguage(language));

          // Save selected keywords
          props.onSave(items);

          // Close the modal
          onClose();
        }}
        renderItem={(language) => language.name}
        selectedItems={_.map(props.items, (item) => item.language)}
        title={props.t('LanguagesList.title')}
      />
    )}
  />
));

export default LanguagesList;
