// @flow

import type { Option } from '../types/Option';
import AdminNotes from './AdminNotes';
import FormDataTransform from './FormDataTransform';
import RecordAssociations from './RecordAssociations';

import type { Donation as DonationType } from '@archnet/shared';

/**
 * Class for handling transforming donation records.
 */
class Donation extends FormDataTransform {
  /**
   * Returns the donation parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'donation';
  }

  /**
   * Returns the donation payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'name',
      'description',
      'hcp_item',
      'award_winner',
      'flag_for_review',
      'copyright_restriction',
      'work_status',
      'work_status_date',
      'negotiated_by',
      'permission_type',
      'date',
      'locked'
    ];
  }

  /**
   * Returns the passed donation as a dropdown option.
   *
   * @param donation
   *
   * @returns {{text: *, value: *, key: *}}
   */
  toDropdown(donation: DonationType): Option {
    return {
      key: donation.id,
      value: donation.id,
      text: donation.name
    };
  }

  /**
   * Returns the donation object to be sent to the server on POST/PUT requests.
   *
   * @param donation
   *
   * @returns {*}
   */
  toPayload(donation: DonationType): any {
    const formData = super.toPayload(donation);

    AdminNotes.appendFormData(formData, this.getParameterName(), donation);
    RecordAssociations.appendFormData(formData, this.getParameterName(), donation, 'donor_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), donation, 'media_record_associations');
    RecordAssociations.appendFormData(formData, this.getParameterName(), donation, 'publication_record_associations');

    return formData;
  }
}

export default (new Donation(): Donation);
